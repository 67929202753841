import { useEffect, useState } from "react";
import { HealthType } from "../../../../entities/api/HealthType";
import { HealthTypes } from "../../../../entities/api/HealthTypes";
import { DashboardComponent } from "../../../../generated/graphql";
import { DashboardComponentInputConfigValue } from "../../../../generated/graphql";
import { useDataTypes } from "../../../../hooks/UseDataTypes";
import { DataTypeSelectModal } from "../components/DataTypeSelectModal";
import "./EditStackedBarLineChartConfigurationRow.css"
import { useEditStackedBarLineChartConfigurationRowData } from "../../../../hooks/charts/UseEditStackedBarLineChartConfigurationRowData";

export interface EditStackedBarLineChartConfigurationRowProps {
    component: DashboardComponent,
    patchConfiguration: (configurations: DashboardComponentInputConfigValue[]) => void,
    changeComponentHealthTypeAction: (componentUuid: string, newType: HealthType, index: number) => void,
}

export const EditStackedBarLineChartConfigurationRow: React.FC<EditStackedBarLineChartConfigurationRowProps> = (props: EditStackedBarLineChartConfigurationRowProps) => {
    let dataTypes = useDataTypes();
    dataTypes.workouts = null;
    const [isTypePickerShown, setIsTypePickerShown] = useState(false);
    const [secondaryType, setSecondaryType] = useState(null as HealthType | null);
    const formData = useEditStackedBarLineChartConfigurationRowData(props.component, props.patchConfiguration);

    useEffect(() => {
        const defaultSecondaryType = makeDefaultSecondaryDataType(props.component, dataTypes);
        setSecondaryType(defaultSecondaryType);
        // eslint-disable-next-line
    }, [props.component]); 

    function changeSecondaryType() {
        setIsTypePickerShown(true);
    }

    function secondaryTypeSelectedAction(newType: HealthType) {
        setIsTypePickerShown(false);
        putSecondaryType(newType);
    }

    function putSecondaryType(newType: HealthType) {
        props.changeComponentHealthTypeAction(props.component.componentUuid, newType, 1);
    }

    return (
        <>
            <div className="row">
                <p className="stackedTypesInfo">The primary data type will be displayed as a bar chart and the secondary data type as a line chart. The primary data type is the one on top of this card and the secondary type can be specified below.</p>
            </div>
            <form>
                <div className="row">
                    <div className="form-group col-lg-4">
                        <label className="col-form-label">Y primary axis minimum (optional):</label>
                        <input type="number" className="form-control" value={formData.yMainMin ?? ''} onChange={(event) => formData.setYMainMin(event.target.value)} />
                    </div>
                    <div className="form-group col-lg-4">
                        <label className="col-form-label">Y primary axis maximum (optional):</label>
                        <input type="number" className="form-control" value={formData.yMainMax ?? ''} onChange={(event) => formData.setYMainMax(event.target.value)} />
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-lg-4">
                        <label className="col-form-label">Secondary data type:</label>
                        <div className="stackedBarLineChartConfiguration_secondaryTypeWrapper">
                            <strong>{ secondaryType?.name ?? 'Not selected' }</strong>
                            <a href="#" onClick={changeSecondaryType}><i className="material-icons">edit</i></a>
                        </div>
                    </div>
                    <div className="form-group col-lg-4">
                        <label className="col-form-label">Y secondary axis minimum (optional):</label>
                        <input type="number" className="form-control" value={formData.ySecondaryMin ?? ''} onChange={(event) => formData.setYSecondaryMin(event.target.value)} />
                    </div>
                    <div className="form-group col-lg-4">
                        <label className="col-form-label">Y secondary axis maximum (optional):</label>
                        <input type="number" className="form-control" value={formData.ySecondaryMax ?? ''} onChange={(event) => formData.setYSecondaryMax(event.target.value)} />
                    </div>
                </div>
            </form>
            <DataTypeSelectModal
                defaultHealthType={secondaryType ?? undefined}
                healthTypes={dataTypes}
                typeSelectedAction={secondaryTypeSelectedAction}
                isDisplayed={isTypePickerShown}
                setIsDisplayed={setIsTypePickerShown}
            />
        </>
    )
}

function makeDefaultSecondaryDataType(component: DashboardComponent, types: HealthTypes): HealthType | null {
    const typeId = component.dataTypeIds.length >= 2
        ? component.dataTypeIds[1]
        : null;
    if (typeId === null) {
        return null;
    }
    const type = [
        ...types.aggregated.flatMap(x => x.types),
        ...types.recordBased.flatMap(x => x.types),
    ]
    .find(type => type.id === typeId) ?? null;
    return type;
}