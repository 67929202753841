import { DashboardComponentConfigurationValue } from "../../generated/graphql";
import { GaugeChartConfigurationKey } from "../../models/chart_configuration_keys/GaugeChartConfigurationKey";

export const GaugeDefaultInterval = {
    min: 0,
    max: 100,
}

export type GaugeChartConfiguration = {
    percentage: number,
    unboundPercentage: number,
}

export function useGaugeChartConfiguration(value: number, configuration: DashboardComponentConfigurationValue[]): GaugeChartConfiguration {
    const valueNumber = value
    if (Number.isNaN(valueNumber) || value === 0) {
        return {
            percentage: 0,
            unboundPercentage: 0,
        }
    }

    let min = GaugeDefaultInterval.min;
    let max = GaugeDefaultInterval.max;
    const minConf = configuration.find(x => x.key === GaugeChartConfigurationKey.GAUGE_MIN)?.value;
    if (minConf) {
        min = parseFloat(minConf);
    }
    const maxConf = configuration.find(x => x.key === GaugeChartConfigurationKey.GAUGE_MAX)?.value;
    if (maxConf) {
        max = parseFloat(maxConf);
    }

    if (min === max) {
        const value = valueNumber >= min ? 1 : 0;
        return {
            percentage: value,
            unboundPercentage: value,
        }
    }

    const range = [min, max].sort();
    let boundedPercentage: number | null = null;

    if (valueNumber <= range[0]) {
        boundedPercentage = 0;
    } else if (valueNumber >= range[1]) {
        boundedPercentage = 1;
    }

    const unboundPercentage = (valueNumber - range[0]) / (range[1] - range[0]);

    return {
        percentage: boundedPercentage ?? unboundPercentage,
        unboundPercentage: unboundPercentage,
    }
}