import { ChartData } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { useSelector } from "react-redux";
import { useIsDarkMode } from "../../../hooks/UseIsDarkmode";
import { CaloriesCalculator } from "../../../models/CaloriesCalculator";
import { formatNumber } from "../../../models/UserUtils";
import { RootState } from "../../../Store";
import "./NutritionChart.css"

export interface NutritionChartProps {
    protein: number,
    fats: number,
    carbohydrates: number,
    units: string,
}

export const NutritionChart: React.FC<NutritionChartProps> = (props: NutritionChartProps) => {

    const isDarkMode = useIsDarkMode();
    const preferences = useSelector((state: RootState) => state).preferencesReducer.preferences;
    const chartData = makeChartData(props.protein, props.fats, props.carbohydrates, isDarkMode);
    const calories = CaloriesCalculator.calories(props.protein, props.fats, props.carbohydrates);

    return (
        <>
            <div className="col-xl-6">
                <Doughnut
                    data={chartData}
                    height={200}
                    options={{
                        elements: {
                            arc: {
                                borderColor: isDarkMode ? '#171717' : '#ffffff'
                            },
                        },
                        maintainAspectRatio: false,
                        plugins: {
                            legend: {
                                display: false,
                            },
                            tooltip: {
                                callbacks: {
                                    // @ts-ignore
                                    label: (data: any) => {
                                        const isEmpty = props.protein === 0 && props.fats === 0 && props.carbohydrates === 0;
                                        if (isEmpty) {
                                            return 'No data';
                                        } else {
                                            const type = data.label as string
                                            const value = data.parsed as number
                                            const formatedValue = formatNumber(
                                                value,
                                                1,
                                                preferences.numberFormat
                                            )
                                            return `${type}: ${formatedValue} ${props.units}`;
                                        }
                                    },
                                }
                            }
                        },
                    }}
                />
            </div>
            <div className="col-xl-6">
                <div className="nutritionTodayValueWrap">
                    <p>Protein</p>
                    <p className="proteinTodayValueLabel">{formatNumber(props.protein,0,preferences.numberFormat)} {props.units}</p>
                </div>
                <div className="nutritionTodayValueWrap">
                    <p>Fats</p>
                    <p className="fatsTodayValueLabel">{formatNumber(props.fats,0,preferences.numberFormat)} {props.units}</p>
                </div>
                <div className="nutritionTodayValueWrap">
                    <p>Carbohydrates</p>
                    <p className="carbohydratesTodayValueLabel">{formatNumber(props.carbohydrates,0,preferences.numberFormat)} {props.units}</p>
                </div>
                <div className="nutritionTodayValueWrap">
                    <p>Calories</p>
                    <p className="caloriesTodayValueLabel">{formatNumber(calories,0,preferences.numberFormat)} Cal</p>
                </div>
            </div>
        </>
    )
}

function makeChartData(
    protein: number,
    fats: number,
    carbohydrates: number,
    isDarkMode: boolean,
): ChartData<"doughnut", (number | null)[], unknown> {
    const isEmpty = protein === 0 && fats === 0 && carbohydrates === 0;
    if (isEmpty) {
        return {
            labels: [
                'No data',
            ],
            datasets: [{
                data: [
                    1,
                ],
                backgroundColor: [
                    isDarkMode 
                        ? '#424242'
                        : '#BDBDBD',
                ],
            }],
        }
    } else {
        return {
            labels: [
                'Protein',
                'Fats',
                'Carbohydrates',
            ],
            datasets: [{
                data: [
                    protein,
                    fats,
                    carbohydrates,
                ],
                backgroundColor: [
                    '#35C759',
                    '#2893FF',
                    '#FFCC02',
                ],
            }]
        }
    }
}