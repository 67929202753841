import { configureStore } from '@reduxjs/toolkit';
import AccountReducer from './reducers/AccountReducer';
import UserPreferencesReducer from './reducers/UserPreferencesReducer';

export const reduxStore = configureStore({ 
  reducer: {
    preferencesReducer: UserPreferencesReducer,
    accountReducer: AccountReducer,
  },
  devTools: false
});

export type RootState = ReturnType<typeof reduxStore.getState>
export type AppDispatch = typeof reduxStore.dispatch