import gql from "graphql-tag";
import { dashboardWithoutComponents } from "../../entities/gql_fragments/DashboardWithoutComponents";

export const createDashboardFromPresetMutation = gql`
${dashboardWithoutComponents}
mutation CreateDashboardFromPreset($preset: String!, $name: String!, $uid: String!) {
    createDashboardFromPreset(preset: $preset, name: $name, uid: $uid) {
        ...DashboardWithoutComponents
    }
}
`;