import { HealthType } from "../../../../entities/api/HealthType"
import { HealthTypes } from "../../../../entities/api/HealthTypes"
import { DateInterval, DateIntervalData } from "../../../../hooks/UseDateInterval"
import { useHealthData, UseHealthDataState, UseHealthDataStatus } from "../../../../hooks/UseHealthData"
import { AggregationPeriod } from "../../../../models/AggregationPeriod"
import { ActivityIndicator } from "../../../core/activity_indicator/ActivityIndicator"
import { Card } from "../../../core/card/Card"
import { StateMessage, StateMessageProps } from "./state_messages/StateMessage"
import { DashboardComponentContent } from "./DashboardComponentContent"
import { HealthDataAggregator } from "../../../../models/HealthDataAggregator"
import { DashboardComponentTitle } from "./DashboardComponentTitle"
import { COMPONENT_TIME_PERIOD_CONFIGURATION_KEY } from "../../dashboard_settings_detail/rows/EditComponentRow"
import { presetsWithAggregation, presetsWithoutAggregation } from "../../../../models/DatePreset"
import { NoDataTypeMessage } from "./state_messages/NoDataTypeMessage"
import { HealthDataCollection } from "../../../../models/datasources/HERemoteApiService"
import { healthTypeIdentifiers } from "../../../../models/datasources/HealthTypeIdentifier"
import { WorkoutChartConfigurationKey } from "../../../../models/chart_configuration_keys/WorkoutChartConfigurationKey"
import { WorkoutChartValue } from "../../../../models/WorkoutChartValue"
import { DashboardComponent } from "../../../../generated/graphql"
import { useHover } from "../../../../hooks/UseHover"
import { useRef } from "react"
import html2canvas from "html2canvas"
import jsPDF from "jspdf"

export interface DashboardComponentViewProps {
    component: DashboardComponent,
    dataTypes: HealthTypes,
    dateInterval: DateIntervalData,
    aggregationPeriod: AggregationPeriod,
}

export const DashboardComponentView: React.FC<DashboardComponentViewProps> = (props: DashboardComponentViewProps) => {
    const dataType = makeDataType(props.component, props.dataTypes);
    const { interval, intervalTitle } = makeComponentDateInterval(props.component, props.dateInterval.interval);
    const workoutValueConfiguration = makeWorkoutValueConfiguration(props.component);
    const workoutActivityConfiguration = makeWorkoutActivityConfiguration(props.component);
    const healthDataA = useHealthData(
        dataType.length >= 1 ? dataType[0]!.type : null,
        dataType.length >= 1 && dataType[0]!.isAggregated ? props.aggregationPeriod : null,
        workoutValueConfiguration,
        workoutActivityConfiguration,
        interval,
    );
    const healthDataB = useHealthData(
        dataType.length >= 2 ? dataType[1]!.type : null,
        dataType.length >= 2 && dataType[1]!.isAggregated ? props.aggregationPeriod : null,
        workoutValueConfiguration,
        workoutActivityConfiguration,
        interval,
    );
    const totalStatus = makeTotalStatus(props.component.dataTypeIds.length, healthDataA, healthDataB);
    const data = [healthDataA.data, healthDataB.data]
        .filter(x => x !== null && x !== undefined)
        .map(x => x![0]) // TODO: Fixme, multiple units for single type
        .filter(x => x);
    const isEmptyDataset = data.length === 0 || data.length === data.filter(x => x.records.length === 0).length;
    const aggregationPeriod = dataType.findIndex(x => x.isAggregated) !== -1 
        ? props.aggregationPeriod
        : null;
    const stateMessage = makeStateMessageIfNeeded(totalStatus, isEmptyDataset, data, props.component);
    const [hoverActive, hoverEventHandlers] = useHover();

    const chartRef = useRef<HTMLDivElement>(null);

    function onExportButtonClicked() {
        if (chartRef.current) {
            html2canvas(chartRef.current, { scale: 3 })
                .then((canvas) => {
                    const img = canvas.toDataURL('image/jpg');
                    const pdf = new jsPDF({
                            orientation: 'l',
                            unit: 'pt',
                            format: [canvas.width, canvas.height]
                    });
                    pdf.addImage(img, 'JPEG', 0, 0, canvas.width, canvas.height, undefined, "NONE");
                    pdf.save('export.pdf');
                })
        }
    }

    return (
        <div className="col-lg-6" {...hoverEventHandlers}>
            <Card useDefaultHorizontalMargin={false} >
                { dataType.length >= 1 &&
                    <>
                        <DashboardComponentTitle
                                types={dataType}
                                data={data}
                                dateIntervalTitle={intervalTitle}
                                componentConfiguration={props.component.configuration}
                                showExportButton={hoverActive && !stateMessage && totalStatus === UseHealthDataStatus.data && !isEmptyDataset}
                                onExportButtonClick={onExportButtonClicked}
                            />
                        { !stateMessage && totalStatus === UseHealthDataStatus.loading &&
                            <ActivityIndicator
                                    useFullHeight={false}
                                />
                        }
                        { stateMessage &&
                            <StateMessage {...stateMessage} />
                        }
                        { !stateMessage && totalStatus === UseHealthDataStatus.data && !isEmptyDataset &&
                            <div ref={chartRef}>
                                <DashboardComponentContent 
                                    data={data}
                                    datatypes={props.dataTypes}
                                    component={props.component}
                                    dateInterval={props.dateInterval}
                                    aggregationPeriod={aggregationPeriod}
                                />
                            </div>
                        }
                    </>
                }
                { !dataType &&
                    <NoDataTypeMessage />
                }
            </Card>
        </div>
    )
}

function makeDataType(component: DashboardComponent, dataTypes: HealthTypes): ({ type: HealthType, isAggregated: boolean })[] {
    return component.dataTypeIds
        .map(dataTypeId => {
            const aggregatedType = dataTypes.aggregated
                .flatMap(category => category.types)
                .concat(dataTypes.workouts ? [dataTypes.workouts] : [])
                .find(type => type.id === dataTypeId) ?? null;
            const recordBasedType = dataTypes.recordBased
                .flatMap(category => category.types)
                .find(type => type.id === dataTypeId) ?? null;
            const isAggregated = HealthDataAggregator.isTypeIdAggregated(dataTypeId, dataTypes);
            if (aggregatedType) {
                return {
                    type: aggregatedType,
                    isAggregated: isAggregated,
                }
            } else if (recordBasedType) {
                return {
                    type: recordBasedType,
                    isAggregated: isAggregated,
                }
            } else {
                return null;
            }
        })
        .filter(x => x !== null && x !== undefined) as { type: HealthType, isAggregated: boolean }[];
}

function makeTotalStatus(componentTypeCount: number, dataA: UseHealthDataState, dataB: UseHealthDataState): UseHealthDataStatus {
    if (dataA.status === UseHealthDataStatus.error || dataB.status === UseHealthDataStatus.error) {
        return UseHealthDataStatus.error;
    }
    if (componentTypeCount === 1 && dataA.status === UseHealthDataStatus.data) {
        return UseHealthDataStatus.data;
    }
    if (componentTypeCount === 2 && dataA.status === UseHealthDataStatus.data && dataB.status === UseHealthDataStatus.data) {
        return UseHealthDataStatus.data;
    }
    return UseHealthDataStatus.loading;
}

function makeComponentDateInterval(component: DashboardComponent, dashboardDateInterval: DateInterval): {interval: DateInterval, intervalTitle?: string} {
    const configuration = component.configuration.find(x => x.key === COMPONENT_TIME_PERIOD_CONFIGURATION_KEY)
    if (!configuration) {
        return { interval: dashboardDateInterval };
    } else if (configuration.value === '0') { // see DashboardComponentTimePeriod.id
        return { interval: dashboardDateInterval };
    } else {
        const preset = [
            ...presetsWithAggregation,
            ...presetsWithoutAggregation,
        ]
        .find(preset => `${preset.id}` === configuration.value);
        if (preset) {
            return {
                interval: {
                    from: preset.dateFrom,
                    to: preset.dateTo,
                },
                intervalTitle: preset.title,
            }
        } else {
            return { interval: dashboardDateInterval };
        }
    }
}

function makeStateMessageIfNeeded(status: UseHealthDataStatus, isEmptyDataset: boolean, data: HealthDataCollection[], component: DashboardComponent): StateMessageProps | null {

    if (component.dataTypeIds.indexOf(healthTypeIdentifiers.workouts) !== -1) {
        const workoutValueConfiguration = component.configuration.find(conf => conf.key === WorkoutChartConfigurationKey.WORKOUT_VALUE);
        if (!workoutValueConfiguration) {
            return {
                title: 'Missing configuration',
                messages: [
                    'Please choose the <i>workout value</i> in the chart configuration.',
                ]
            }
        }
    }

    if (status === UseHealthDataStatus.error) {
        return {
            title: 'Unable to load data',
            messages: [
                'We are sorry, but something went wrong. Please try it later.',
                'If the problem persists, please contact us at <a href="mailto:hello@healthexport.app">hello@healthexport.app',
            ]
        }
    }

    if (status === UseHealthDataStatus.data && isEmptyDataset) {
        return {
            title: 'No data available',
            messages: [
                'There are no data available in the selected time period.',
            ]
        }
    }

    return null;
}

function makeWorkoutValueConfiguration(component: DashboardComponent): WorkoutChartValue | null {
    const rawValue = component.configuration.find(conf => conf.key === WorkoutChartConfigurationKey.WORKOUT_VALUE)?.value;
    if (rawValue) {
        return rawValue as WorkoutChartValue;
    } else {
        return null;
    }
}

function makeWorkoutActivityConfiguration(component: DashboardComponent): string | null {
    return component.configuration.find(conf => conf.key === WorkoutChartConfigurationKey.WORKOUT_ACTIVITY)?.value ?? null;
}