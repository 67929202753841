import { SleepInterval } from "./SleepInterval"

export type SleepDay = {
    date: Date,
    hoursInBed: number,
    hoursAsleep: number,
    hoursAwake: number,
    hoursCore: number,
    hoursDeep: number,
    hoursREM: number,
    intervals: SleepInterval[]
}

export function makeEmptySleepDay(date: Date): SleepDay {
    return {
        date: date,
        hoursInBed: 0,
        hoursAsleep: 0,
        hoursAwake: 0,
        hoursCore: 0,
        hoursDeep: 0,
        hoursREM: 0,
        intervals: []
    }
}