import moment from "moment";
import { FirstWeekDay } from "../generated/graphql";

export const DateUtils = {
    datesAreSameHour(d1: Date, d2: Date): boolean {
        return moment(d1).startOf('hour').isSame(moment(d2).startOf('hour'));
    },
    datesAreSameDay(d1: Date, d2: Date): boolean {
        return DateUtils.datesAreSameMonth(d1,d2) && d1.getDate() === d2.getDate();
    },
    datesAreSameMonth(d1: Date, d2: Date): boolean {
        return DateUtils.datesAreSameYear(d1, d2) && d1.getMonth() === d2.getMonth();
    },
    datesAreSameYear(d1: Date, d2: Date): boolean {
        return d1.getFullYear() === d2.getFullYear();
    },
    removeTimeFromDate(date: Date) {
        return new Date(date.getFullYear(), date.getMonth() , date.getDate());
    },
    setTimeToSecondBeforeMidnight(date: Date) {
        return new Date(date.getFullYear(), date.getMonth() , date.getDate(), 23, 59, 59);
    },
    yesterday(date: Date): Date {
        return DateUtils.removeDays(1, date);
    },
    removeDays(n: number, date: Date): Date {
        return DateUtils.addDays(-n, date);
    },
    addDays(n: number, date: Date): Date {
        const newDate = new Date(date);
        newDate.setDate(newDate.getDate() + n);
        return newDate;
    },
    firstWeekDay(date: Date, firstWeekDay: FirstWeekDay): Date {
        let dateFrom = new Date(date.getTime());
        dateFrom.setHours(0, 0, 0, 0);
    
        let firstWeekDayIndex: number;
        switch (firstWeekDay) {
            case FirstWeekDay.Monday:
                firstWeekDayIndex = 1;
                break;
            case FirstWeekDay.Sunday:
                firstWeekDayIndex = 0;
                break;
        }
        if (dateFrom.getDay() !== firstWeekDayIndex) {
            let daysToRemove = dateFrom.getDay() - firstWeekDayIndex;
            if (daysToRemove < 0) {
                daysToRemove = 7 - (-daysToRemove);
            }
            dateFrom = DateUtils.removeDays(daysToRemove, dateFrom);
        }
    
        return dateFrom;
    },
    formatHours(hours: number): string {
        let h = Math.floor(hours);  
        let m = Math.floor((hours - h) * 60);
        return `${h}h ${m}min`;
    }
};