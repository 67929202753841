import { AggregationPeriod, aggregationPeriodName } from "../../../../models/AggregationPeriod"

export interface DashboarAggregationPeriodPickerProps {
    aggregationPeriod: AggregationPeriod,
    setAggregationPeriod: (aggregationPeriod: AggregationPeriod) => void,
}

export const DashboarAggregationPeriodPicker: React.FC<DashboarAggregationPeriodPickerProps> = (props: DashboarAggregationPeriodPickerProps) => {
    return (
        <div className="dropdown">
            <button className="btn dropdown-toggle btn-outline-secondary dashboardAggregationPeriodDropdown" type="button" id="exportAgregationPeriodDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{aggregationPeriodName(props.aggregationPeriod)}</button>
            <div className="dropdown-menu" aria-labelledby="exportAgregationPeriodDropdown">
                <a className="dropdown-item" href="#" onClick={() => props.setAggregationPeriod(AggregationPeriod.hours)}>Hours</a>
                <a className="dropdown-item" href="#" onClick={() => props.setAggregationPeriod(AggregationPeriod.days)}>Days</a>
                <a className="dropdown-item" href="#" onClick={() => props.setAggregationPeriod(AggregationPeriod.months)}>Months</a>
                <a className="dropdown-item" href="#" onClick={() => props.setAggregationPeriod(AggregationPeriod.years)}>Years</a>
            </div>
        </div>
    )
}