import { ChangeAccountModal } from "../pages/change_account/ChangeAccountModal"
import { SideBar, SideBarItem } from "./SideBar"

export interface AppContainerProps {
    title: string,
    activeSideBarItem?: SideBarItem,
    children: React.ReactNode;
}

export const AppContainer: React.FC<AppContainerProps> = (props: AppContainerProps) => {
    return (
        <>
            <div className="wrapper">
                <div className="sidebar sidebarBackground" data-color="green" data-background-color="white"></div>
                <SideBar activeItem={props.activeSideBarItem} />
                <div className="main-panel">
                    <nav className="navbar navbar-expand-lg navbar-transparent navbar-absolute fixed-top">
                        <div className="container-fluid">
                            <div className="navbar-wrapper">
                                <a className="navbar-brand" href="#">{props.title}</a>
                            </div>
                            <button className="navbar-toggler" type="button" data-toggle="collapse" aria-controls="navigation-index" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="sr-only">Toggle navigation</span>
                                <span className="navbar-toggler-icon icon-bar"></span>
                                <span className="navbar-toggler-icon icon-bar"></span>
                                <span className="navbar-toggler-icon icon-bar"></span>
                            </button>
                        </div>
                    </nav>
                    <div className="content">
                        {props.children}
                    </div>
                </div>
            </div>
            <ChangeAccountModal />
        </>
    )
}