import { presetsWithoutAggregation } from "./DatePreset"

export type ComponentTimePeriod = {
    id: number,
    title: string,
}

export type DashboardComponentTimePeriod =  ComponentTimePeriod & {
    id: 0,
    title: 'Dashboard time period',
}

export type FixedComponentTimePeriod =  ComponentTimePeriod & {
    id: 1,
    datePresetId: number,
}

export function makeAllComponentTimePeriods(): ComponentTimePeriod[] {
    return [
        {
            id: 0,
            title: 'Dashboard time period',
        },
        ...presetsWithoutAggregation.map(preset => ({
            id: preset.id,
            title: preset.title,
            dataPresetId: preset.id,
        }))
    ]
}