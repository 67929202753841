import { Fragment } from "react";
import { HealthType } from "../../../../entities/api/HealthType"
import { HealthDataCollection } from "../../../../models/datasources/HERemoteApiService";
import { DashboardComponentConfigurationValue } from "../../../../generated/graphql";
import { healthTypeIdentifiers } from "../../../../models/datasources/HealthTypeIdentifier";
import { WorkoutChartConfigurationKey } from "../../../../models/chart_configuration_keys/WorkoutChartConfigurationKey";
import { nameForWorkoutChartValue, WorkoutChartValue } from "../../../../models/WorkoutChartValue";
import "./DashboardComponentTitle.css";

export interface DashboardComponentTitleProps {
    types: ({ type: HealthType, isAggregated: boolean })[]
    data: HealthDataCollection[],
    dateIntervalTitle?: string,
    componentConfiguration: DashboardComponentConfigurationValue[],
    showExportButton: boolean,
    onExportButtonClick: () => void,
}

export const DashboardComponentTitle: React.FC<DashboardComponentTitleProps> = (props: DashboardComponentTitleProps) => (
    <div className="cartTitleWrapper">
        <h4 className="card-title">
            {
                props.types.map((type, index) => (
                    <Fragment key={index}>{index !== 0 ? ' | ' : ''}{ makeTitle(type.type, props.componentConfiguration) } <small>{ makeUnitsStringForIndex(index, props) }</small></Fragment>
                ))
            }
        </h4>
        <div className="cardTitleRightSide">
            <img
                // We can't use the conditional rendering, because Safari doesn't refresh flex width after removal of an element.
                // ¯\_(ツ)_/¯
                style={props.showExportButton ? {} : { visibility: 'hidden' }}
                alt="Export chart button"
                src="/images/download.svg"
                onClick={props.onExportButtonClick}
            />
            { props.dateIntervalTitle && 
                <p>{ props.dateIntervalTitle }</p>
            }
        </div>
    </div>
)

function makeTitle(type: HealthType, componentConfiguration: DashboardComponentConfigurationValue[]): string {
    const workoutValueConfiguration = componentConfiguration.find(conf => conf.key === WorkoutChartConfigurationKey.WORKOUT_VALUE);
    if (type.id === healthTypeIdentifiers.workouts && workoutValueConfiguration) {
        return `${type.name}: ${nameForWorkoutChartValue(workoutValueConfiguration.value as WorkoutChartValue)}`
    } else {
        return type.name;
    }
}

function makeUnitsStringForIndex(index: number, props: DashboardComponentTitleProps): string {
    if (props.data.length > index) {
        return makeUnitsStringForType(props.data[index]);
    } else {
        return "";
    }
}

function makeUnitsStringForType(healthData: HealthDataCollection | undefined): string {
    if (healthData) {
        return `(${healthData.units})`;
    } else {
        return '';
    }
}