import { HealthTypes } from "../entities/api/HealthTypes";
import { HealthTypeRepository } from "../models/datasources/HealthTypeRepository";

export enum UseDataTypesStatus {
    loading,
    error,
    data,
}

export type UseDataTypesState = {
    status: UseDataTypesStatus,
    error?: Error,
    types?: HealthTypes,
};

export function useDataTypes(): HealthTypes {
    const types = HealthTypeRepository.getTypes();
    removeUnsupportedDataTypes(types);
    return types;
}

function removeUnsupportedDataTypes(dataTypes: HealthTypes) {
    // Remove record based HR types
    for (let categoryI = 0; categoryI < dataTypes.recordBased.length; categoryI++) {
        dataTypes.recordBased[categoryI].types = dataTypes.recordBased[categoryI].types
            .filter(type => (type.id !== 52 && type.id !== 53 && type.id !== 54));
    }
}