import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { logoutUsecase } from "../../models/use_cases/LogoutUsecase";
import { setAccountToNull } from "../../reducers/AccountReducer";
import { RootState } from "../../Store";

export enum SideBarItem {
    dashboard,
    csv,
    workouts,
    settings,
    nutrition,
    sleep,
}

export interface SideBarProps {
    activeItem?: SideBarItem
}

export const SideBar: React.FC<SideBarProps> = (props: SideBarProps) => {
    const history = useHistory();
    const activeNavItemClassName = ' active';
    const connectedAccountCount = useSelector((state: RootState) => state.accountReducer?.connectedAccounts.length ?? 0);
    const dispatch = useDispatch();

    function logout() {
        logoutUsecase();
        dispatch(setAccountToNull());
        history.push('/');
        window.location.reload();
    }

    function historyPushAndCloseMenu(path: string) {
        history.push(path);
        // @ts-expect-error
        $(".navbar-toggler")[0].click();
    }

    return (
        <div className="sidebar" data-color="green" data-background-color="white">
            <div className="sidebar-wrapper">
                <ul className="nav">
                    <li className={`nav-item${props.activeItem === SideBarItem.dashboard ? activeNavItemClassName : ''}`}>
                        <a className="nav-link" onClick={() => historyPushAndCloseMenu('/dashboard')}>
                            <i className="material-icons">insert_chart</i>
                            <p>Dashboard</p>
                        </a>
                    </li>
                    <li className={`nav-item${props.activeItem === SideBarItem.csv ? activeNavItemClassName : ''}`}>
                        <a className="nav-link" onClick={() => historyPushAndCloseMenu('/export')}>
                            <i className="material-icons">insert_drive_file</i>
                            <p>CSV</p>
                        </a>
                    </li>
                    <li className={`nav-item${props.activeItem === SideBarItem.workouts ? activeNavItemClassName : ''}`}>
                        <a className="nav-link" onClick={() => historyPushAndCloseMenu('/workouts')}>
                            <i className="material-icons">pool</i>
                            <p>Workouts</p>
                        </a>
                    </li>
                    <li className={`nav-item${props.activeItem === SideBarItem.nutrition ? activeNavItemClassName : ''}`}>
                        <a className="nav-link" onClick={() => historyPushAndCloseMenu('/nutrition')}>
                            <i className="material-icons">restaurant_menu</i>
                            <p>Nutrition</p>
                        </a>
                    </li>
                    <li className={`nav-item${props.activeItem === SideBarItem.sleep ? activeNavItemClassName : ''}`}>
                        <a className="nav-link" onClick={() => historyPushAndCloseMenu('/sleep')}>
                            <i className="material-icons">hotel</i>
                            <p>Sleep</p>
                        </a>
                    </li>
                </ul>
            </div>
            <div className="sidebar-wrapper">
                <ul className="nav">
                    { connectedAccountCount > 0 &&
                        <li className="nav-item" id="changeActiveUserButton">
                            <a className="nav-link" data-toggle="modal" href="#changeUserModal">
                                <i className="material-icons">people</i>
                                <p>Change account</p>
                            </a>
                        </li>
                    }
                    <li className="nav-item">
                        <a className="nav-link" href="mailto:hello@healthexport.app">
                            <i className="material-icons">support</i>
                            <p>Support</p>
                        </a>
                    </li>
                    <li className={`nav-item${props.activeItem === SideBarItem.settings ? activeNavItemClassName : ''}`}>
                        <a className="nav-link" onClick={() => historyPushAndCloseMenu('/settings')}>
                            <i className="material-icons">settings</i>
                            <p>Settings</p>
                        </a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" onClick={logout}>
                            <i className="material-icons">login</i>
                            <p>Sign out</p>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    )
}