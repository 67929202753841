export interface AddComponentButtonProps {
    onClickAction: () => void,
}

export const AddComponentButton: React.FC<AddComponentButtonProps> = (props: AddComponentButtonProps) => {
    return (
        <button
            type="button"
            className="btn btn-fab"
            onClick={props.onClickAction}
            ><i className="material-icons">add</i></button>
    )
}