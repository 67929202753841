import { HealthType } from "../entities/api/HealthType";
import { NumberFormat } from "../generated/graphql";
import { HealthDataCollection } from "../models/datasources/HERemoteApiService";
import { AggregationMode, HealthDataAggregator } from "../models/HealthDataAggregator";
import { formatNumber } from "../models/UserUtils";

export function useSingleChartValue(data: HealthDataCollection[], numberFormat: NumberFormat, dataType: HealthType | null): string {
    if (data.length === 0 || dataType === null) {
        return '-';
    } else {
        let total: number;

        const validRecords = data[0]
            .records
            .filter(record => {
                if (typeof record.value === 'number') {
                    return !isNaN(record.value);
                } else {
                    return true;
                }
            })
        const sum = validRecords
            .map(record => {
                if (typeof record.value === 'number') {
                    return record.value;
                } else {
                    return parseFloat(record.value);
                }
            })
            .reduce((a,b) => a + b);

        switch (HealthDataAggregator.aggregationModeForType(dataType)) {
            case AggregationMode.sum:
                total = sum;
                break;
            case AggregationMode.average:
                total = sum / validRecords.length;
                break;
        }

        let preferredFractionDigits: number;
        if (total > 180) {
            preferredFractionDigits = 0
        } else {
            preferredFractionDigits = 2
        }

        return formatNumber(total, preferredFractionDigits, numberFormat);
    }
}


export function useSingleChartNumberValue(data: HealthDataCollection[], dataType: HealthType | null): number {
    if (data.length === 0 || dataType === null) {
        return 0;
    } else {
        let total: number;

        const validRecords = data[0]
            .records
            .filter(record => {
                if (typeof record.value === 'number') {
                    return !isNaN(record.value);
                } else {
                    return true;
                }
            })
        const sum = validRecords
            .map(record => {
                if (typeof record.value === 'number') {
                    return record.value;
                } else {
                    return parseFloat(record.value);
                }
            })
            .reduce((a,b) => a + b);

        switch (HealthDataAggregator.aggregationModeForType(dataType)) {
            case AggregationMode.sum:
                total = sum;
                break;
            case AggregationMode.average:
                total = sum / validRecords.length;
                break;
        }
        
        return total;
    }
}