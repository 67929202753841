import moment from "moment"
import { SleepDay } from "../../../entities/sleep/SleepDay"
import { DateInterval } from "../../../hooks/UseDateInterval"
import { SleepDayChart } from "./SleepDayChart"
import { SleepDaysChart } from "./SleepDaysChart"
import { RoundedWrapper } from "../../core/rounded_wrapper/RoundedWrapper"
import "./SleepPageContent.css"
import { SleepStage, sleepStageColorHex } from "../../../entities/sleep/SleepStage"
import { DateUtils } from "../../../models/DateUtils"
import { useMediaQuery } from "react-responsive"
import { SleepUtils } from "../../../models/SleepUtils"

export interface SleepPageContentProps {
    sleepDays: SleepDay[],
    dateInterval: DateInterval,
}

export const SleepPageContent: React.FC<SleepPageContentProps> = (props: SleepPageContentProps) => {
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 950px)' });
    
    let reversedDays = [...props.sleepDays];
    reversedDays.reverse();
    const daysWithDetail = reversedDays.filter(day => !((!isTabletOrMobile && day.intervals.length === 0) || (isTabletOrMobile && day.hoursAsleep === 0 && day.hoursInBed === 0)));
    
    return (
        <>
            <RoundedWrapper>
                <SleepDaysChart
                    sleepDays={props.sleepDays}
                    dateInterval={props.dateInterval}
                />
            </RoundedWrapper>
            { daysWithDetail.length > 0 &&
                <hr className="sleepChartsSeparator" />
            }
            {
                daysWithDetail
                    .map(day => 
                        <div className="sleepDay" key={day.date.toISOString()}>
                            <RoundedWrapper>
                                <h3>{moment(day.date).format('MMMM D')}</h3>
                                { !isTabletOrMobile && day.intervals.length > 0 && 
                                    <div className="sleepDayDataWrapper">
                                        <div className="sleepChartWrapper">
                                            <SleepDayChart sleepDay={day} />
                                        </div>
                                        <div className="sleepValuesWrapper">
                                            <p className="timeAsleepValueLabel" style={{ color: sleepStageColorHex(SleepStage.asleep) }}>{ DateUtils.formatHours(SleepUtils.makeSleepingHours(day)) }</p>
                                            <p style={{ color: sleepStageColorHex(SleepStage.inBed) }}>{ DateUtils.formatHours(day.hoursInBed) }</p>
                                            <p style={{ color: sleepStageColorHex(SleepStage.awake) }}>{ DateUtils.formatHours(day.hoursAwake) }</p>
                                            <p style={{ color: sleepStageColorHex(SleepStage.core) }}>{ DateUtils.formatHours(day.hoursCore) }</p>
                                            <p style={{ color: sleepStageColorHex(SleepStage.deep) }}>{ DateUtils.formatHours(day.hoursDeep) }</p>
                                            <p style={{ color: sleepStageColorHex(SleepStage.rem) }}>{ DateUtils.formatHours(day.hoursREM) }</p>
                                        </div>
                                    </div>
                                }
                                { isTabletOrMobile && (day.hoursAsleep > 0 || day.hoursInBed > 0) && 
                                    <div className="sleepDayDataWrapperMobile">
                                        <p className="sleepMobileValueNameLabel" style={{ color: sleepStageColorHex(SleepStage.asleep) }}>Time asleep:</p>
                                        <p className="sleepMobileValueLabel" style={{ color: sleepStageColorHex(SleepStage.asleep) }}>{ DateUtils.formatHours(SleepUtils.makeSleepingHours(day)) }</p>
                                        <p className="sleepMobileValueNameLabel" style={{ color: sleepStageColorHex(SleepStage.inBed) }}>Time in bed:</p>
                                        <p className="sleepMobileValueLabel" style={{ color: sleepStageColorHex(SleepStage.inBed) }}>{ DateUtils.formatHours(day.hoursInBed) }</p>
                                        <p className="sleepMobileValueNameLabel" style={{ color: sleepStageColorHex(SleepStage.awake) }}>Time awake:</p>
                                        <p className="sleepMobileValueLabel" style={{ color: sleepStageColorHex(SleepStage.awake) }}>{ DateUtils.formatHours(day.hoursAwake) }</p>
                                        <p className="sleepMobileValueNameLabel" style={{ color: sleepStageColorHex(SleepStage.core) }}>Time core:</p>
                                        <p className="sleepMobileValueLabel" style={{ color: sleepStageColorHex(SleepStage.core) }}>{ DateUtils.formatHours(day.hoursCore) }</p>
                                        <p className="sleepMobileValueNameLabel" style={{ color: sleepStageColorHex(SleepStage.deep) }}>Time deep:</p>
                                        <p className="sleepMobileValueLabel" style={{ color: sleepStageColorHex(SleepStage.deep) }}>{ DateUtils.formatHours(day.hoursDeep) }</p>
                                        <p className="sleepMobileValueNameLabel" style={{ color: sleepStageColorHex(SleepStage.rem) }}>Time REM:</p>
                                        <p className="sleepMobileValueLabel" style={{ color: sleepStageColorHex(SleepStage.rem) }}>{ DateUtils.formatHours(day.hoursREM) }</p>
                                    </div>
                                }
                            </RoundedWrapper>
                        </div>
                    )
            }
        </>
    )
}