import { DateIntervalData } from "../../../../hooks/UseDateInterval";
import Flatpickr from "react-flatpickr";
import { useSelector } from "react-redux";
import { RootState } from "../../../../Store";
import { FirstWeekDay } from "../../../../generated/graphql";
import { DateUtils } from "../../../../models/DateUtils";

export interface DashboardDatePickerProps {
    dateInterval: DateIntervalData,
}

export const DashboardDatePicker: React.FC<DashboardDatePickerProps> = (props: DashboardDatePickerProps) => {
    const weekStartPreference = useSelector((state: RootState) => state.preferencesReducer.preferences.firstWeekDay);
    return (
        <Flatpickr
                className="workoutsDatePicker"
                value={[props.dateInterval.interval.from, props.dateInterval.interval.to]}
                options={{
                    mode: 'range',
                    locale: {
                        firstDayOfWeek: weekStartPreference === FirstWeekDay.Sunday ? 0 : 1,
                    },
                }}
                onChange={dates => {
                    if (dates.length === 2 && dates[0] && dates[1]) {
                        props.dateInterval.setInterval({
                            from: dates[0],
                            to: DateUtils.setTimeToSecondBeforeMidnight(dates[1]),
                        });
                    }
                }}
            />
    )
}