export function insertAtArrayIndex<T>(array: T[], index:  number, item: T): T[] {
    return [
        ...array.slice(0, index),
        item,
        ...array.slice(index, array.length),
    ]
}

export function safeAt<T>(array: T[] | undefined | null, index:  number): T | null {
    if (array && index >= 0 && array.length > index) {
        return array[index];
    } else {
        return null;
    }
}