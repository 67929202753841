export enum WorkoutChartValue {
    activeEnergyBurned = 'ACTIVE_ENERGY_BURNED',
    distance = 'DISTANCE',
}

export function allWorkoutChartValues(): WorkoutChartValue[] {
    return [
        WorkoutChartValue.activeEnergyBurned,
        WorkoutChartValue.distance,
    ]
}

export function nameForWorkoutChartValue(value: WorkoutChartValue): string {
    switch (value) {
        case WorkoutChartValue.activeEnergyBurned:
            return 'Active energy burned';
        case WorkoutChartValue.distance:
            return 'Distance';
    }
}