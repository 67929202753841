import "./ActivityIndicator.css";

export interface ActivityIndicatorProps {
    useFullHeight?: boolean,
}

export const ActivityIndicator: React.FC<ActivityIndicatorProps> = (props: ActivityIndicatorProps) => {
    const cssClasses = ['loaderWrapper'];
    if (props.useFullHeight) {
        cssClasses.push('loaderWrapperDefaultHeight');
    }
    return (
        <div className={ cssClasses.join(' ') }>
            <div className="loader"></div>
        </div>
    )
};

ActivityIndicator.defaultProps = {
    useFullHeight: true,
}