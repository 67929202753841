import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { SleepDay } from "../entities/sleep/SleepDay";
import { SleepRepository } from "../models/datasources/SleepRepository";
import { RootState } from "../Store";
import { DateInterval } from "./UseDateInterval";

export enum UseSleepDataStatus {
    loading,
    error,
    data,
}

export type UseSleepDataState = {
    status: UseSleepDataStatus,
    data: SleepDay[] | null,
    error: Error | null
}

export function useSleepData(dateInterval: DateInterval): UseSleepDataState {
    const account = useSelector((state: RootState) => state.accountReducer?.activeAccount);
    
    const [status, setStatus] = useState(UseSleepDataStatus.loading);
    const [data, setData] = useState(null as SleepDay[] | null);
    const [error, setError] = useState(null as Error | null);

    useEffect(() => {
        if (account) {
            SleepRepository.getData(account, dateInterval.from, dateInterval.to)
                .catch(error => {
                    setError(error);
                    setStatus(UseSleepDataStatus.error);
                })
                .then(data => {
                    setData(data as SleepDay[]);
                    setStatus(UseSleepDataStatus.data);
                });
        }
    }, [account, dateInterval.from, dateInterval.to]);

    return {
        status: status,
        data: data,
        error: error,
    };
}