import { useHistory } from "react-router"
import { DashboardPreset, allDashboardPresets, dashboardPresetName } from "../../../../models/DashboardPreset";

export interface NoDashboardMessageProps {
    onDashboardPresetClick: (preset: DashboardPreset) => void
}

export const NoDashboardMessage: React.FC<NoDashboardMessageProps> = (props: NoDashboardMessageProps) => {
    const history = useHistory();

    function setupDashboardButtonClicked() {
        history.push('/dashboard/settings');
    }

    return (
        <div id="dashboardMessageStateWrapper">
            <img src="images/dashboard_config_needed.svg" alt="No data available" />
            <h3><b>Configuration needed</b></h3>
            <p>Setup your first dashboard <a href="#" onClick={setupDashboardButtonClicked}>here</a>.</p>
            <hr className="dashboardEmptyStateDivider"/>
            <p>Or choose one of these templates:</p>
            <div className="noDashboardPresetsWrapper">
                { allDashboardPresets().map(preset =>
                    <button
                        key={preset}
                        type="button"
                        className="btn"
                        onClick={() => props.onDashboardPresetClick(preset) }
                    >{ dashboardPresetName(preset) }</button>)
                }
             </div>
        </div>
    )
}