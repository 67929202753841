import moment from "moment";
import { workoutDates } from "../../components/pages/workouts/WorkoutUtils";
import { Workout } from "../../entities/api/Workout";
import { DateIntervalData } from "../../hooks/UseDateInterval";
import { Account } from "../../reducers/AccountReducer";
import { AggregationPeriod } from "../AggregationPeriod";
import { HealthDataAggregator } from "../HealthDataAggregator";
import { HealthTypeRepository } from "./HealthTypeRepository";
import { HealthDataCollection, HERemoteApiService } from "./HERemoteApiService";

export const CsvExportService = {
    async fetchAndGenerateCsv(account: Account, typeIds: Set<number>, dateInterval: DateIntervalData, aggregationPeriod: AggregationPeriod): Promise<string> {
        // Fetch types and data
        const types = HealthTypeRepository.getTypes();
        let data = new Map<number, HealthDataCollection[]>();
        for await (const typeId of typeIds) {
            const isAggregated = HealthDataAggregator.isTypeIdAggregated(typeId, types);
            const healthData = isAggregated
                ? await HERemoteApiService.getAggregatedData(account, typeId, dateInterval.interval.from, dateInterval.interval.to, aggregationPeriod)
                : await HERemoteApiService.getData(account, typeId, dateInterval.interval.from, dateInterval.interval.to);
            data.set(typeId, healthData);
        }
        const typesInExport = types.aggregated
            .concat(types.recordBased)
            .flatMap(x => x.types)
            .filter(x => typeIds.has(x.id))
            .sort((a, b) => (a.name > b.name) ? 1 : -1);

        // Generate CSV header
        let csv = 'Date';
        typesInExport.forEach(type => {
            const typeData = data.get(type.id);
            if (typeData && typeData.length > 0) {
                typeData.forEach(dataCollection => {
                    csv += `,${type.name} (${dataCollection.units})`;
                });
            } else {
                csv += `,${type.name}`;
            }
        });

        // Get all csv row dates
        let rows: CsvRow[] = [];
        data.forEach((data, typeId) => {
            data.forEach(dataCollection => {
                dataCollection.records.forEach(dataRecord => {
                    const isAggregated = HealthDataAggregator.isTypeIdAggregated(typeId, types);
                    const row = rows.findIndex(row => row.isAggregated === isAggregated && row.date === dataRecord.time);
                    if (row === -1) {
                        rows.push({
                            date: dataRecord.time,
                            isAggregated: isAggregated,
                        });
                    }
                })
            });
        });
        rows.sort((a, b) => (a.date > b.date) ? 1 : -1);

        // Generate CSV rows
        rows.forEach(row => {
            csv += `\n${formatDateForCsv(row.date, row.isAggregated, aggregationPeriod)}`
            typesInExport.forEach(type => {
                const typeData = data.get(type.id);
                if (typeData && typeData.length > 0) {
                    typeData.forEach(dataCollection => {
                        if (dataCollection.records.length > 0 && dataCollection.records[0].time === row.date && row.isAggregated === HealthDataAggregator.isTypeIdAggregated(type.id, types)) {
                            if (typeof dataCollection.records[0].value === 'number' && isNaN(dataCollection.records[0].value)) {
                                csv += `,`;
                            } else {
                                csv += `,${dataCollection.records[0].value}`;
                            }
                            dataCollection.records.shift();
                        } else {
                            csv += ',';
                        }
                    });
                } else {
                    csv += ',';
                }
            })
        });

        return csv;
    },
    generateWorkoutCsv(workouts: Workout[]): string {
        let csvColumns = ['Activity', 'Duration (s)'];
        workouts.forEach(workout => {
            workout.values
                .filter(value => value.name !== 'Workout date' && value.name !== 'Activity')
                .forEach(workoutMetadata => {
                    const columnName = `${workoutMetadata.name} (${workoutMetadata.units})`;
                    if (csvColumns.indexOf(columnName) === -1) {
                        csvColumns.push(columnName);
                    }
                });
        });
        csvColumns.sort();
        let csv = 'Date,' + csvColumns.join(',');
        workouts
            .filter(workout => workoutDates(workout).filter(date => date !== null).length === 2) // Only workouts with dates
            .slice()
            .reverse()
            .forEach(workout => {
                const dates = workoutDates(workout) as Date[];
                const date = moment(dates[0]).format("YYYY-MM-DD HH:mm:ss") + " - " + moment(dates[1]).format("YYYY-MM-DD HH:mm:ss");
                csv += `\n${date}`;
                csvColumns
                    .forEach(csvColumn => {
                        let columnValue = '';
                        if (csvColumn === 'Activity') {
                            columnValue = workout.values.find(x => x.name === 'Activity')?.value ?? '';
                        } else if (csvColumn === 'Duration (s)') {
                            columnValue = Math.abs(moment(dates[0]).diff(moment(dates[1]), 'seconds')).toString();
                        } else {
                            const metadataItem = workout.values.find(workoutMetadata => `${workoutMetadata.name} (${workoutMetadata.units})` === csvColumn);
                            if (metadataItem) {
                                columnValue = metadataItem.value;
                            }
                        }
                        csv += `,${columnValue}`
                    });
            });
        return csv;
    },
    downloadCsv(filename: string, csvContent: string) {
        let element = document.createElement('a');
        element.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvContent));
        element.setAttribute('download', filename);
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    }
};

type CsvRow = {
    date: string,
    isAggregated: boolean,
}

export function formatDateForCsv(dateString: string, isAggregatedType: boolean, aggregationPeriod: AggregationPeriod): string {
    if (!isAggregatedType) {
        return moment(dateString).format('YYYY-MM-DD HH:mm:ss');
    }
    switch (aggregationPeriod) {
        case AggregationPeriod.hours:
            return moment(dateString).format('YYYY-MM-DD HH:mm:ss');
        case AggregationPeriod.days:
            return moment(dateString).format('YYYY-MM-DD');
        case AggregationPeriod.months:
            return moment(dateString).format('YYYY-MM');
        case AggregationPeriod.years:
            return moment(dateString).format('YYYY');
    }
}