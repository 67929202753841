import { SHA512 } from "crypto-js";
import { UserInfo } from "../entities/UserInfo";
import Base64 from 'crypto-js/enc-base64';
import { NumberFormat } from "../generated/graphql";

export function makeUserInfo(sub: string): UserInfo {
    let encryptionKey;
    if (sub.indexOf('.') < 0) {
        encryptionKey = sub.substr(0, 32);
    } else {
        encryptionKey = sub.substr(sub.indexOf('.') + 1, 32);
    }
    const hashedSub = Base64.stringify(SHA512(sub));
    return {
        accountKey: sub,
        accountKeySha512: hashedSub,
        encryptionKey: encryptionKey,
    };
}

export function formatNumber(number: number, preferredFractionDigits: number, format: NumberFormat): string {
    const fractionDigitsSeparator: string = format === NumberFormat.Classic
        ? ','
        : '.';
    const groupsSeparator: string = format === NumberFormat.Classic
        ? ' '
        : ',';
    const numberParts = number.toFixed(preferredFractionDigits).split('.');
    const groupingSize = 3;
    if (numberParts.length > 0 && numberParts[0].length > groupingSize) {
        let formattedNumber = '';
        let i = 0;
        numberParts[0].split('').reverse().forEach(digit => {
            if (formattedNumber.length > 0 && i % groupingSize === 0) {
                formattedNumber += groupsSeparator + digit;
            } else {
                formattedNumber += digit;
            }
            i += 1
        });
        numberParts[0] = formattedNumber.split('').reverse().join('');
    }
    if (numberParts.length > 1) {
        let formattedNumberParts: string[] = [];
        let foundNonZero = false;
        numberParts[1].split('').reverse().forEach(digit => {
            if (foundNonZero || digit !== '0') {
                foundNonZero = true;
                formattedNumberParts.push(digit);
            }
        });
        if (formattedNumberParts.length > 0) {
            numberParts[1] = formattedNumberParts.reverse().join('');
        } else {
            numberParts.pop();
        }
    }
    return numberParts.join(fractionDigitsSeparator);
}