import { useToasts } from "react-toast-notifications";

export interface UseConfigurationSavedToastResult {
    showConfigurationSavedToast: () => void,
}

export function useConfigurationSavedToast(): UseConfigurationSavedToastResult {
    const { addToast } = useToasts();
    return {
        showConfigurationSavedToast: () => 
            addToast('Configuration saved', {
                appearance: 'success',
                autoDismiss: true,
                autoDismissTimeout: 1500,
            })
    }
}