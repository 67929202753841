export enum DashboardPreset {
    HeartRate = "HEART_RATE",
    Movement = "MOVEMENT",
    Running = "RUNNING",
    Workouts = "WORKOUTS",
}

export function allDashboardPresets(): Array<DashboardPreset> {
    return [
        DashboardPreset.HeartRate,
        DashboardPreset.Movement,
        DashboardPreset.Running,
        DashboardPreset.Workouts,
    ]
}

export function dashboardPresetName(preset: DashboardPreset): string {
    switch(preset) {
        case DashboardPreset.HeartRate:
            return "Heart rate";
        case DashboardPreset.Movement:
            return "Movement";
        case DashboardPreset.Running:
            return "Running";
        case DashboardPreset.Workouts:
            return "Workouts";
    }
}