import { ChartData, ChartDataset } from "chart.js";
import moment from "moment";
import { Bar } from "react-chartjs-2"
import { useSelector } from "react-redux";
import { SleepDay } from "../../../entities/sleep/SleepDay"
import { SleepInterval } from "../../../entities/sleep/SleepInterval";
import { SleepStage, sleepStageColorHex } from "../../../entities/sleep/SleepStage";
import { TimeFormat } from "../../../generated/graphql";
import { useChartLabelColor } from "../../../hooks/UseChartLabelColor";
import useWindowDimensions from "../../../hooks/UseWindowDimensions";
import { SleepUtils } from "../../../models/SleepUtils";
import { RootState } from "../../../Store";

export interface SleepDayChartProps {
    sleepDay: SleepDay,    
}

export const SleepDayChart: React.FC<SleepDayChartProps> = (props: SleepDayChartProps) => {
    const chartData = makeChartData(props.sleepDay);
    const preferences = useSelector((state: RootState) => state.preferencesReducer.preferences);
    const labelColor = useChartLabelColor();
    const windowDimensions = useWindowDimensions();

    return (
        <Bar
            redraw={true}
            data={chartData.chartData}
            height={500}
            options={{
                indexAxis: 'y',
                plugins: {
                    legend: {
                        display: false,
                    },
                    tooltip: {
                        enabled: false,
                    }
                },
                aspectRatio: windowDimensions.width < 1600 ? 3 : 5,
                scales: {
                    xAxis: {
                        stacked: true,
                        ticks: {
                            color: labelColor,
                            stepSize: 1,
                            callback(this, tickValue, index, ticks) {
                                if (preferences.timeFormat === TimeFormat.Format12h) {
                                    return moment(chartData.hours[index]).format('h a');
                                } else {
                                    return moment(chartData.hours[index]).format('H:mm');
                                }
                            },
                        }
                    },
                    yAxis: {
                        stacked: true,
                        ticks: {
                            color: labelColor,
                        }
                    },
                },
            }}
        />
    )
}

type SleepDayChartData = {
    hours: Date[],
    chartData: ChartData<"bar", (number | null)[], unknown>
}

function makeChartData(sleepDay: SleepDay): SleepDayChartData {

    // Get timeline hours
    if (sleepDay.intervals.length === 0) {
        return {
            hours: [],
            chartData: {
                labels: [],
                datasets: [],
            }
        }
    };

    let start = sleepDay.intervals[0].from;
    let to = sleepDay.intervals[0].to;
    sleepDay.intervals.forEach(interval => {
        if (interval.from < start) {
            start = interval.from;
        }
        if (interval.to > to) {
            to = interval.to;
        }
    });
    
    let lastHour = moment(to).endOf('h').toDate();
    let labelHours: Date[] = [
        moment(start).startOf('h').toDate()
    ];
    if (labelHours[0] < lastHour) {
        while(labelHours[labelHours.length - 1] < lastHour) {
            labelHours.push(
                moment(labelHours[labelHours.length - 1]).add(1, 'h').toDate()
            )
        }
        labelHours.push(lastHour);
    }

    // Dataset calculation
    let datasets: ChartDataset<"bar", number[]>[] = [];
    makeDatasets(
        datasets,
        SleepUtils.makeSleepingIntervals(sleepDay.intervals),
        labelHours,
        sleepStageColorHex(SleepStage.asleep),
        0,
    );
    [
        SleepStage.inBed,
        SleepStage.awake,
        SleepStage.core,
        SleepStage.deep,
        SleepStage.rem,
    ].forEach((stage, index) => {
        makeDatasets(
            datasets,
            sleepDay.intervals.filter(interval => interval.stage === stage),
            labelHours,
            sleepStageColorHex(stage),
            index + 1
        );
    });

    return {
        hours: labelHours,
        chartData: {
            labels: ['Asleep', 'In bed', 'Awake', 'Core', 'Deep', 'REM'],
            datasets: datasets,
        }
    }
}

function makeDatasets(
    datasets: ChartDataset<"bar", number[]>[],
    intervals: SleepInterval[],
    labelHours: Date[],
    fillColor: string,
    stageIndex: number,
) {
    if (intervals.length > 0) {
        let intervalsToAdd = [];
        if (intervals[0].from > labelHours[0]) {
            intervalsToAdd.push({
                from: labelHours[0],
                to: intervals[0].from,
                fill: false,
            });
        }
        let index = 0;
        while(index < intervals.length) {
            intervalsToAdd.push({
                from: intervals[index].from,
                to: intervals[index].to,
                fill: true,
            });
            index += 1;
            if (index === intervals.length && intervals[index - 1].to < labelHours[labelHours.length - 1]) {
                intervalsToAdd.push({
                    from: intervals[index - 1].to,
                    to: labelHours[labelHours.length - 1],
                    fill: false,
                });
            } else if (index !== intervals.length && intervals[index].from > intervals[index - 1].to) {
                intervalsToAdd.push({
                    from: intervals[index - 1].to,
                    to: intervals[index].from,
                    fill: false,
                });
            }
        }
        intervalsToAdd.reverse();
        intervalsToAdd.forEach(intervalToAdd => {
            datasets.push(
                makeDataset(
                    intervalToAdd.from,
                    intervalToAdd.to,
                    intervalToAdd.fill ? fillColor : 'rgba(0,0,0,0)',
                    stageIndex,
                )
            );
        })
    }
}

function makeDataset(from: Date, to: Date, backgroundColor: string, index: number): ChartDataset<"bar", number[]> {
    const value = (from.getTime() - to.getTime()) / 3600000;
    return {
        backgroundColor: backgroundColor,
        data: index === 0
            ? [ value ]
            : [ ...Array.from({length: index}).map(_ => 0), value ],
    };
}