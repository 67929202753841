import gql from "graphql-tag";

export const dashboardWithComponents = gql`
    fragment DashboardWithComponents on Dashboard {
        uid
        dashboardUuid
        name
        defaultDatePreset
        components {
            componentUuid
            dataTypeIds
            type
            configuration {
                key
                value
            }
        }
    }
`