import { ChartData } from "chart.js";
import { Line } from "react-chartjs-2"
import { useSelector } from "react-redux";
import { DashboardComponentConfigurationValue } from "../../../../../generated/graphql";
import { DateIntervalData } from "../../../../../hooks/UseDateInterval";
import { BarOrLineChartConfigurationKey } from "../../../../../models/chart_configuration_keys/BarOrLineChartConfigurationKey";
import { HealthDataCollection } from "../../../../../models/datasources/HERemoteApiService";
import { RootState } from "../../../../../Store";
import { ChartUtils } from "./ChartUtils";

export interface LineChartProps {
    data: HealthDataCollection[],
    dateInterval: DateIntervalData,
    configuration: DashboardComponentConfigurationValue[],
}

export const LineChart: React.FC<LineChartProps> = (props: LineChartProps) => {
    const chartData = makeChartData(props.data[0], props.dateInterval);
    const preferences = useSelector((state: RootState) => state.preferencesReducer.preferences);
    return (
        <Line
                    data={chartData}
                    height={ChartUtils.makeChartHeight()}
                    options={{
                        plugins: {
                            legend: {
                                display: false,
                            },
                            tooltip: {
                                callbacks: {
                                    label: (data: any) => ChartUtils.tooltipCallback(data, preferences.numberFormat, props.data[0].units),
                                }
                            }
                        },
                        aspectRatio: 2,
                        scales: {
                            xAxis: {
                                type: 'time',
                                time: {
                                    displayFormats: {
                                        second: ChartUtils.makeDateFormatSeconds(preferences.timeFormat),
                                        minute: ChartUtils.makeDateFormatMinutesAndHours(preferences.timeFormat),
                                        hour: ChartUtils.makeDateFormatMinutesAndHours(preferences.timeFormat),
                                    }
                                }
                            },
                            yAxis: {
                                min: makeYMin(props.configuration),
                                max: makeYMax(props.configuration),
                            },
                        },
                    }}
                />
    )
}

function makeChartData(data: HealthDataCollection, dateInterval: DateIntervalData): ChartData<"line", (number | null)[], unknown> {
    const numberdDataPoints = data.records.map(dataPoint => {
        if (typeof dataPoint.value === 'string') {
            return parseFloat(dataPoint.value);
        } else {
            return dataPoint.value as number;
        }
    });
    return {
        labels: [
            dateInterval.interval.from,
            ...data.records.map(dataPoint => new Date(dataPoint.time)),
            dateInterval.interval.to
        ],
        datasets: [
            {
                label: 'Chart',
                backgroundColor: '#00000025',
                borderColor: '#43a047',
                fill: true,
                pointBackgroundColor: '#66bb6a',
                data: [
                    null,
                    ...numberdDataPoints,
                    null
                ]
            },
            {
                label: 'Hidden data',
                hidden: true,
                data: [
                    0,
                    ...numberdDataPoints.map(_ => 0),
                    0
                ],
            },
        ],
    }
}

function makeYMin(configuration: DashboardComponentConfigurationValue[]): number | undefined {
    const rawValue = configuration.find(x => x.key === BarOrLineChartConfigurationKey.Y_MIN)?.value;
    if (rawValue) {
        return parseFloat(rawValue);
    } else {
        return undefined;
    }
}

function makeYMax(configuration: DashboardComponentConfigurationValue[]): number | undefined {
    const rawValue = configuration.find(x => x.key === BarOrLineChartConfigurationKey.Y_MAX)?.value;
    if (rawValue) {
        return parseFloat(rawValue);
    } else {
        return undefined;
    }
}