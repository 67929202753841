import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';
import { reduxStore } from './Store';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
} from "@apollo/client";
import { BatchHttpLink } from "@apollo/client/link/batch-http";

const apolloClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: new BatchHttpLink({
    uri: process.env.REACT_APP_DELTA_URL,
  }),
  defaultOptions: {
    query: {
      fetchPolicy: 'no-cache',
    },
    watchQuery: {
      fetchPolicy: 'no-cache',
      nextFetchPolicy: 'no-cache',
    },
    mutate: {
      fetchPolicy: 'no-cache',
    }
  },
});


ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={apolloClient}>
      <Provider store={reduxStore}>
          <App />
      </Provider>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('root')
);