import { HealthType } from "../../entities/api/HealthType";
import { HealthTypeCategory } from "../../entities/api/HealthTypeCategory";
import { HealthTypes } from "../../entities/api/HealthTypes";
import healthTypesJsonData from  "../../resources/healthtypes.json";
import { healthTypeIdentifiers } from "./HealthTypeIdentifier";

export const HealthTypeRepository = {
    getTypes(): HealthTypes {
        return {
            aggregated: mapHealthTypeDTOs(healthTypesJsonData.Cumulative),
            recordBased: mapHealthTypeDTOs(healthTypesJsonData.Record),
            workouts: {
                id: healthTypeIdentifiers.workouts,
                name: 'Workouts',
            }
        };
    }
}

type HealthTypeDTO = {
    id: number,
    name: string,
    subCategory: string,
}
function mapHealthTypeDTOs(dtos: HealthTypeDTO[]): HealthTypeCategory[] {
    let categories: HealthTypeCategory[] = [];
    dtos.forEach(dto => {
        const healthType: HealthType = {
            id: dto.id,
            name: dto.name,
        };
        const category = categories.find(category => category.name === dto.subCategory);
        if (category) {
            category.types.push(healthType);
        } else {
            categories.push({
                name: dto.subCategory,
                types: [healthType],
            })
        }
    });
    categories.sort((a, b) => a.name.localeCompare(b.name));
    categories.forEach(category => 
        category.types.sort((a,b) => a.name.localeCompare(b.name))
    );
    return categories;
}