import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSetUserPreferencesBusinessAccessKeyMutation } from "../../../generated/graphql";
import { RootState } from "../../../Store";
import { AppContainer } from "../../core/AppContainer";
import { HESwal } from "../../core/HESwal";
import { RoundedWrapper } from "../../core/rounded_wrapper/RoundedWrapper";
import { SideBarItem } from "../../core/SideBar";
import { setPreferences as setReduxPreferences, updatePreferencesBusinessAccessKey } from "../../../reducers/UserPreferencesReducer";
import { ConnectedBusinessAccounts } from "./ConnectedBusinessAccounts";
import { updateBusinessAccessKey } from "../../../reducers/AccountReducer";

export const SettingsBusinessPage: React.FC = () => {

    const dispatch = useDispatch();
    const me = useSelector((state: RootState) => state.accountReducer?.me);
    const [accessKey, setAccessKey] = useState(null as string | null);
    const [accessKeyMutation, accessKeyMutationResult] = useSetUserPreferencesBusinessAccessKeyMutation();
    const userPreferences = useSelector((state: RootState) => state.preferencesReducer.preferences);

    if (userPreferences.businessAccessKey && accessKey === null) {
        setAccessKey(userPreferences.businessAccessKey);
    }

    if (accessKeyMutationResult.data?.setUserPreferencesBusinessAccessKey && (accessKeyMutationResult.data.setUserPreferencesBusinessAccessKey?.businessAccessKey ?? '') !== (userPreferences.businessAccessKey ?? '')) {
        dispatch(setReduxPreferences({
            __typename: "UserPreferences",
            timeFormat: accessKeyMutationResult.data.setUserPreferencesBusinessAccessKey.timeFormat,
            numberFormat: accessKeyMutationResult.data.setUserPreferencesBusinessAccessKey.numberFormat,
            firstWeekDay: accessKeyMutationResult.data.setUserPreferencesBusinessAccessKey.firstWeekDay,
            businessAccessKey: accessKeyMutationResult.data.setUserPreferencesBusinessAccessKey.businessAccessKey,
        }))
    }

    function saveButtonClicked() {
        accessKeyMutation({
            variables: {
                uid: me?.directAccess?.uid ?? '',
                accessKey: accessKey ?? '',
            }
        }).then(result => {
            const businessAccessKey = result.data?.setUserPreferencesBusinessAccessKey?.businessAccessKey;
            if (businessAccessKey) {
                dispatch(
                    updateBusinessAccessKey(businessAccessKey)
                );
                dispatch(
                    updatePreferencesBusinessAccessKey(businessAccessKey)
                )
            }

            HESwal.fire({
                title: 'Success !',
                html: '<p>Business account access key saved.</p>',
                icon: 'success',
                confirmButtonText: 'OK',
                customClass: {
                    confirmButton: 'btn btn-success'
                }
            });
        }).catch(() => {
            HESwal.fire({
                title: 'Error !',
                html: '<p>Business account access key update failed. Please try it later.</p>',
                icon: 'error',
                confirmButtonText: 'OK',
                customClass: {
                    confirmButton: 'btn btn-danger'
                }
            });
        });
    }

    return (
        <AppContainer
            title="Business settings"
            activeSideBarItem={SideBarItem.settings}
        >
            <div className="content">
                <div className="settingsContent">
                    <p style={{
                        marginTop: '30px'
                    }}>HealthExport for Business lets you access your customer's health records remotely, so, for example, you can monitor patients as a doctor, or give more informed advices as a fitness coach.</p>
                    <p style={{
                        marginTop: '10px',
                        marginBottom: '30px'
                    }}>To learn more about HealthExport for Business reach out at <a href="mailto://hello@healthexport.app">hello@healthexport.app</a></p>
                </div>
                <RoundedWrapper>
                        <div className="form-group">
                            <p
                                style={{ marginBottom: '0' }} 
                            >Access key:</p>
                            <input 
                                type="text"
                                className="form-control"
                                placeholder="Your access key"
                                value={accessKey ?? ''}
                                onChange={(event) => setAccessKey(event.target.value)}
                            />
                            <button
                                type="button"
                                className="btn btn-success"
                                style={{ marginTop: '20px', marginBottom: '0' }}
                                onClick={saveButtonClicked}
                                >Save</button>
                            <hr
                                style={{
                                    marginTop: '30px',
                                    marginBottom: '30px',
                                }}
                            />
                            <ConnectedBusinessAccounts />
                        </div>
                    </RoundedWrapper>
            </div>
        </AppContainer>
    );
}