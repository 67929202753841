import { ApolloError, gql, useQuery } from "@apollo/client";
import { UserPreferences, UserPreferencesQuery, UserPreferencesQueryVariables } from "../generated/graphql";

const userPreferencesQuery = gql`
query UserPreferences($uid: String!) {
  userPreferences(uid: $uid) {
    timeFormat
    numberFormat
    firstWeekDay
    businessAccessKey
  }
}
`;

export type UserPreferencesState = {
    loading: boolean,
    error?: ApolloError,
    data?: UserPreferences,
}

export function useUserPreferences(uid: string) {
    const queryVariables: UserPreferencesQueryVariables = {
        uid: uid,
    }
    const { loading, error, data } = useQuery<UserPreferencesQuery>(userPreferencesQuery, { variables: queryVariables });
    return {
        loading: loading,
        error: error,
        data: data?.userPreferences,
    }
}