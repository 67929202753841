import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FirstWeekDay, NumberFormat, TimeFormat, UserPreferences } from '../generated/graphql';
import { LocalStorageManager } from '../models/LocalStorageManager';

interface UserPreferencesSliceData {
    preferences: UserPreferences,
}

export const defaultPreferences: UserPreferences = {
    timeFormat: TimeFormat.Format12h,
    numberFormat: NumberFormat.Us,
    firstWeekDay: FirstWeekDay.Sunday,
    __typename: 'UserPreferences',
}

export const userPreferencesSlice = createSlice({
    name: 'preferences',
    initialState: { 
        preferences: LocalStorageManager.getUserPreferences() ?? defaultPreferences,
    } as UserPreferencesSliceData,
    reducers: {
        setPreferences: (state, action: PayloadAction<UserPreferences | undefined>) => {
            state.preferences = action.payload ?? defaultPreferences;
            LocalStorageManager.setUserPreferences(action.payload ?? defaultPreferences);
        },
        updatePreferencesBusinessAccessKey: (state, action: PayloadAction<string>) => {
            if (state) {
                state.preferences.businessAccessKey = action.payload;
                LocalStorageManager.setUserPreferences(state.preferences);
            }
        },
    }
});

export const { setPreferences, updatePreferencesBusinessAccessKey } = userPreferencesSlice.actions;

export default userPreferencesSlice.reducer;