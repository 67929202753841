import { useState } from "react"
import { DashboardComponent, DashboardComponentInputConfigValue } from "../../../../generated/graphql";
import { useDebouncedEffect } from "../../../../hooks/UseDebouncedEffect"
import { BarOrLineChartConfigurationKey } from "../../../../models/chart_configuration_keys/BarOrLineChartConfigurationKey"

export interface EditBarOrLineChartConfigurationRowsProps {
    component: DashboardComponent,
    patchConfiguration: (configurations: DashboardComponentInputConfigValue[]) => void,
}

export const EditBarOrLineChartConfigurationRows: React.FC<EditBarOrLineChartConfigurationRowsProps> = (props: EditBarOrLineChartConfigurationRowsProps) => {
    const defaultYMin = props.component.configuration.find(x => x.key === BarOrLineChartConfigurationKey.Y_MIN)?.value ?? null;
    const defaultYMax = props.component.configuration.find(x => x.key === BarOrLineChartConfigurationKey.Y_MAX)?.value ?? null;
    const [yMin, setYMin] = useState(defaultYMin);
    const [yMax, setYMax] = useState(defaultYMax);

    useDebouncedEffect(() => {
        const hasChange = defaultYMin !== yMin || defaultYMax !== yMax;
        if (hasChange) {
            var configurationValues: DashboardComponentInputConfigValue[] = [];
            configurationValues.push({
                key: BarOrLineChartConfigurationKey.Y_MIN,
                value: yMin ?? '',
            });
            configurationValues.push({
                key: BarOrLineChartConfigurationKey.Y_MAX,
                value: yMax ?? '',
            });
            props.patchConfiguration(configurationValues);
        }
    }, [yMin, yMax, props], 500);

    return (
        <form className="row">
            <div className="form-group col-lg-4">
                <label className="col-form-label">Y axis minimum (optional):</label>
                <input type="number" className="form-control" value={yMin ?? ''} onChange={(event) => setYMin(event.target.value)} />
            </div>
            <div className="form-group col-lg-4">
                <label className="col-form-label">Y axis maximum (optional):</label>
                <input type="number" className="form-control" value={yMax ?? ''} onChange={(event) => setYMax(event.target.value)} />
            </div>
        </form>
    )
}