import JSChaCha20 from '../Chacha20';
import { Base64Binary } from '../Base64Binary';

export const HERemoteApiDecoder = {
    decodeChacha(key: string | Uint8Array, nonce: string, cipher: string): string {
        let keyUint: Uint8Array;
        if (typeof key === 'string' || key instanceof String) {
            keyUint = this.makeDecodeKey(key as string);
        } else {
            keyUint = key as Uint8Array;
        }
        const chacha20 = new JSChaCha20(keyUint, Base64Binary.decode(nonce), 0);
        const decodedData = chacha20.decrypt(Base64Binary.decode(cipher));
        const decodedMessage = new TextDecoder('utf-8').decode(decodedData);
        return decodedMessage;
    },

    makeDecodeKey(key: string): Uint8Array {
        return new TextEncoder().encode(key);
    }
};