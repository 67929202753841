import { Workout } from "../../../entities/api/Workout";
import { ActivityIndicator } from "../../core/activity_indicator/ActivityIndicator";
import { WorkoutsEmptyState } from "./WorkoutsEmptyState";
import { WorkoutList } from "./WorkoutsList";

export interface WorkoutsPageContentProps {
    isLoading: boolean,
    workouts: Workout[],
};

export const WorkoutsPageContent: React.FC<WorkoutsPageContentProps> = (props: WorkoutsPageContentProps) => {
    if (props.isLoading) {
        return (<ActivityIndicator />);
    } else if (props.workouts.length === 0) {
        return (<WorkoutsEmptyState />);
    } else {
        return (<WorkoutList workouts={props.workouts} />);
    }
};