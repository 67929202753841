import { DashboardWithComponentsFragment } from "../../../../generated/graphql"

export interface ActiveDashboardPickerProps {
    dashboards: DashboardWithComponentsFragment[],
    activeDashboard: DashboardWithComponentsFragment | null,
    setActiveDashboard: (dashboard: DashboardWithComponentsFragment) => void,
}

export const ActiveDashboardPicker: React.FC<ActiveDashboardPickerProps> = (props: ActiveDashboardPickerProps) => {
    return (
        <>
            { props.dashboards.length <= 1 &&
                <></>
            }
            { props.dashboards.length > 1 &&
                <div className="dropdown">
                    <button className="btn dropdown-toggle btn-outline-secondary dashboardsDropdown" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{ props.activeDashboard?.name ?? '-' }</button>
                    <div className="dropdown-menu">
                        {
                            props.dashboards.map(dashboard => (
                                <a 
                                        key={dashboard.dashboardUuid}
                                        className="dropdown-item"
                                        href="#"
                                        onClick={() => props.setActiveDashboard(dashboard)}
                                    >{dashboard.name}</a>
                            ))
                        }
                    </div>
                </div>
            }
        </>
    )
}