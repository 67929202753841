import "./Card.css"

export interface CardProps {
    children: React.ReactNode;
    useContainerFluid?: boolean;
    useDefaultHorizontalMargin?: boolean;
    useDefaultVerticalMargin?: boolean;
}

export const Card: React.FC<CardProps> = (props: CardProps) => {
    let cardCssClasses = ['card', 'heCard'];
    if (props.useDefaultHorizontalMargin) {
        cardCssClasses.push('heCardHorizontalMargin');
    }
    if (props.useDefaultVerticalMargin) {
        cardCssClasses.push('heCardTopMargin');
    }
    return (
        <div className={ props.useContainerFluid ? "container-fluid row" : ""}>
            <div className={ cardCssClasses.join(' ') }>
                { props.children }
            </div>
        </div>
    )
}

Card.defaultProps = {
    useContainerFluid: true,
    useDefaultHorizontalMargin: true,
    useDefaultVerticalMargin: true,
}