import { DashboardComponent } from "../../../../generated/graphql";
import { HealthDataCollection } from "../../../../models/datasources/HERemoteApiService"
import 'chartjs-adapter-moment'
import { BarChart } from "./charts/BarChart"
import { LineChart } from "./charts/LineChart"
import { DateIntervalData } from "../../../../hooks/UseDateInterval"
import { AggregationPeriod } from "../../../../models/AggregationPeriod"
import { DashboardUtils } from "../../../../models/DashboardUtils"
import { ChartType } from "../../../../models/ChartType"
import { HealthTypes } from "../../../../entities/api/HealthTypes"
import { StackedBarLineChart } from "./charts/StackedBarLineChart"
import { TotalValueChart } from "./charts/TotalValueChart"
import { HealthType } from "../../../../entities/api/HealthType"
import { safeAt } from "../../../../models/ArrayUtils"
import { TableChart } from "./charts/TableChart"
import { GaugeChart } from "./charts/GaugeChart"

export interface DashboardComponentContentProps {
    data: HealthDataCollection[],
    component: DashboardComponent,
    datatypes: HealthTypes,
    dateInterval: DateIntervalData,
    aggregationPeriod: AggregationPeriod | null,
}

export const DashboardComponentContent: React.FC<DashboardComponentContentProps> = (props: DashboardComponentContentProps) => {
    switch (DashboardUtils.chartTypeForComponent(props.component, props.datatypes, props.aggregationPeriod)) {
        case ChartType.BAR:
            return (
                <BarChart
                        data={props.data}
                        aggregationPeriod={props.aggregationPeriod ?? AggregationPeriod.days}
                        configuration={props.component.configuration}
                    />
                );
        case ChartType.LINE:
            return (
                <LineChart
                        data={props.data}
                        dateInterval={props.dateInterval}
                        configuration={props.component.configuration}
                    />
                );
        case ChartType.STACKED_BAR_LINE:
            return (
                <StackedBarLineChart
                        data={props.data}
                        aggregationPeriod={props.aggregationPeriod ?? AggregationPeriod.days}
                        dateInterval={props.dateInterval}
                        configuration={props.component.configuration}
                    />
                );
        case ChartType.TOTAL_VALUE:
            return (
                <TotalValueChart
                        dataType={safeAt(dataType(props.component, props.datatypes), 0)}
                        data={props.data}
                    />
            );
        case ChartType.TABLE:
            return (
                <TableChart
                        data={props.data}
                        aggregationPeriod={props.aggregationPeriod}
                    />
            );
        case ChartType.GAUGE:
            return (
                <GaugeChart
                        dataType={safeAt(dataType(props.component, props.datatypes), 0)}
                        data={props.data}
                        configuration={props.component.configuration}
                    />
            );
    }
}

function dataType(component: DashboardComponent, dataTypes: HealthTypes): HealthType[] | null {
    if (component.dataTypeIds.length === 0) {
        return null;
    } else {
        let workoutsType: HealthType[] = [];
        if (dataTypes.workouts) {
            workoutsType = [dataTypes.workouts]
        }
        const allTypes = [
            ...dataTypes.aggregated,
            ...dataTypes.recordBased,
        ]
        .flatMap(category => category.types)
        .concat(workoutsType);

        const types = component
            .dataTypeIds
            .map(typeId => allTypes.find(type => type.id === typeId) ?? null)
            .filter(type => type !== null) as HealthType[];

        return types.length === 0
            ? null
            : types;
    }
}