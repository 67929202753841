export const TextCompare = {
    compare(textA: string, textB: string): boolean {
        if ((textA === '' && textB !== '') || (textA !== '' && textB === '')) {
            return false;
        }
        const normA = normalizeText(textA);
        const normB = normalizeText(textB);
        return normA.includes(normB) || normB.includes(normA);
    }
}

function normalizeText(text: string): string {
    return text.toLocaleLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "");
}