import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useUserPreferences } from "../../hooks/UseUserPreferences";
import { setPreferences } from "../../reducers/UserPreferencesReducer";
import { RootState } from "../../Store";

export interface UserPreferencesProviderProps {
    children: JSX.Element,
}

export const UserPreferencesProvider: React.FC<UserPreferencesProviderProps> = (props: UserPreferencesProviderProps) => {
    const accountState = useSelector((state: RootState) => state.accountReducer);
    const userPreferences = useUserPreferences(accountState?.me.directAccess?.uid ?? '');
    const dispatch = useDispatch();
    useEffect(() => {
        if (userPreferences.data) {
            dispatch(setPreferences(userPreferences.data));
        }
    });
    return props.children;
}