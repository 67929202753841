import "./RoundedWrapper.css"

export interface RoundedWrapperProps {
    children: React.ReactNode
}

export const RoundedWrapper: React.FC<RoundedWrapperProps> = (props: RoundedWrapperProps) => {
    return (
        <div className="roundedRectWrapper">
            { props.children }
        </div>
    )
}