import { HealthType } from "../entities/api/HealthType";
import { HealthTypeCategory } from "../entities/api/HealthTypeCategory";
import { HealthTypes } from "../entities/api/HealthTypes";
import { TextCompare } from "./TextCompare";

export const DataTypesFilter = {
    filter(dataTypes: HealthTypes, searchQuery: string): HealthTypes {
        if (searchQuery === '') {
            return dataTypes;
        } else {
            return {
                aggregated: filterHealthTypeCategories(dataTypes.aggregated, searchQuery),
                recordBased: filterHealthTypeCategories(dataTypes.recordBased, searchQuery),
                workouts: filterWorkouts(dataTypes.workouts, searchQuery),
            }
        }
    }
}

function compare(textA: string, textB: string): boolean {
    return TextCompare.compare(textA, textB);
}

function filterWorkouts(workoutType: HealthType | null, searchQuery: string): HealthType | null {
    if (workoutType && compare(workoutType.name, searchQuery)) {
        return workoutType;
    } else {
        return null;
    }
}

function filterHealthTypeCategories(categories: HealthTypeCategory[], searchQuery: string): HealthTypeCategory[] {
    return categories
        .map(category => filterHealthTypeCategory(category, searchQuery))
        .filter(category => category !== null) as HealthTypeCategory[];
}

function filterHealthTypeCategory(category: HealthTypeCategory, searchQuery: string): HealthTypeCategory | null {
    if (compare(searchQuery, category.name)) {
        return category;
    } else {
        const filteredTypes = category.types.filter(type => compare(type.name, searchQuery));
        if (filteredTypes.length > 0) {
            return {
                name: category.name,
                types: filteredTypes,
            }
        } else {
            return null;
        }
    }
}