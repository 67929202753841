import { Workout } from "../../../entities/api/Workout";

export function workoutDates(workout: Workout): [Date | null, Date | null] {
    const workoutDatesRaw = workout.values.find(x => x.name === 'Workout date')?.value;
    if (!workoutDatesRaw) {
        return [null, null];
    }
    const dates = workoutDatesRaw
        .split(' - ')
        .map(d => {
            return new Date(d);
        });
    return [
        dates.length >= 1 ? dates[0] : null,
        dates.length >= 1 ? dates[1] : null
    ];
}