import { useMutation, useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { useState } from "react";
import { useHistory } from "react-router";
import { dashboardWithComponents } from "../../../entities/gql_fragments/DashboardWithComponents";
import { CreateDashboardFromPresetMutationVariables, DashboardsFullQuery, DashboardsFullQueryVariables, DashboardWithComponentsFragment, DashboardWithoutComponentsFragment } from "../../../generated/graphql";
import { useDataTypes } from "../../../hooks/UseDataTypes";
import { useDateInterval } from "../../../hooks/UseDateInterval";
import { useUserId } from "../../../hooks/UseUserId";
import { AggregationPeriod } from "../../../models/AggregationPeriod";
import { DashboardUtils } from "../../../models/DashboardUtils";
import { LocalStorageManager } from "../../../models/LocalStorageManager";
import { ActivityIndicator } from "../../core/activity_indicator/ActivityIndicator";
import { AppContainer } from "../../core/AppContainer";
import { LoadingFailedState } from "../../core/LoadingFailedState";
import { SideBarItem } from "../../core/SideBar";
import { DashboardContent } from "./content/DashboardContent";
import { DashboardHeader } from "./header/DashboardPageHeader";
import { NoComponentsMessage } from "./state_messages/NoComponentsMessage";
import { NoDashboardMessage } from "./state_messages/NoDashboardsMessage";
import { DashboardPreset, dashboardPresetName } from "../../../models/DashboardPreset";
import { createDashboardFromPresetMutation } from "../../../models/shared_gql/CreateDashboardFromPresetMutation";

const dashboardsQuery = gql`
    ${dashboardWithComponents}
    query DashboardsFull($uid: String!) {
        dashboards(uid: $uid) {
            ...DashboardWithComponents
        }
    }
`;

export const DashboardPage: React.FC = () => {
    const userId = useUserId();
    const dateInterval = useDateInterval();
    const history = useHistory();
    const [aggregationPeriod, setAggregationPeriod] = useState(AggregationPeriod.days);
    const [dashboards, setDashboards] = useState([] as DashboardWithComponentsFragment[]);
    const [activeDashboard, setActiveDashboard] = useState(null as DashboardWithComponentsFragment | null);
    const [dashboardFromPresetMutation] = useMutation<DashboardWithoutComponentsFragment>(createDashboardFromPresetMutation);
    const dataTypes = useDataTypes();

    function activateDashboard(dashboard: DashboardWithComponentsFragment) {
        setActiveDashboard(dashboard);
        const datePreset = DashboardUtils.dashboardDatePreset(dashboard);
        dateInterval.setInterval({
            from: datePreset.dateFrom,
            to: datePreset.dateTo,
        });
        if (datePreset.aggregationPeriod) {
            setAggregationPeriod(datePreset.aggregationPeriod);
        }
    }

    const { loading, error, data, refetch } = useQuery<DashboardsFullQuery>(dashboardsQuery, { 
        variables: { uid: userId } as DashboardsFullQueryVariables,
    });
    if (dashboards.length === 0 && (data?.dashboards.length ?? 0) > 0) {
        setDashboards(data!.dashboards);
    }
    if (!activeDashboard && dashboards.length > 0) {
        const previousActiveDashboardUUID = LocalStorageManager.getActiveDashboardUUID();
        if (previousActiveDashboardUUID) {
            const previousActiveDashboard = dashboards.find(dashboard => dashboard.dashboardUuid === previousActiveDashboardUUID);
            if (previousActiveDashboard) {
                activateDashboard(previousActiveDashboard);
            } else {
                activateDashboard(dashboards[0]);    
            }
        } else {
            activateDashboard(dashboards[0]);
        }
    }

    function setActiveDashboardAction(dashboard: DashboardWithComponentsFragment) {
        activateDashboard(dashboard);
        LocalStorageManager.setActiveDashboardUUID(dashboard.dashboardUuid);
    }

    function settingsButtonClicked() {
        history.push('/dashboard/settings');
    }

    function onDashboardPresetClick(preset: DashboardPreset) {
        const mutationVariables: CreateDashboardFromPresetMutationVariables = {
            uid: userId,
            name: dashboardPresetName(preset),
            preset: preset,
        }
        dashboardFromPresetMutation({ variables: mutationVariables })
            .then(() => refetch())
            .catch(() => alert('Failed to create the dashboard. Please check your internet connection and try it again later.'));
    }

    return (
        <AppContainer
            title="Dashboard"
            activeSideBarItem={SideBarItem.dashboard}
        >
            <div className="container-fluid row">
                <DashboardHeader 
                    dateInterval={dateInterval}
                    aggregationPeriod={aggregationPeriod}
                    dashboards={dashboards}
                    activeDashboard={activeDashboard}
                    setActiveDashboard={setActiveDashboardAction}
                    setAggregationPeriod={setAggregationPeriod}
                    settingsButtonClicked={settingsButtonClicked}
                />
            </div>
            { loading &&
                <ActivityIndicator />
            }
            { error &&
                <LoadingFailedState />
            }
            { !error && !loading && dashboards.length === 0 &&
                <NoDashboardMessage
                    onDashboardPresetClick={onDashboardPresetClick}
                />
            }
            { activeDashboard && activeDashboard.components.length === 0 &&
                <NoComponentsMessage 
                        dashboard={activeDashboard!}
                    />
            }
            { activeDashboard && activeDashboard.components.length > 0 &&
                <DashboardContent
                        dashboard={activeDashboard}
                        aggregationPeriod={aggregationPeriod}
                        dateInterval={dateInterval}
                        dataTypes={dataTypes}
                    />
            }
        </AppContainer>
    );
}