import React from "react";

export interface RadioSelectionListItem<T extends string> {
    title: string,
    id: T,
    isSelected: boolean,
}

export interface RadioSelectionListProps<T extends string> {
    selectionId: string,
    title: string,
    items: RadioSelectionListItem<T>[],
    setSelectedItem: (selectionId: T) => void,
}

export class RadioSelectionList<T extends string> extends React.Component<RadioSelectionListProps<T>> {
    render() {
        return (
            <>
                <p className="settingsSectionTitle">{this.props.title}:</p>
                <div className="checkbox-radios">
                    {
                        this.props.items.map(item => (
                            <div key={item.id} className="form-check">
                                <label className="form-check-label">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name={this.props.selectionId}
                                        value={item.id}
                                        checked={item.isSelected}
                                        onChange={ () => this.props.setSelectedItem(item.id) }
                                        /> {item.title}
                                    <span className="circle"><span className="check"></span></span>
                                </label>
                            </div>
                        ))
                    }
                </div>
            </>
        )
    }
}