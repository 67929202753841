import { Workout } from "../../entities/api/Workout";
import { WorkoutMetadata } from "../../entities/api/WorkoutMetadata";
import { HealthDataCollection } from "./HERemoteApiService";
import { TextCompare } from "../TextCompare";

export const WorkoutActivityAdapter = {
    adaptWorkouts(filter: string, collections: HealthDataCollection[]): HealthDataCollection[] {
        if (filter.replaceAll(' ','').length === 0) {
            return collections;
        }

        const queriedActivities = filter
            .split(',')
            .map(query => query.trim());

        let newCollections: HealthDataCollection[] = [];
        
        collections.forEach(collection => {
            let validTimes = new Set<String>();
            const workouts: Workout[] = collection.records.map((dto) => ({
                time: new Date(dto.time as string),
                values: JSON.parse(dto.value as string) as WorkoutMetadata[],
            }));

            for (const workout of workouts) {
                const activityValues = workout.values.filter(value => value.name === 'Activity');
                if (activityValues.length !== 1) {
                    continue
                }
                const activityName = activityValues[0].value;
                for (const queriedActivity of queriedActivities) {
                    if (TextCompare.compare(queriedActivity, activityName)) {
                        validTimes.add(workout.time.toISOString());
                        break;
                    }
                }
            }

            const newCollection: HealthDataCollection = {
                units: collection.units,
                records: collection.records.filter(record => validTimes.has(record.time))
            };
            newCollections.push(newCollection);
        });

        return newCollections;
    }
}