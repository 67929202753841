import { DependencyList, useEffect } from "react";

// Credit: https://stackoverflow.com/a/61127960/4890463

export const useDebouncedEffect = (effect: () => void, deps: DependencyList, delay: number) => {
    useEffect(() => {
        const handler = setTimeout(() => effect(), delay);
        return () => clearTimeout(handler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...deps || [], delay]);
}