import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux"
import { Account, setActiveAccount } from "../../../reducers/AccountReducer"
import { RootState } from "../../../Store"

export const ChangeAccountModal: React.FC = () => {
    const me = useSelector((state: RootState) => state.accountReducer?.me);
    const connectedAccounts = useSelector((state: RootState) => state.accountReducer?.connectedAccounts);
    const activeAccount = useSelector((state: RootState) => state.accountReducer?.activeAccount);
    const dispatch = useDispatch();
    const refHiddenDismissButton = useRef(null as HTMLButtonElement | null);

    let accounts: Account[] = [
        me,
        ...connectedAccounts ?? [],
    ].filter(x => x !== undefined) as Account[];

    function setNewActiveAccout(uid: string) {
        const newActiveAccount = accounts.find(acc => 
            (acc.directAccess?.uid !== undefined && acc.directAccess.uid === uid) ||
            (acc.businessAccess?.uuid !== undefined && acc.businessAccess?.uuid === uid)
        );
        if (newActiveAccount) {
            dispatch(setActiveAccount(newActiveAccount));
        }
        refHiddenDismissButton.current?.click();
    }
    return (
        <div className="modal fade" id="changeUserModal" role="dialog" aria-labelledby="changeUserModalLabel" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h3 className="modal-title" id="changeUserModalLabel">Change account</h3>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="checkbox-radios" id="changeAccountForm">
                            {
                                accounts.map((connectedAccount, index) => (
                                    <div className="form-check" key={`${connectedAccount.directAccess?.uid ?? connectedAccount.businessAccess?.uuid}${index}`}>
                                        <label className="form-check-label">
                                            <input 
                                                className="form-check-input accountIdInput"
                                                type="radio"
                                                name="accountId"
                                                checked={
                                                    (activeAccount?.directAccess?.uid !== undefined && activeAccount?.directAccess?.uid === connectedAccount.directAccess?.uid) || 
                                                    (activeAccount?.businessAccess?.uuid !== undefined && activeAccount?.businessAccess?.uuid === connectedAccount.businessAccess?.uuid)
                                                }
                                                onChange={() => setNewActiveAccout(connectedAccount.directAccess?.uid ?? connectedAccount.businessAccess?.uuid ?? '')}
                                                /> {connectedAccount.name}
                                            <span className="circle">
                                                <span className="check"></span>
                                                </span>
                                        </label>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button
                            ref={refHiddenDismissButton}
                            data-dismiss="modal"
                            style={{ display: 'none' }}
                            ></button>
                    </div>
                </div>
            </div>
        </div>
    )
}