import { useState } from "react";
import { fuzzySearch, SelectSearchOption } from "react-select-search";
import { DashboardComponent } from "../../../../generated/graphql";
import { DashboardComponentInputConfigValue } from "../../../../generated/graphql";
import { useDebouncedEffect } from "../../../../hooks/UseDebouncedEffect";
import { WorkoutChartConfigurationKey } from "../../../../models/chart_configuration_keys/WorkoutChartConfigurationKey";
import { allWorkoutChartValues, nameForWorkoutChartValue } from "../../../../models/WorkoutChartValue";
import { HESelectSearch } from "../../../core/HESelectSearch/HESelectSearch"
import "./WorkoutConfiguration.css"

export interface WorkoutConfigurationProps {
    component: DashboardComponent,
    patchConfiguration: (configurations: DashboardComponentInputConfigValue[]) => void,
}

export const WorkoutConfiguration: React.FC<WorkoutConfigurationProps> = (props: WorkoutConfigurationProps) => {
    const [workoutActivity, setWorkoutActivity] = useState(makeCurrentComponentWorkoutActivity(props.component))
    const componentWorkoutValueSelectOptions = makeWorkoutValueOptions();

    function componentWorkoutValueChangedAction(option: SelectSearchOption | SelectSearchOption[]) {
        // @ts-expect-error
        const selectedWorkoutValuedId = option as string;
        if (selectedWorkoutValuedId !== makeCurrentComponentWorkoutValueId(props.component)) {
            props.patchConfiguration([
                {
                    key: WorkoutChartConfigurationKey.WORKOUT_VALUE,
                    value: selectedWorkoutValuedId,
                }
            ])
        }
    }

    useDebouncedEffect(() => {
        const originalValue = makeCurrentComponentWorkoutActivity(props.component);
        const hasChange = originalValue !== workoutActivity;
        if (hasChange) {
            var configurationValues: DashboardComponentInputConfigValue[] = [];
            configurationValues.push({
                key: WorkoutChartConfigurationKey.WORKOUT_ACTIVITY,
                value: workoutActivity ?? '',
            });
            props.patchConfiguration(configurationValues);
        }
    }, [workoutActivity, props], 500);

    return (
        <>
            <div className="workoutsMetadataSelectWrapper">
                <p>Workout value:</p>
                <HESelectSearch
                        options={componentWorkoutValueSelectOptions}
                        search
                        filterOptions={fuzzySearch}
                        emptyMessage="Not found"
                        placeholder="Select workout value"
                        onChange={componentWorkoutValueChangedAction}
                        value={makeCurrentComponentWorkoutValueId(props.component)}
                    />
            </div>
            <div className="workoutsMetadataSelectWrapper">
                <p>Workout activity:</p>
                <input type="string" placeholder="Running, Walking" className="form-control workoutActivityName" value={workoutActivity} onChange={(event) => setWorkoutActivity(event.target.value)} />
            </div>
        </>
    )
}

function makeWorkoutValueOptions(): SelectSearchOption[] {
    return allWorkoutChartValues()
        .map(workoutValue => ({
            name: nameForWorkoutChartValue(workoutValue),
            value: workoutValue,
        }))
}

function makeCurrentComponentWorkoutValueId(component: DashboardComponent): string | undefined {
    return component.configuration.find(x => x.key === WorkoutChartConfigurationKey.WORKOUT_VALUE)?.value;
}

function makeCurrentComponentWorkoutActivity(component: DashboardComponent): string {
    return component.configuration.find(x => x.key === WorkoutChartConfigurationKey.WORKOUT_ACTIVITY)?.value ?? '';
}