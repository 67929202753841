import { DashboardComponentConfigurationValue } from "../../generated/graphql";
import { StackedBarLineChartConfigurationKey } from "../../models/chart_configuration_keys/StackedBarLineChartConfigurationKey";

export type StackedBarLineChartConfiguration = {
    yMainMin?: number,
    yMainMax?: number,
    ySecondaryMin?: number,
    ySecondaryMax?: number,
}

export function useStackedBarLineChartConfiguration(configuration: DashboardComponentConfigurationValue[]): StackedBarLineChartConfiguration {
    return {
        yMainMin:       makeConfigurationNumberValue(StackedBarLineChartConfigurationKey.Y_MAIN_MIN, configuration),
        yMainMax:       makeConfigurationNumberValue(StackedBarLineChartConfigurationKey.Y_MAIN_MAX, configuration),
        ySecondaryMin:  makeConfigurationNumberValue(StackedBarLineChartConfigurationKey.Y_SECONDARY_MIN, configuration),
        ySecondaryMax:  makeConfigurationNumberValue(StackedBarLineChartConfigurationKey.Y_SECONDARY_MAX, configuration),
    }
}

function makeConfigurationNumberValue(key: StackedBarLineChartConfigurationKey, configuration: DashboardComponentConfigurationValue[]): number | undefined {
    const rawValue = configuration.find(x => x.key === key)?.value;
    if (!rawValue) {
        return undefined;
    } else {
        return parseFloat(rawValue);
    }
}