import { ConnectedAccountCrypto } from "../ConnectedAccountCrypto";
import { v4 as uuidv4 } from 'uuid';
import { AddConnectedAccountMutationVariables } from "../../generated/graphql";

export function addConnectedAccountVariablesFactory(
    uid: string,
    encryptionKey: string,
    name: string,
    key: string
): AddConnectedAccountMutationVariables {
    const encryptedAccountKey = ConnectedAccountCrypto.encrypt(key, encryptionKey);
    const variables: AddConnectedAccountMutationVariables = {
        uid: uid,
        connectedAccount: {
            connectedAccountUuid: uuidv4(),
            name: name,
            accountKey: encryptedAccountKey,
        } 
    }
    return variables;
}