
export type WorkoutMetadataIcon = {
    url: string,
    invertInDarkMode: boolean,
}

export function iconForWorkoutMetadata(metadataName: string): { iconUrl: string, invertedInDarkMode: boolean } {
    const icons = [
        ['energy','calories',false],
        ['Distance','distance',false],
        ['Heart rate','heartrate',false],
        ['Weather: Temperature','temperature',false],
        ['Weather: Humidity','humidity',false],
        ['Swimming','swim',false],
        ['Elevation: Maximum','elevation_max',true],
        ['Elevation: Minimum','elevation_min',true],
        ['Elevation: Ascended','elevation_ascended',true],
        ['Elevation: Descended','elevation_descended',true],
        ['METs Average','met',true],
        ['Pace','running_pace',false],
        ['Speed: Average','speed',false],
        ['Speed','speed',false],
        ['Duration','duration',false],
        ['Alpine Slope Grade','mountain',true]
    ]
    let iconName = 'unknown';
    let invertInDarkMode = true;
    for (let i = 0; i < icons.length; ++i) {
        if (metadataName.indexOf(icons[i][0] as string) > -1) {
            iconName = icons[i][1] as string;
            invertInDarkMode = icons[i][2] as boolean;
            break;
        }
    }
    return {
        iconUrl: `images/workouts/icon_workouts_${iconName}.svg`,
        invertedInDarkMode: invertInDarkMode,
    }
}