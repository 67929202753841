import { DashboardWithComponentsFragment } from "../../../../generated/graphql";
import { DateIntervalData } from "../../../../hooks/UseDateInterval";
import { AggregationPeriod } from "../../../../models/AggregationPeriod";
import { DatePreset, presetsWithAggregation } from "../../../../models/DatePreset";
import { DatePresetPicker } from "../../../core/DatePresetPicker";
import { ActiveDashboardPicker } from "./ActiveDashboardPicker";
import { DashboarAggregationPeriodPicker } from "./DashboarAggregationPeriodPicker";
import { DashboardDatePicker } from "./DashboardDatePicker";
import "./DashboardPageHeader.css";

export interface DashboardHeaderProps {
    dateInterval: DateIntervalData,
    aggregationPeriod: AggregationPeriod,
    dashboards: DashboardWithComponentsFragment[],
    activeDashboard: DashboardWithComponentsFragment | null,
    setActiveDashboard: (dashboard: DashboardWithComponentsFragment) => void,
    setAggregationPeriod: (aggregationPeriod: AggregationPeriod) => void,
    settingsButtonClicked: () => void,
}

export const DashboardHeader: React.FC<DashboardHeaderProps> = (props: DashboardHeaderProps) => {

    function onPresetSelected(datePreset: DatePreset) {
        props.dateInterval.setInterval({
            from: datePreset.dateFrom,
            to: datePreset.dateTo,
        });
        props.setAggregationPeriod(datePreset.aggregationPeriod!);
    }

    return (
        <div className="container-fluid row dashboardHeader">
            <ActiveDashboardPicker
                    dashboards={props.dashboards}
                    activeDashboard={props.activeDashboard}
                    setActiveDashboard={props.setActiveDashboard}
                />
            <p>Dates: </p>
            <DashboardDatePicker 
                    dateInterval={props.dateInterval}
                />
            <DatePresetPicker
                    presets={presetsWithAggregation}
                    onPresetSelected={onPresetSelected}
                />
            <DashboarAggregationPeriodPicker
                    aggregationPeriod={props.aggregationPeriod}
                    setAggregationPeriod={props.setAggregationPeriod}
                />
            <div style={{ marginLeft: 'auto' }}></div>
            <button
                type="button"
                className="btn btn-fab"
                onClick={props.settingsButtonClicked}
                ><i className="material-icons">settings</i></button>
        </div>
    )
}