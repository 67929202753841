import { HealthTypes } from "../../../../entities/api/HealthTypes"
import { DashboardWithComponentsFragment } from "../../../../generated/graphql";
import { DateIntervalData } from "../../../../hooks/UseDateInterval"
import { AggregationPeriod } from "../../../../models/AggregationPeriod"
import { DashboardComponentView } from "./DashboardComponentView"

export interface DashboardContentProps {
    dashboard: DashboardWithComponentsFragment,
    dataTypes: HealthTypes,
    dateInterval: DateIntervalData,
    aggregationPeriod: AggregationPeriod,
}

export const DashboardContent: React.FC<DashboardContentProps> = (props: DashboardContentProps) => {
    return (
        <div className="row">
            { props.dashboard.components.map(component => (
                <DashboardComponentView
                        key={component.componentUuid}
                        component={component}
                        dataTypes={props.dataTypes}
                        aggregationPeriod={props.aggregationPeriod}
                        dateInterval={props.dateInterval}
                    />
                ))
            }
        </div>
    )
}