import { useHistory } from "react-router"
import { DashboardWithComponentsFragment } from "../../../../generated/graphql";

export interface NoComponentsMessageProps {
    dashboard: DashboardWithComponentsFragment,
}

export const NoComponentsMessage: React.FC<NoComponentsMessageProps> = (props: NoComponentsMessageProps) => {
    const history = useHistory();

    function setupComponentsButtonClicked() {
        history.push(`/dashboard/settings/${props.dashboard.dashboardUuid}`);
    }

    return (
        <div id="dashboardMessageStateWrapper">
            <img src="images/dashboard_config_needed.svg" alt="No data available" />
            <h3><b>Configuration needed</b></h3>
            <p>The { props.dashboard.name } dashboard doesn't have any components. To add component, please go to the <a href="#" onClick={setupComponentsButtonClicked}>dashboard settings</a>.</p>
        </div>
    )
}