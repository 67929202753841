import moment from "moment";
import { makeEmptySleepDay, SleepDay } from "../../entities/sleep/SleepDay";
import { SleepInterval } from "../../entities/sleep/SleepInterval";
import { SleepStage, sleepStageDataTypeId } from "../../entities/sleep/SleepStage";
import { Account } from "../../reducers/AccountReducer";
import { HERemoteApiService } from "./HERemoteApiService";

export const SleepRepository = {
    async getData(account: Account, from: Date, to: Date): Promise<SleepDay[]> {
        const bufferedFrom = moment(from).add(-1,'day').toDate();
        const inBedResponse = await HERemoteApiService.getData(account, sleepStageDataTypeId(SleepStage.inBed), bufferedFrom, to);
        const asleepResponse = await HERemoteApiService.getData(account, sleepStageDataTypeId(SleepStage.asleep), bufferedFrom, to);
        const awakeResponse = await HERemoteApiService.getData(account, sleepStageDataTypeId(SleepStage.awake), bufferedFrom, to);
        const coreResponse = await HERemoteApiService.getData(account, sleepStageDataTypeId(SleepStage.core), bufferedFrom, to);
        const deepResponse = await HERemoteApiService.getData(account, sleepStageDataTypeId(SleepStage.deep), bufferedFrom, to);
        const remResponse = await HERemoteApiService.getData(account, sleepStageDataTypeId(SleepStage.rem), bufferedFrom, to);
        const inBedRecords = inBedResponse.flatMap(x => x.records);
        const asleepRecords = asleepResponse.flatMap(x => x.records);
        const awakeRecords = awakeResponse.flatMap(x => x.records);
        const coreRecords = coreResponse.flatMap(x => x.records);
        const deepRecords = deepResponse.flatMap(x => x.records);
        const remRecords = remResponse.flatMap(x => x.records);
        
        let days = new Map<string,SleepDay>();
        let dayToAdd = moment(from);
        let toMoment = moment(to);
        while (true) {
            const dayToAddDay = dayToAdd.toDate();
            days.set(dayToAddDay.toISOString(), makeEmptySleepDay(dayToAddDay));
            dayToAdd = dayToAdd.add(1, 'd');
            if (dayToAdd >= toMoment) {
                break;
            }
        }

        [
            { stage: SleepStage.inBed,  records: inBedRecords},
            { stage: SleepStage.asleep, records: asleepRecords},
            { stage: SleepStage.awake, records: awakeRecords},
            { stage: SleepStage.core, records: coreRecords},
            { stage: SleepStage.deep, records: deepRecords},
            { stage: SleepStage.rem, records: remRecords},
        ].forEach(stageRecords => {
            stageRecords.records.forEach(record => {
                const momentDate = moment(record.time)
                    .add(12, 'hour')
                    .startOf('day');
                let hours: number;
                if (typeof record.value === 'string') {
                    hours = parseFloat(record.value);
                } else {
                    hours = record.value;
                }
                let interval: SleepInterval = {
                    from: moment(record.time).toDate(),
                    to: moment(record.time).add(hours, 'h').toDate(),
                    stage: stageRecords.stage,
                }
                const isoString = momentDate.toISOString();
                if (days.has(isoString)) {
                    let exisingIntervals = days.get(isoString)!!.intervals;
                    let foundConflictingInterval = false;
                    for (const existingInterval of exisingIntervals) {
                        if (existingInterval.stage !== interval.stage) {
                            continue;
                        }
                        if (existingInterval.from < interval.from && existingInterval.to > interval.to) {
                            foundConflictingInterval = true;
                            break;
                        } else if (existingInterval.from > interval.from && existingInterval.to < interval.to) {
                            existingInterval.from = interval.from;
                            existingInterval.to = interval.to;
                            foundConflictingInterval = true
                            break;
                        } else if (existingInterval.from < interval.from && existingInterval.to > interval.from) {
                            existingInterval.to = interval.to;
                            foundConflictingInterval = true
                            break;
                        } else if (existingInterval.from < interval.to && existingInterval.from > interval.from) {
                            existingInterval.from = interval.from;
                            foundConflictingInterval = true
                            break;
                        }
                    }
                    if (!foundConflictingInterval) {
                        exisingIntervals.push(interval);
                    }
                }
            });
        });

        days.forEach(day => {
            day.intervals.forEach(interval => {
                const intervalHours = (interval.to.getTime() - interval.from.getTime()) / 3600000
                switch(interval.stage) {
                    case SleepStage.inBed:
                        day.hoursInBed += intervalHours;
                        break;
                    case SleepStage.asleep:
                        day.hoursAsleep += intervalHours;
                        break;
                    case SleepStage.awake:
                        day.hoursAwake += intervalHours;
                        break;
                    case SleepStage.core:
                        day.hoursCore += intervalHours;
                        break;
                    case SleepStage.deep:
                        day.hoursDeep += intervalHours;
                        break;
                    case SleepStage.rem:
                        day.hoursREM += intervalHours;
                        break;
                }
            })
        });

        return Array.from(days.values()).sort((a, b) => (a.date > b.date) ? 1 : -1);
    }
}