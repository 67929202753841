import { AppContainer } from "../../core/AppContainer"
import { SideBarItem } from "../../core/SideBar"
import { HESwal } from "../../core/HESwal";
import { gql, useMutation, useQuery } from "@apollo/client";
import { LocalStorageManager } from "../../../models/LocalStorageManager";
import { ActivityIndicator } from "../../core/activity_indicator/ActivityIndicator";
import { LoadingFailedState } from "../../core/LoadingFailedState";
import { AddConnectedAccountModal } from "./AddConnectedAccountModal";
import { ConnectedAccountCrypto } from "../../../models/ConnectedAccountCrypto";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../Store";
import { removeConnectedAccountsWithUid, setActiveAccount, addConnectedAccount } from "../../../reducers/AccountReducer";
import { makeUserInfo } from "../../../models/UserUtils";
import { addConnectedAccountMutation } from "../../../models/shared_gql/AddConnectedAccountMutation";
import { addConnectedAccountVariablesFactory } from "../../../models/factories/AddConnectedAccountVariablesFactory";
import { AddConnectedAccountMutation, ConnectedAccountsQuery, ConnectedAccountsQueryVariables, DeleteConnectedAccountMutation, DeleteConnectedAccountMutationVariables } from "../../../generated/graphql";

const connectedAccountsQuery = gql`
query ConnectedAccounts($uid: String!) {
    connectedAccounts(uid: $uid) {
        connectedAccountUuid
        name
        accountKey
  }
}
`;

const deleteConnectedAccountMutation = gql`
mutation DeleteConnectedAccount($uid: String!, $connectedAccountUuid: String!) {
    deleteConnectedAccount(uid: $uid, connectedAccountUuid: $connectedAccountUuid) {
        connectedAccountUuid
        name
        accountKey
    }
}
`;

export const DataSharingSettingsPage: React.FC = () => {
    const dispatch = useDispatch();
    const activeAccountUid = useSelector((state: RootState) => 
        state.accountReducer?.activeAccount.directAccess?.uid ?? 
        state.accountReducer?.activeAccount.businessAccess?.uuid ??
        '-'
    );
    const me = useSelector((state: RootState) => state.accountReducer?.me);
    const uid = LocalStorageManager.getUser()?.accountKeySha512 ?? "";
    const queryVariables: ConnectedAccountsQueryVariables = {
        uid: uid,
    }
    const { loading, error, data, refetch } = useQuery<ConnectedAccountsQuery>(connectedAccountsQuery, { 
        variables: queryVariables,
     });
    
    const [addConnectedAccountGQL] = useMutation<AddConnectedAccountMutation>(addConnectedAccountMutation);
    const [deleteConnectedAccountGQL] = useMutation<DeleteConnectedAccountMutation>(deleteConnectedAccountMutation);
    
    function connectNewAccount(name: string, key: string) {
        const variables = addConnectedAccountVariablesFactory(
            uid,
            LocalStorageManager.getUser()?.encryptionKey ?? '',
            name,
            key,
        )
        addConnectedAccountGQL({ variables: variables })
            .then(_ => {
                refetch();
                dispatch(addConnectedAccount({
                    name: name,
                    directAccess: {
                        uid: makeUserInfo(key).accountKeySha512,
                        encryptionKey: key,
                    }
                }));
                HESwal.fire({
                    title: 'Success !',
                    html: '<p>Account connected.</p><p>To change the active account, click on the "Change account" menu button on the Dashboard, CSV, and Workouts pages.</p><img style="width: 100%;object-fit: scale-down;" src="/images/change_account_example.jpg">',
                    icon: 'success',
                    confirmButtonText: 'OK',
                    customClass: {
                        confirmButton: 'btn btn-success'
                    }
                });
            })
            .catch(_ => alert('Something went wrong. Please try it later.'));
    }

    function deleteConnectedAccountAction(connectedAccountUuid: string) {
        const accountToDelete = (data?.connectedAccounts ?? []).find(x => x.connectedAccountUuid === connectedAccountUuid);
        HESwal.fire({
            title: 'Are you sure?',
            text: `Are you sure you want to delete ${accountToDelete?.name ?? ''}?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it',
            customClass: {
                confirmButton: 'btn btn-danger'
            }
        }).then((result) => {
            if (result.isConfirmed) {
                const variables: DeleteConnectedAccountMutationVariables = {
                    uid: uid,
                    connectedAccountUuid: connectedAccountUuid,
                }
                deleteConnectedAccountGQL({ variables: variables })
                    .then(result => {
                        refetch();
                        const deletedAccountKey = result.data?.deleteConnectedAccount?.accountKey;
                        if (deletedAccountKey && me) {
                            const decryptedAccountKey = ConnectedAccountCrypto.dencrypt(deletedAccountKey, me.directAccess?.encryptionKey ?? '');
                            const deletedAccountUid = makeUserInfo(decryptedAccountKey).accountKeySha512;
                            dispatch(removeConnectedAccountsWithUid(deletedAccountUid));
                            if (activeAccountUid === deletedAccountUid) {
                                dispatch(setActiveAccount(me));
                            }
                        }
                    })
                    .catch(_ => alert('Something went wrong. Please try it later.'));
            }
        })
    }
    
    const connectedAccountsPage = (
        <>
            <h4 style={{marginTop: "20px", fontWeight: "bold"}}>Share my health data</h4>
            <p>You can share access to your health data with another person (for example a doctor). To share your data, send the person your account key.</p>
            <div className="alert alert-warning" role="alert" style={{ marginTop: "10px", marginBottom: "20px" }}>
            Be extremely careful with your account key! The key cannot be revoked in the future. Once you send the key to someone, the person can read all of your health data.
            </div>
            <button
                type="button"
                className="btn btn-success shareMyAccountKeyButton"
                onClick={() => showAccountKey(LocalStorageManager.getUser()?.accountKey ?? '')}
                >Show me my account key</button>

            <hr />
            <h4 style={{ fontWeight: "bold" }}>Connected account keys</h4>
            <p>If you have an account key of another person, click the Add account key button to access their health data.</p>
            <table className="table" style={{ borderTopWidth: "0"}}>
                <tbody>
                    {
                        (data?.connectedAccounts ?? []).map(connectedAccount => (
                            <tr key={connectedAccount.connectedAccountUuid}>
                                <td>
                                    <span>{connectedAccount.name}</span>
                                </td>
                                <td className="deleteConnectedAccountTd">
                                    <button 
                                        className="btn btn-round btn-fab"
                                        onClick={() => deleteConnectedAccountAction(connectedAccount.connectedAccountUuid)}
                                    >
                                        <i className="material-icons">delete</i>
                                        <div className="ripple-container"></div>
                                    </button>
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
            <button type="button" className="btn btn-success" data-toggle="modal" data-target="#addAccountModal">Add account key</button>
        </>
    )

    let content: JSX.Element
    if (loading) {
        content = (<ActivityIndicator />);
    } else if (error) {
        content = (<LoadingFailedState />);
    } else {
        content = connectedAccountsPage;
    }

    return (
        <>
            <AppContainer
                title="Settings"
                activeSideBarItem={SideBarItem.settings}
            >
                <div className="content">
                    <div className="settingsContent">
                        { content }
                    </div>
                </div>
            </AppContainer>
            <AddConnectedAccountModal connectAccountHandler={connectNewAccount} />
        </>
    )
}

function showAccountKey(accountKey: string) {
    HESwal.fire({
        title: 'Account key',
        html: `<p>The key cannot be revoked, so be extremely careful when sharing it and never share it with an untrusted person.</p><p style="margin-bottom: 4px">Your account key:</p><p class="text-warning" style="font-size: 1rem;">${accountKey}</p>`,
        icon: 'warning',
        confirmButtonText: 'OK'
    });
}