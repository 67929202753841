import { DateInterval, useDateInterval } from "../../../hooks/UseDateInterval";
import { useSleepData, UseSleepDataState, UseSleepDataStatus } from "../../../hooks/UseSleepData";
import { ActivityIndicator } from "../../core/activity_indicator/ActivityIndicator";
import { AppContainer } from "../../core/AppContainer";
import { LoadingFailedState } from "../../core/LoadingFailedState";
import { SideBarItem } from "../../core/SideBar";
import { SleepHeader } from "./SleepHeader";
import { SleepPageContent } from "./SleepPageContent";

export const SleepPage: React.FC = () => {

    const dateInterval = useDateInterval();
    const sleepData = useSleepData(dateInterval.interval);

    return (
        <AppContainer
            title="Sleep"
            activeSideBarItem={SideBarItem.sleep}
        >
            <SleepHeader
                dateInterval={dateInterval}
                />

            { makeContent(sleepData, dateInterval.interval) }

        </AppContainer>
    );
}

function makeContent(data: UseSleepDataState, dateInterval: DateInterval) {
    switch (data.status) {
        case UseSleepDataStatus.loading:
            return (<ActivityIndicator />);
        case UseSleepDataStatus.error:
            return (<LoadingFailedState />);
        case UseSleepDataStatus.data:
            return (
                <SleepPageContent
                    sleepDays={data.data ?? []}
                    dateInterval={dateInterval}
                    />
            );
    }
}