import { useMutation, useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { useState } from "react";
import { useHistory } from "react-router";
import { dashboardWithoutComponents } from "../../../entities/gql_fragments/DashboardWithoutComponents";
import { CreateDashboardFromPresetMutationVariables, CreateDashboardMutationVariables, DashboardsQuery, DashboardsQueryVariables, DashboardWithoutComponentsFragment, DeleteDashboardMutationVariables, RenameDashboardMutationVariables } from "../../../generated/graphql";
import { useUserId } from "../../../hooks/UseUserId";
import { createDashboardMutation } from "../../../models/shared_gql/CreateDashboardMutation";
import { ActivityIndicator } from "../../core/activity_indicator/ActivityIndicator";
import { AppContainer } from "../../core/AppContainer"
import { HESwal } from "../../core/HESwal";
import { LoadingFailedState } from "../../core/LoadingFailedState";
import { SideBarItem } from "../../core/SideBar"
import { DashboardSettingsItem } from "./dashboard_settings_item/DashboardSettingsItem"
import { DashboardPreset, allDashboardPresets, dashboardPresetName } from "../../../models/DashboardPreset";
import { createDashboardFromPresetMutation } from "../../../models/shared_gql/CreateDashboardFromPresetMutation";

const dashboardsQuery = gql`
    ${dashboardWithoutComponents}
    query Dashboards($uid: String!) {
        dashboards(uid: $uid) {
            ...DashboardWithoutComponents
        }
    }
`;

const renameDashboardMutation = gql`
    ${dashboardWithoutComponents}
    mutation RenameDashboard($uid: String!, $name: String!, $dashboardUuid: String!) {
        renameDashboard(uid: $uid, name: $name, dashboardUuid: $dashboardUuid) {
            ...DashboardWithoutComponents
        }
    }
`;

const deleteDashboardMutation = gql`
    ${dashboardWithoutComponents}
    mutation DeleteDashboard($uid: String!, $dashboardUuid: String!) {
        deleteDashboard(uid: $uid, dashboardUuid: $dashboardUuid) {
            ...DashboardWithoutComponents
        }
    }
`;

export const DashboardSettingsPage: React.FC = () => {
    
    // Variables

    const [isErrorState, setIsErrorState] = useState(false);
    const userId = useUserId();
    const { error, data, refetch } = useQuery<DashboardsQuery>(dashboardsQuery, { 
        variables: { uid: userId } as DashboardsQueryVariables,
    });
    const history = useHistory();
    const [setCreateNewDashboard] = useMutation<DashboardWithoutComponentsFragment>(createDashboardMutation);
    const [setRenameDashboard] = useMutation<DashboardWithoutComponentsFragment>(renameDashboardMutation);
    const [setDeleteDashboard] = useMutation<DashboardWithoutComponentsFragment>(deleteDashboardMutation);
    const [setCreateNewDashboardFromPreset] = useMutation<DashboardWithoutComponentsFragment>(createDashboardFromPresetMutation);

    // Setup

    if (!isErrorState && error) {
        setIsErrorState(true);
    }

    // Actions

    function refreshDashboard() {
        refetch();
    }

    function addCustomDashboardClicked() {
        HESwal.fire({
            title: 'Create dashboard',
            html: `<input type="text" id="dashboardName" class="swal2-input" placeholder="Dashboard name">`,
            confirmButtonText: 'Create',
            cancelButtonText: 'Cancel',
            focusConfirm: false,
            preConfirm: () => {
                // @ts-ignore
                const dashboardName = HESwal.getPopup()?.querySelector('#dashboardName')?.value as string
                if (!dashboardName || dashboardName.length === 0) {
                    HESwal.showValidationMessage(`Please enter a valid dashboard name`)
                }
                return { dashboardName: dashboardName }
            }
        }).then((result) => {
            const dashboardName = result.value?.dashboardName ?? '';
            if (dashboardName.length > 0) {
                const createDashboardMutationVariables: CreateDashboardMutationVariables = {
                    name: dashboardName,
                    uid: userId,
                }
                setCreateNewDashboard({ variables: createDashboardMutationVariables })
                    .then(refreshDashboard)
                    .catch(() => setIsErrorState(true));
            }
        });
    }

    function addDashboardFromPresetClicked(preset: DashboardPreset) {
        const mutationVariables: CreateDashboardFromPresetMutationVariables = {
            uid: userId,
            name: dashboardPresetName(preset),
            preset: preset,
        }
        setCreateNewDashboardFromPreset({ variables: mutationVariables })
            .then(() => refreshDashboard())
            .catch(() => alert('Failed to create the dashboard. Please check your internet connection and try it again later.'));
    }

    function editDashboardClicked(dashboardUuid: string) {
        history.push(`/dashboard/settings/${dashboardUuid}`);
    }

    function renameDashboardClicked(dashboardUuid: string) {
        const originalDashboardName = (data?.dashboards ?? []).find(x => x.dashboardUuid === dashboardUuid)?.name;
        HESwal.fire({
            title: 'Rename dashboard',
            html: `<input type="text" id="dashboardName" class="swal2-input" placeholder="Dashboard name" value="${originalDashboardName}">`,
            confirmButtonText: 'Rename',
            cancelButtonText: 'Cancel',
            focusConfirm: false,
            preConfirm: () => {
                // @ts-ignore
                const dashboardName = HESwal.getPopup()?.querySelector('#dashboardName')?.value as string
                if (!dashboardName || dashboardName.length === 0) {
                    HESwal.showValidationMessage(`Please enter a valid dashboard name`)
                }
                return { dashboardName: dashboardName }
            }
        }).then((result) => {
            const dashboardName = result.value?.dashboardName ?? '';
            if (dashboardName.length > 0) {
                const renameDashboardMutationVariables: RenameDashboardMutationVariables = {
                    uid: userId,
                    name: dashboardName,
                    dashboardUuid: dashboardUuid,
                }
                setRenameDashboard({ variables: renameDashboardMutationVariables })
                    .then(refreshDashboard)
                    .catch(() => setIsErrorState(true));
            }
        });
    }

    function deleteDashboardClicked(dashboardUuid: string) {
        const dashboardName = (data?.dashboards ?? []).find(x => x.dashboardUuid === dashboardUuid)?.name;
        HESwal.fire({
            title: 'Are you sure?',
            text: `Are you sure you want to delete ${dashboardName ?? ''}?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it',
            customClass: {
                confirmButton: 'btn btn-danger'
            }
        }).then((result) => {
            if (result.isConfirmed) {
                setDeleteDashboard({ variables: { dashboardUuid: dashboardUuid, uid: userId } as DeleteDashboardMutationVariables })
                    .then(refreshDashboard)
                    .catch(() => setIsErrorState(true));
            }
        })
    }

    // Body

    return (
        <AppContainer
            title="Dashboard configuration"
            activeSideBarItem={SideBarItem.dashboard}
        >
            <div
                className="container-fluid row workoutFiltrationRow"
                style={{
                    marginBottom: '12px'
                }}
            >
                <button
                    type="button"
                    className="btn btn-success dropdown-toggle"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                >Create dashboard </button>
                <div className="dropdown-menu">
                    <a
                        className="dropdown-item"
                        href="#"
                        onClick={addCustomDashboardClicked}
                    >Custom dashboard</a>
                    <hr className="createDashboardSectionSeparator" />
                    <p className="presetsLabel">Presets:</p>
                    { allDashboardPresets().map(preset => (
                        <a  key={preset} 
                            className="dropdown-item"
                            href="#"
                            onClick={() => addDashboardFromPresetClicked(preset)}
                        >{ dashboardPresetName(preset) }</a>
                    ))   
                    }
                </div>
            </div>
            { isErrorState && 
                <LoadingFailedState />
            }
            { !isErrorState && !data && 
                <ActivityIndicator />
            }
            { !isErrorState && data && 
                (data?.dashboards ?? [])
                    .sort((a,b) => a.name.localeCompare(b.name))
                    .map(dashboard => (
                        <DashboardSettingsItem
                            key={dashboard.dashboardUuid}
                            dashboard={dashboard}
                            editClicked={() => editDashboardClicked(dashboard.dashboardUuid)}
                            renameClicked={() => renameDashboardClicked(dashboard.dashboardUuid)}
                            deleteClicked={() => deleteDashboardClicked(dashboard.dashboardUuid)}
                        />)
                    )
            }
        </AppContainer>
    )
}