import { useState, Dispatch, SetStateAction } from 'react';
import { FirstWeekDay } from '../generated/graphql';
import { DateUtils } from '../models/DateUtils';

export type DateInterval = {
    from: Date,
    to: Date,
}

export type DateIntervalData = {
    interval: DateInterval,
    setInterval: Dispatch<SetStateAction<DateInterval>>,
}

export function useDateInterval(dateFrom: Date | null = null, dateTo: Date | null = null): DateIntervalData {
    const [interval, setInterval] = useState({
        from: makeDefaultDateFrom(),
        to: makeDefaultDateTo(),
    } as DateInterval)
    return {
        interval: interval,
        setInterval: setInterval,
    };
}

export function makeTodayDateInterval(): DateInterval {
    let dateFrom = new Date();
    dateFrom.setHours(0, 0, 0, 0);

    let dateTo = new Date();
    dateTo.setHours(23, 59, 59, 59);
    
    return {
        from: dateFrom,
        to: dateTo,
    }
}

export function makeThisWeekDateInterval(firstWeekDay: FirstWeekDay): DateInterval {
    const dateFrom = DateUtils.firstWeekDay(new Date(), firstWeekDay);

    let dateTo = new Date();
    dateTo.setHours(23, 59, 59, 59);
    
    return {
        from: dateFrom,
        to: dateTo,
    }
}

function makeDefaultDateFrom() {
    let dateFrom = new Date();
    dateFrom.setDate(dateFrom.getDate() - 7);
    dateFrom.setHours(0, 0, 0, 0);
    return dateFrom;
}

function makeDefaultDateTo(): Date {
    let dateTo = new Date();
    dateTo.setHours(23, 59, 59, 59);
    return dateTo;
}