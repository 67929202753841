import { HealthDataCollection } from "../../../models/datasources/HERemoteApiService"
import { NutritionChart } from "./NutritionChart";

export interface NutritionCurrentWeekProps {
    protein: HealthDataCollection | null,
    fats: HealthDataCollection | null,
    carbohydrates: HealthDataCollection | null,
}

export const NutritionCurrentWeek: React.FC<NutritionCurrentWeekProps> = (props: NutritionCurrentWeekProps) => {
    
    const proteinValue = dataCollectionToValue(props.protein);
    const fatsValue = dataCollectionToValue(props.fats);
    const carbohydratesValue = dataCollectionToValue(props.carbohydrates);
    const units = makeUnits(props);

    return (
        <div className="row nutritionTodayWrap">
            <NutritionChart
                protein={proteinValue}
                fats={fatsValue}
                carbohydrates={carbohydratesValue}
                units={units}
            />
        </div>
    )
}

function dataCollectionToValue(collection: HealthDataCollection | null): number {
    if (collection === null) {
        return 0;
    } else {
        return collection
            .records
            .map(record => {
                if (typeof record.value === 'number') {
                    return record.value;
                } else {
                    return parseFloat(record.value);
                }
            })
            .reduce((a,b) => a+b);
    }
}

function makeUnits(props: NutritionCurrentWeekProps): string {
    return props.protein?.units ?? props.fats?.units ?? props.carbohydrates?.units ?? '';
}