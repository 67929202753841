import gql from "graphql-tag";

export const setUserPreferencesMutation = gql`
mutation SetUserPreferences($uid: String!, $preferences: UserPreferencesInput!) {
    setUserPreferences(uid: $uid, preferences: $preferences) {
        firstWeekDay
        timeFormat
        numberFormat
        businessAccessKey
    }
}
`;