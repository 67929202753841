import { useSelector } from "react-redux";
import { HealthType } from "../../../../../entities/api/HealthType";
import { useSingleChartValue } from "../../../../../hooks/UseSingleChartValue";
import { HealthDataCollection } from "../../../../../models/datasources/HERemoteApiService"
import { RootState } from "../../../../../Store";
import "./TotalValueChart.css";

export interface TotalValueChartProps {
    dataType: HealthType | null,
    data: HealthDataCollection[],
}

export const TotalValueChart: React.FC<TotalValueChartProps> = (props: TotalValueChartProps) => {

    const numberFormat = useSelector((state: RootState) => state.preferencesReducer.preferences).numberFormat;
    const value = useSingleChartValue(props.data, numberFormat, props.dataType);

    return (
        <p className="totalValueLabel">{value}</p>
    )
}