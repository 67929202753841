import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type BusinessDataTypes = {
  __typename?: 'BusinessDataTypes';
  allowedTypeIds: Array<Scalars['Float']>;
};

export type BusinessLicense = {
  __typename?: 'BusinessLicense';
  activeFrom?: Maybe<Scalars['String']>;
  activeTo?: Maybe<Scalars['String']>;
  allowedDataTypeIds?: Maybe<BusinessDataTypes>;
  googleSheetId?: Maybe<Scalars['String']>;
  licenseKey: Scalars['String'];
  note: Scalars['String'];
  partnerName: Scalars['String'];
  state: BusinessLicenseState;
};

export enum BusinessLicenseState {
  Active = 'active',
  ClientRevoked = 'clientRevoked',
  PartnerRevoked = 'partnerRevoked',
  WaitingForActivation = 'waitingForActivation'
}

export type BusinessPartner = {
  __typename?: 'BusinessPartner';
  accessKeys: Array<BusinessPartnerAccessKey>;
  activeLicenseCount: Scalars['Float'];
  email: Scalars['String'];
  isAdmin: Scalars['Boolean'];
  licenseLimit: Scalars['Float'];
  licenses: Array<BusinessLicense>;
  name: Scalars['String'];
};

export type BusinessPartnerAccessKey = {
  __typename?: 'BusinessPartnerAccessKey';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type BusinessPartnerLoginResponse = {
  __typename?: 'BusinessPartnerLoginResponse';
  apiKey: Scalars['String'];
  partner: BusinessPartner;
};

export enum ComponentType {
  BarOrLineChart = 'barOrLineChart',
  Gauge = 'gauge',
  StackedBarLineChart = 'stackedBarLineChart',
  Table = 'table',
  TotalValue = 'totalValue'
}

export type ConnectedAccount = {
  __typename?: 'ConnectedAccount';
  accountKey: Scalars['String'];
  connectedAccountUuid: Scalars['String'];
  name: Scalars['String'];
  uid: Scalars['String'];
};

export type ConnectedAccountInput = {
  accountKey: Scalars['String'];
  connectedAccountUuid: Scalars['String'];
  name: Scalars['String'];
};

export type ConnectedBusinessAccount = {
  __typename?: 'ConnectedBusinessAccount';
  connectedAccountUuid: Scalars['String'];
  licenseKey: Scalars['String'];
  name: Scalars['String'];
  uid: Scalars['String'];
};

export type ConnectedBusinessAccountInput = {
  connectedAccountUuid: Scalars['String'];
  licenseKey: Scalars['String'];
  name: Scalars['String'];
};

export type Dashboard = {
  __typename?: 'Dashboard';
  components: Array<DashboardComponent>;
  dashboardUuid: Scalars['String'];
  defaultDatePreset?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  uid: Scalars['String'];
};

export type DashboardComponent = {
  __typename?: 'DashboardComponent';
  componentUuid: Scalars['String'];
  configuration: Array<DashboardComponentConfigurationValue>;
  dataTypeIds: Array<Scalars['Float']>;
  type: ComponentType;
};

export type DashboardComponentConfigurationValue = {
  __typename?: 'DashboardComponentConfigurationValue';
  key: Scalars['String'];
  value: Scalars['String'];
};

export type DashboardComponentInput = {
  componentUuid: Scalars['String'];
  configuration: Array<DashboardComponentInputConfigValue>;
  dataTypeIds: Array<Scalars['Int']>;
  type: ComponentType;
};

export type DashboardComponentInputConfigValue = {
  key: Scalars['String'];
  value: Scalars['String'];
};

export enum FirstWeekDay {
  Monday = 'monday',
  Sunday = 'sunday'
}

export type Mutation = {
  __typename?: 'Mutation';
  activateLicense?: Maybe<BusinessLicense>;
  changePassword: Scalars['Boolean'];
  createDashboard: Dashboard;
  createDashboardFromPreset: Dashboard;
  deleteConnectedAccount?: Maybe<ConnectedAccount>;
  deleteConnectedBusinessAccount?: Maybe<ConnectedBusinessAccount>;
  deleteDashboard?: Maybe<Dashboard>;
  generateAccessKey?: Maybe<Scalars['String']>;
  generateLicenses?: Maybe<Array<BusinessLicense>>;
  putConnectedAccount: ConnectedAccount;
  putConnectedBusinessAccount: ConnectedBusinessAccount;
  putDashboardComponents?: Maybe<Dashboard>;
  putRevenueCatId: RevenueCatSubscription;
  renameDashboard?: Maybe<Dashboard>;
  revokeAccessKey?: Maybe<Scalars['Boolean']>;
  revokeLicenseFromClient?: Maybe<BusinessLicense>;
  revokeLicenseFromPartner?: Maybe<BusinessLicense>;
  setDashboardDefaultDatePreset?: Maybe<Dashboard>;
  setGoogleSheetId?: Maybe<BusinessLicense>;
  setLicenseNote?: Maybe<BusinessLicense>;
  setUserPreferences: UserPreferences;
  setUserPreferencesBusinessAccessKey?: Maybe<UserPreferences>;
};


export type MutationActivateLicenseArgs = {
  accountKey: Scalars['String'];
  licenseKey: Scalars['String'];
};


export type MutationChangePasswordArgs = {
  currentPassword: Scalars['String'];
  newPassword: Scalars['String'];
};


export type MutationCreateDashboardArgs = {
  name: Scalars['String'];
  uid: Scalars['String'];
};


export type MutationCreateDashboardFromPresetArgs = {
  name: Scalars['String'];
  preset: Scalars['String'];
  uid: Scalars['String'];
};


export type MutationDeleteConnectedAccountArgs = {
  connectedAccountUuid: Scalars['String'];
  uid: Scalars['String'];
};


export type MutationDeleteConnectedBusinessAccountArgs = {
  connectedBusinessAccountUuid: Scalars['String'];
  uid: Scalars['String'];
};


export type MutationDeleteDashboardArgs = {
  dashboardUuid: Scalars['String'];
  uid: Scalars['String'];
};


export type MutationGenerateAccessKeyArgs = {
  name: Scalars['String'];
};


export type MutationGenerateLicensesArgs = {
  numberOfLicenses: Scalars['Float'];
};


export type MutationPutConnectedAccountArgs = {
  connectedAccount: ConnectedAccountInput;
  uid: Scalars['String'];
};


export type MutationPutConnectedBusinessAccountArgs = {
  connectedBusinessAccount: ConnectedBusinessAccountInput;
  uid: Scalars['String'];
};


export type MutationPutDashboardComponentsArgs = {
  components: Array<DashboardComponentInput>;
  dashboardUuid: Scalars['String'];
  uid: Scalars['String'];
};


export type MutationPutRevenueCatIdArgs = {
  revenueCatId: Scalars['String'];
  uid: Scalars['String'];
};


export type MutationRenameDashboardArgs = {
  dashboardUuid: Scalars['String'];
  name: Scalars['String'];
  uid: Scalars['String'];
};


export type MutationRevokeAccessKeyArgs = {
  accessKeyId: Scalars['String'];
};


export type MutationRevokeLicenseFromClientArgs = {
  accountKeyHash: Scalars['String'];
  licenseKey: Scalars['String'];
};


export type MutationRevokeLicenseFromPartnerArgs = {
  licenseKey: Scalars['String'];
};


export type MutationSetDashboardDefaultDatePresetArgs = {
  dashboardUuid: Scalars['String'];
  defaultDatePresetId: Scalars['Float'];
  uid: Scalars['String'];
};


export type MutationSetGoogleSheetIdArgs = {
  googleSheetId: Scalars['String'];
  licenseKey: Scalars['String'];
};


export type MutationSetLicenseNoteArgs = {
  licenseKey: Scalars['String'];
  note: Scalars['String'];
};


export type MutationSetUserPreferencesArgs = {
  preferences: UserPreferencesInput;
  uid: Scalars['String'];
};


export type MutationSetUserPreferencesBusinessAccessKeyArgs = {
  accessKey: Scalars['String'];
  uid: Scalars['String'];
};

export type News = {
  __typename?: 'News';
  content: Scalars['String'];
  title: Scalars['String'];
};

export enum NumberFormat {
  Classic = 'classic',
  Us = 'us'
}

export type Query = {
  __typename?: 'Query';
  allowedDataTypeIds?: Maybe<BusinessDataTypes>;
  businessPartner?: Maybe<BusinessPartner>;
  businessPartnerLogin?: Maybe<BusinessPartnerLoginResponse>;
  businessPartners: Array<BusinessPartner>;
  connectedAccounts: Array<ConnectedAccount>;
  connectedBusinessAccounts: Array<ConnectedBusinessAccount>;
  dashboard?: Maybe<Dashboard>;
  dashboards: Array<Dashboard>;
  license?: Maybe<BusinessLicense>;
  news?: Maybe<News>;
  userPreferences?: Maybe<UserPreferences>;
};


export type QueryBusinessPartnerLoginArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type QueryConnectedAccountsArgs = {
  uid: Scalars['String'];
};


export type QueryConnectedBusinessAccountsArgs = {
  uid: Scalars['String'];
};


export type QueryDashboardArgs = {
  dashboardUuid: Scalars['String'];
  uid: Scalars['String'];
};


export type QueryDashboardsArgs = {
  uid: Scalars['String'];
};


export type QueryLicenseArgs = {
  licenseKey: Scalars['String'];
};


export type QueryNewsArgs = {
  uid: Scalars['String'];
};


export type QueryUserPreferencesArgs = {
  uid: Scalars['String'];
};

export type RevenueCatSubscription = {
  __typename?: 'RevenueCatSubscription';
  revenueCatId: Scalars['String'];
  uid: Scalars['String'];
};

export enum TimeFormat {
  Format12h = 'format12h',
  Format24h = 'format24h'
}

export type UserPreferences = {
  __typename?: 'UserPreferences';
  businessAccessKey?: Maybe<Scalars['String']>;
  firstWeekDay: FirstWeekDay;
  numberFormat: NumberFormat;
  timeFormat: TimeFormat;
};

export type UserPreferencesInput = {
  firstWeekDay: FirstWeekDay;
  numberFormat: NumberFormat;
  timeFormat: TimeFormat;
};

export type ConnectedAccountsWithKeysQueryVariables = Exact<{
  uid: Scalars['String'];
}>;


export type ConnectedAccountsWithKeysQuery = { __typename?: 'Query', connectedAccounts: Array<{ __typename?: 'ConnectedAccount', name: string, accountKey: string }>, connectedBusinessAccounts: Array<{ __typename?: 'ConnectedBusinessAccount', uid: string, connectedAccountUuid: string, name: string, licenseKey: string }>, userPreferences?: { __typename?: 'UserPreferences', businessAccessKey?: string | null } | null };

export type DashboardsFullQueryVariables = Exact<{
  uid: Scalars['String'];
}>;


export type DashboardsFullQuery = { __typename?: 'Query', dashboards: Array<{ __typename?: 'Dashboard', uid: string, dashboardUuid: string, name: string, defaultDatePreset?: number | null, components: Array<{ __typename?: 'DashboardComponent', componentUuid: string, dataTypeIds: Array<number>, type: ComponentType, configuration: Array<{ __typename?: 'DashboardComponentConfigurationValue', key: string, value: string }> }> }> };

export type DashboardsQueryVariables = Exact<{
  uid: Scalars['String'];
}>;


export type DashboardsQuery = { __typename?: 'Query', dashboards: Array<{ __typename?: 'Dashboard', uid: string, dashboardUuid: string, name: string, defaultDatePreset?: number | null }> };

export type RenameDashboardMutationVariables = Exact<{
  uid: Scalars['String'];
  name: Scalars['String'];
  dashboardUuid: Scalars['String'];
}>;


export type RenameDashboardMutation = { __typename?: 'Mutation', renameDashboard?: { __typename?: 'Dashboard', uid: string, dashboardUuid: string, name: string, defaultDatePreset?: number | null } | null };

export type DeleteDashboardMutationVariables = Exact<{
  uid: Scalars['String'];
  dashboardUuid: Scalars['String'];
}>;


export type DeleteDashboardMutation = { __typename?: 'Mutation', deleteDashboard?: { __typename?: 'Dashboard', uid: string, dashboardUuid: string, name: string, defaultDatePreset?: number | null } | null };

export type DashboardQueryVariables = Exact<{
  uid: Scalars['String'];
  dashboardUuid: Scalars['String'];
}>;


export type DashboardQuery = { __typename?: 'Query', dashboard?: { __typename?: 'Dashboard', uid: string, dashboardUuid: string, name: string, defaultDatePreset?: number | null, components: Array<{ __typename?: 'DashboardComponent', componentUuid: string, dataTypeIds: Array<number>, type: ComponentType, configuration: Array<{ __typename?: 'DashboardComponentConfigurationValue', key: string, value: string }> }> } | null };

export type SetDashboardDefaultDatePresetMutationVariables = Exact<{
  defaultDatePresetId: Scalars['Float'];
  dashboardUuid: Scalars['String'];
  uid: Scalars['String'];
}>;


export type SetDashboardDefaultDatePresetMutation = { __typename?: 'Mutation', setDashboardDefaultDatePreset?: { __typename?: 'Dashboard', uid: string, dashboardUuid: string, name: string, defaultDatePreset?: number | null, components: Array<{ __typename?: 'DashboardComponent', componentUuid: string, dataTypeIds: Array<number>, type: ComponentType, configuration: Array<{ __typename?: 'DashboardComponentConfigurationValue', key: string, value: string }> }> } | null };

export type ConnectedAccountsQueryVariables = Exact<{
  uid: Scalars['String'];
}>;


export type ConnectedAccountsQuery = { __typename?: 'Query', connectedAccounts: Array<{ __typename?: 'ConnectedAccount', connectedAccountUuid: string, name: string, accountKey: string }> };

export type DeleteConnectedAccountMutationVariables = Exact<{
  uid: Scalars['String'];
  connectedAccountUuid: Scalars['String'];
}>;


export type DeleteConnectedAccountMutation = { __typename?: 'Mutation', deleteConnectedAccount?: { __typename?: 'ConnectedAccount', connectedAccountUuid: string, name: string, accountKey: string } | null };

export type ConnectedBusinessAccountsQueryVariables = Exact<{
  uid: Scalars['String'];
}>;


export type ConnectedBusinessAccountsQuery = { __typename?: 'Query', connectedBusinessAccounts: Array<{ __typename?: 'ConnectedBusinessAccount', connectedAccountUuid: string, name: string, licenseKey: string }> };

export type DeleteConnectedBusinessAccountMutationVariables = Exact<{
  uid: Scalars['String'];
  connectedBusinessAccountUuid: Scalars['String'];
}>;


export type DeleteConnectedBusinessAccountMutation = { __typename?: 'Mutation', deleteConnectedBusinessAccount?: { __typename?: 'ConnectedBusinessAccount', connectedAccountUuid: string, name: string, licenseKey: string } | null };

export type DashboardWithComponentsFragment = { __typename?: 'Dashboard', uid: string, dashboardUuid: string, name: string, defaultDatePreset?: number | null, components: Array<{ __typename?: 'DashboardComponent', componentUuid: string, dataTypeIds: Array<number>, type: ComponentType, configuration: Array<{ __typename?: 'DashboardComponentConfigurationValue', key: string, value: string }> }> };

export type DashboardWithoutComponentsFragment = { __typename?: 'Dashboard', uid: string, dashboardUuid: string, name: string, defaultDatePreset?: number | null };

export type UserPreferencesQueryVariables = Exact<{
  uid: Scalars['String'];
}>;


export type UserPreferencesQuery = { __typename?: 'Query', userPreferences?: { __typename?: 'UserPreferences', timeFormat: TimeFormat, numberFormat: NumberFormat, firstWeekDay: FirstWeekDay, businessAccessKey?: string | null } | null };

export type AddConnectedAccountMutationVariables = Exact<{
  uid: Scalars['String'];
  connectedAccount: ConnectedAccountInput;
}>;


export type AddConnectedAccountMutation = { __typename?: 'Mutation', putConnectedAccount: { __typename?: 'ConnectedAccount', connectedAccountUuid: string, name: string, accountKey: string } };

export type AddConnectedBusinessAccountMutationVariables = Exact<{
  uid: Scalars['String'];
  connectedBusinessAccount: ConnectedBusinessAccountInput;
}>;


export type AddConnectedBusinessAccountMutation = { __typename?: 'Mutation', putConnectedBusinessAccount: { __typename?: 'ConnectedBusinessAccount', uid: string, connectedAccountUuid: string, name: string, licenseKey: string } };

export type CreateDashboardFromPresetMutationVariables = Exact<{
  preset: Scalars['String'];
  name: Scalars['String'];
  uid: Scalars['String'];
}>;


export type CreateDashboardFromPresetMutation = { __typename?: 'Mutation', createDashboardFromPreset: { __typename?: 'Dashboard', uid: string, dashboardUuid: string, name: string, defaultDatePreset?: number | null } };

export type CreateDashboardMutationVariables = Exact<{
  name: Scalars['String'];
  uid: Scalars['String'];
}>;


export type CreateDashboardMutation = { __typename?: 'Mutation', createDashboard: { __typename?: 'Dashboard', uid: string, dashboardUuid: string, name: string, defaultDatePreset?: number | null } };

export type PutDashboardComponentsMutationVariables = Exact<{
  uid: Scalars['String'];
  dashboardUuid: Scalars['String'];
  components: Array<DashboardComponentInput> | DashboardComponentInput;
}>;


export type PutDashboardComponentsMutation = { __typename?: 'Mutation', putDashboardComponents?: { __typename?: 'Dashboard', uid: string, dashboardUuid: string, name: string, defaultDatePreset?: number | null, components: Array<{ __typename?: 'DashboardComponent', componentUuid: string, dataTypeIds: Array<number>, type: ComponentType, configuration: Array<{ __typename?: 'DashboardComponentConfigurationValue', key: string, value: string }> }> } | null };

export type SetUserPreferencesMutationVariables = Exact<{
  uid: Scalars['String'];
  preferences: UserPreferencesInput;
}>;


export type SetUserPreferencesMutation = { __typename?: 'Mutation', setUserPreferences: { __typename?: 'UserPreferences', firstWeekDay: FirstWeekDay, timeFormat: TimeFormat, numberFormat: NumberFormat, businessAccessKey?: string | null } };

export type SetUserPreferencesBusinessAccessKeyMutationVariables = Exact<{
  uid: Scalars['String'];
  accessKey: Scalars['String'];
}>;


export type SetUserPreferencesBusinessAccessKeyMutation = { __typename?: 'Mutation', setUserPreferencesBusinessAccessKey?: { __typename?: 'UserPreferences', firstWeekDay: FirstWeekDay, timeFormat: TimeFormat, numberFormat: NumberFormat, businessAccessKey?: string | null } | null };

export const DashboardWithComponentsFragmentDoc = gql`
    fragment DashboardWithComponents on Dashboard {
  uid
  dashboardUuid
  name
  defaultDatePreset
  components {
    componentUuid
    dataTypeIds
    type
    configuration {
      key
      value
    }
  }
}
    `;
export const DashboardWithoutComponentsFragmentDoc = gql`
    fragment DashboardWithoutComponents on Dashboard {
  uid
  dashboardUuid
  name
  defaultDatePreset
}
    `;
export const ConnectedAccountsWithKeysDocument = gql`
    query ConnectedAccountsWithKeys($uid: String!) {
  connectedAccounts(uid: $uid) {
    name
    accountKey
  }
  connectedBusinessAccounts(uid: $uid) {
    uid
    connectedAccountUuid
    name
    licenseKey
  }
  userPreferences(uid: $uid) {
    businessAccessKey
  }
}
    `;

/**
 * __useConnectedAccountsWithKeysQuery__
 *
 * To run a query within a React component, call `useConnectedAccountsWithKeysQuery` and pass it any options that fit your needs.
 * When your component renders, `useConnectedAccountsWithKeysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConnectedAccountsWithKeysQuery({
 *   variables: {
 *      uid: // value for 'uid'
 *   },
 * });
 */
export function useConnectedAccountsWithKeysQuery(baseOptions: Apollo.QueryHookOptions<ConnectedAccountsWithKeysQuery, ConnectedAccountsWithKeysQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ConnectedAccountsWithKeysQuery, ConnectedAccountsWithKeysQueryVariables>(ConnectedAccountsWithKeysDocument, options);
      }
export function useConnectedAccountsWithKeysLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConnectedAccountsWithKeysQuery, ConnectedAccountsWithKeysQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ConnectedAccountsWithKeysQuery, ConnectedAccountsWithKeysQueryVariables>(ConnectedAccountsWithKeysDocument, options);
        }
export type ConnectedAccountsWithKeysQueryHookResult = ReturnType<typeof useConnectedAccountsWithKeysQuery>;
export type ConnectedAccountsWithKeysLazyQueryHookResult = ReturnType<typeof useConnectedAccountsWithKeysLazyQuery>;
export type ConnectedAccountsWithKeysQueryResult = Apollo.QueryResult<ConnectedAccountsWithKeysQuery, ConnectedAccountsWithKeysQueryVariables>;
export const DashboardsFullDocument = gql`
    query DashboardsFull($uid: String!) {
  dashboards(uid: $uid) {
    ...DashboardWithComponents
  }
}
    ${DashboardWithComponentsFragmentDoc}`;

/**
 * __useDashboardsFullQuery__
 *
 * To run a query within a React component, call `useDashboardsFullQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardsFullQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardsFullQuery({
 *   variables: {
 *      uid: // value for 'uid'
 *   },
 * });
 */
export function useDashboardsFullQuery(baseOptions: Apollo.QueryHookOptions<DashboardsFullQuery, DashboardsFullQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DashboardsFullQuery, DashboardsFullQueryVariables>(DashboardsFullDocument, options);
      }
export function useDashboardsFullLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DashboardsFullQuery, DashboardsFullQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DashboardsFullQuery, DashboardsFullQueryVariables>(DashboardsFullDocument, options);
        }
export type DashboardsFullQueryHookResult = ReturnType<typeof useDashboardsFullQuery>;
export type DashboardsFullLazyQueryHookResult = ReturnType<typeof useDashboardsFullLazyQuery>;
export type DashboardsFullQueryResult = Apollo.QueryResult<DashboardsFullQuery, DashboardsFullQueryVariables>;
export const DashboardsDocument = gql`
    query Dashboards($uid: String!) {
  dashboards(uid: $uid) {
    ...DashboardWithoutComponents
  }
}
    ${DashboardWithoutComponentsFragmentDoc}`;

/**
 * __useDashboardsQuery__
 *
 * To run a query within a React component, call `useDashboardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardsQuery({
 *   variables: {
 *      uid: // value for 'uid'
 *   },
 * });
 */
export function useDashboardsQuery(baseOptions: Apollo.QueryHookOptions<DashboardsQuery, DashboardsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DashboardsQuery, DashboardsQueryVariables>(DashboardsDocument, options);
      }
export function useDashboardsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DashboardsQuery, DashboardsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DashboardsQuery, DashboardsQueryVariables>(DashboardsDocument, options);
        }
export type DashboardsQueryHookResult = ReturnType<typeof useDashboardsQuery>;
export type DashboardsLazyQueryHookResult = ReturnType<typeof useDashboardsLazyQuery>;
export type DashboardsQueryResult = Apollo.QueryResult<DashboardsQuery, DashboardsQueryVariables>;
export const RenameDashboardDocument = gql`
    mutation RenameDashboard($uid: String!, $name: String!, $dashboardUuid: String!) {
  renameDashboard(uid: $uid, name: $name, dashboardUuid: $dashboardUuid) {
    ...DashboardWithoutComponents
  }
}
    ${DashboardWithoutComponentsFragmentDoc}`;
export type RenameDashboardMutationFn = Apollo.MutationFunction<RenameDashboardMutation, RenameDashboardMutationVariables>;

/**
 * __useRenameDashboardMutation__
 *
 * To run a mutation, you first call `useRenameDashboardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRenameDashboardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [renameDashboardMutation, { data, loading, error }] = useRenameDashboardMutation({
 *   variables: {
 *      uid: // value for 'uid'
 *      name: // value for 'name'
 *      dashboardUuid: // value for 'dashboardUuid'
 *   },
 * });
 */
export function useRenameDashboardMutation(baseOptions?: Apollo.MutationHookOptions<RenameDashboardMutation, RenameDashboardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RenameDashboardMutation, RenameDashboardMutationVariables>(RenameDashboardDocument, options);
      }
export type RenameDashboardMutationHookResult = ReturnType<typeof useRenameDashboardMutation>;
export type RenameDashboardMutationResult = Apollo.MutationResult<RenameDashboardMutation>;
export type RenameDashboardMutationOptions = Apollo.BaseMutationOptions<RenameDashboardMutation, RenameDashboardMutationVariables>;
export const DeleteDashboardDocument = gql`
    mutation DeleteDashboard($uid: String!, $dashboardUuid: String!) {
  deleteDashboard(uid: $uid, dashboardUuid: $dashboardUuid) {
    ...DashboardWithoutComponents
  }
}
    ${DashboardWithoutComponentsFragmentDoc}`;
export type DeleteDashboardMutationFn = Apollo.MutationFunction<DeleteDashboardMutation, DeleteDashboardMutationVariables>;

/**
 * __useDeleteDashboardMutation__
 *
 * To run a mutation, you first call `useDeleteDashboardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDashboardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDashboardMutation, { data, loading, error }] = useDeleteDashboardMutation({
 *   variables: {
 *      uid: // value for 'uid'
 *      dashboardUuid: // value for 'dashboardUuid'
 *   },
 * });
 */
export function useDeleteDashboardMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDashboardMutation, DeleteDashboardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDashboardMutation, DeleteDashboardMutationVariables>(DeleteDashboardDocument, options);
      }
export type DeleteDashboardMutationHookResult = ReturnType<typeof useDeleteDashboardMutation>;
export type DeleteDashboardMutationResult = Apollo.MutationResult<DeleteDashboardMutation>;
export type DeleteDashboardMutationOptions = Apollo.BaseMutationOptions<DeleteDashboardMutation, DeleteDashboardMutationVariables>;
export const DashboardDocument = gql`
    query Dashboard($uid: String!, $dashboardUuid: String!) {
  dashboard(uid: $uid, dashboardUuid: $dashboardUuid) {
    ...DashboardWithComponents
  }
}
    ${DashboardWithComponentsFragmentDoc}`;

/**
 * __useDashboardQuery__
 *
 * To run a query within a React component, call `useDashboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardQuery({
 *   variables: {
 *      uid: // value for 'uid'
 *      dashboardUuid: // value for 'dashboardUuid'
 *   },
 * });
 */
export function useDashboardQuery(baseOptions: Apollo.QueryHookOptions<DashboardQuery, DashboardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DashboardQuery, DashboardQueryVariables>(DashboardDocument, options);
      }
export function useDashboardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DashboardQuery, DashboardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DashboardQuery, DashboardQueryVariables>(DashboardDocument, options);
        }
export type DashboardQueryHookResult = ReturnType<typeof useDashboardQuery>;
export type DashboardLazyQueryHookResult = ReturnType<typeof useDashboardLazyQuery>;
export type DashboardQueryResult = Apollo.QueryResult<DashboardQuery, DashboardQueryVariables>;
export const SetDashboardDefaultDatePresetDocument = gql`
    mutation SetDashboardDefaultDatePreset($defaultDatePresetId: Float!, $dashboardUuid: String!, $uid: String!) {
  setDashboardDefaultDatePreset(
    defaultDatePresetId: $defaultDatePresetId
    dashboardUuid: $dashboardUuid
    uid: $uid
  ) {
    ...DashboardWithComponents
  }
}
    ${DashboardWithComponentsFragmentDoc}`;
export type SetDashboardDefaultDatePresetMutationFn = Apollo.MutationFunction<SetDashboardDefaultDatePresetMutation, SetDashboardDefaultDatePresetMutationVariables>;

/**
 * __useSetDashboardDefaultDatePresetMutation__
 *
 * To run a mutation, you first call `useSetDashboardDefaultDatePresetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetDashboardDefaultDatePresetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setDashboardDefaultDatePresetMutation, { data, loading, error }] = useSetDashboardDefaultDatePresetMutation({
 *   variables: {
 *      defaultDatePresetId: // value for 'defaultDatePresetId'
 *      dashboardUuid: // value for 'dashboardUuid'
 *      uid: // value for 'uid'
 *   },
 * });
 */
export function useSetDashboardDefaultDatePresetMutation(baseOptions?: Apollo.MutationHookOptions<SetDashboardDefaultDatePresetMutation, SetDashboardDefaultDatePresetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetDashboardDefaultDatePresetMutation, SetDashboardDefaultDatePresetMutationVariables>(SetDashboardDefaultDatePresetDocument, options);
      }
export type SetDashboardDefaultDatePresetMutationHookResult = ReturnType<typeof useSetDashboardDefaultDatePresetMutation>;
export type SetDashboardDefaultDatePresetMutationResult = Apollo.MutationResult<SetDashboardDefaultDatePresetMutation>;
export type SetDashboardDefaultDatePresetMutationOptions = Apollo.BaseMutationOptions<SetDashboardDefaultDatePresetMutation, SetDashboardDefaultDatePresetMutationVariables>;
export const ConnectedAccountsDocument = gql`
    query ConnectedAccounts($uid: String!) {
  connectedAccounts(uid: $uid) {
    connectedAccountUuid
    name
    accountKey
  }
}
    `;

/**
 * __useConnectedAccountsQuery__
 *
 * To run a query within a React component, call `useConnectedAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useConnectedAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConnectedAccountsQuery({
 *   variables: {
 *      uid: // value for 'uid'
 *   },
 * });
 */
export function useConnectedAccountsQuery(baseOptions: Apollo.QueryHookOptions<ConnectedAccountsQuery, ConnectedAccountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ConnectedAccountsQuery, ConnectedAccountsQueryVariables>(ConnectedAccountsDocument, options);
      }
export function useConnectedAccountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConnectedAccountsQuery, ConnectedAccountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ConnectedAccountsQuery, ConnectedAccountsQueryVariables>(ConnectedAccountsDocument, options);
        }
export type ConnectedAccountsQueryHookResult = ReturnType<typeof useConnectedAccountsQuery>;
export type ConnectedAccountsLazyQueryHookResult = ReturnType<typeof useConnectedAccountsLazyQuery>;
export type ConnectedAccountsQueryResult = Apollo.QueryResult<ConnectedAccountsQuery, ConnectedAccountsQueryVariables>;
export const DeleteConnectedAccountDocument = gql`
    mutation DeleteConnectedAccount($uid: String!, $connectedAccountUuid: String!) {
  deleteConnectedAccount(uid: $uid, connectedAccountUuid: $connectedAccountUuid) {
    connectedAccountUuid
    name
    accountKey
  }
}
    `;
export type DeleteConnectedAccountMutationFn = Apollo.MutationFunction<DeleteConnectedAccountMutation, DeleteConnectedAccountMutationVariables>;

/**
 * __useDeleteConnectedAccountMutation__
 *
 * To run a mutation, you first call `useDeleteConnectedAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteConnectedAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteConnectedAccountMutation, { data, loading, error }] = useDeleteConnectedAccountMutation({
 *   variables: {
 *      uid: // value for 'uid'
 *      connectedAccountUuid: // value for 'connectedAccountUuid'
 *   },
 * });
 */
export function useDeleteConnectedAccountMutation(baseOptions?: Apollo.MutationHookOptions<DeleteConnectedAccountMutation, DeleteConnectedAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteConnectedAccountMutation, DeleteConnectedAccountMutationVariables>(DeleteConnectedAccountDocument, options);
      }
export type DeleteConnectedAccountMutationHookResult = ReturnType<typeof useDeleteConnectedAccountMutation>;
export type DeleteConnectedAccountMutationResult = Apollo.MutationResult<DeleteConnectedAccountMutation>;
export type DeleteConnectedAccountMutationOptions = Apollo.BaseMutationOptions<DeleteConnectedAccountMutation, DeleteConnectedAccountMutationVariables>;
export const ConnectedBusinessAccountsDocument = gql`
    query ConnectedBusinessAccounts($uid: String!) {
  connectedBusinessAccounts(uid: $uid) {
    connectedAccountUuid
    name
    licenseKey
  }
}
    `;

/**
 * __useConnectedBusinessAccountsQuery__
 *
 * To run a query within a React component, call `useConnectedBusinessAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useConnectedBusinessAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConnectedBusinessAccountsQuery({
 *   variables: {
 *      uid: // value for 'uid'
 *   },
 * });
 */
export function useConnectedBusinessAccountsQuery(baseOptions: Apollo.QueryHookOptions<ConnectedBusinessAccountsQuery, ConnectedBusinessAccountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ConnectedBusinessAccountsQuery, ConnectedBusinessAccountsQueryVariables>(ConnectedBusinessAccountsDocument, options);
      }
export function useConnectedBusinessAccountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConnectedBusinessAccountsQuery, ConnectedBusinessAccountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ConnectedBusinessAccountsQuery, ConnectedBusinessAccountsQueryVariables>(ConnectedBusinessAccountsDocument, options);
        }
export type ConnectedBusinessAccountsQueryHookResult = ReturnType<typeof useConnectedBusinessAccountsQuery>;
export type ConnectedBusinessAccountsLazyQueryHookResult = ReturnType<typeof useConnectedBusinessAccountsLazyQuery>;
export type ConnectedBusinessAccountsQueryResult = Apollo.QueryResult<ConnectedBusinessAccountsQuery, ConnectedBusinessAccountsQueryVariables>;
export const DeleteConnectedBusinessAccountDocument = gql`
    mutation DeleteConnectedBusinessAccount($uid: String!, $connectedBusinessAccountUuid: String!) {
  deleteConnectedBusinessAccount(
    uid: $uid
    connectedBusinessAccountUuid: $connectedBusinessAccountUuid
  ) {
    connectedAccountUuid
    name
    licenseKey
  }
}
    `;
export type DeleteConnectedBusinessAccountMutationFn = Apollo.MutationFunction<DeleteConnectedBusinessAccountMutation, DeleteConnectedBusinessAccountMutationVariables>;

/**
 * __useDeleteConnectedBusinessAccountMutation__
 *
 * To run a mutation, you first call `useDeleteConnectedBusinessAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteConnectedBusinessAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteConnectedBusinessAccountMutation, { data, loading, error }] = useDeleteConnectedBusinessAccountMutation({
 *   variables: {
 *      uid: // value for 'uid'
 *      connectedBusinessAccountUuid: // value for 'connectedBusinessAccountUuid'
 *   },
 * });
 */
export function useDeleteConnectedBusinessAccountMutation(baseOptions?: Apollo.MutationHookOptions<DeleteConnectedBusinessAccountMutation, DeleteConnectedBusinessAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteConnectedBusinessAccountMutation, DeleteConnectedBusinessAccountMutationVariables>(DeleteConnectedBusinessAccountDocument, options);
      }
export type DeleteConnectedBusinessAccountMutationHookResult = ReturnType<typeof useDeleteConnectedBusinessAccountMutation>;
export type DeleteConnectedBusinessAccountMutationResult = Apollo.MutationResult<DeleteConnectedBusinessAccountMutation>;
export type DeleteConnectedBusinessAccountMutationOptions = Apollo.BaseMutationOptions<DeleteConnectedBusinessAccountMutation, DeleteConnectedBusinessAccountMutationVariables>;
export const UserPreferencesDocument = gql`
    query UserPreferences($uid: String!) {
  userPreferences(uid: $uid) {
    timeFormat
    numberFormat
    firstWeekDay
    businessAccessKey
  }
}
    `;

/**
 * __useUserPreferencesQuery__
 *
 * To run a query within a React component, call `useUserPreferencesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserPreferencesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserPreferencesQuery({
 *   variables: {
 *      uid: // value for 'uid'
 *   },
 * });
 */
export function useUserPreferencesQuery(baseOptions: Apollo.QueryHookOptions<UserPreferencesQuery, UserPreferencesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserPreferencesQuery, UserPreferencesQueryVariables>(UserPreferencesDocument, options);
      }
export function useUserPreferencesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserPreferencesQuery, UserPreferencesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserPreferencesQuery, UserPreferencesQueryVariables>(UserPreferencesDocument, options);
        }
export type UserPreferencesQueryHookResult = ReturnType<typeof useUserPreferencesQuery>;
export type UserPreferencesLazyQueryHookResult = ReturnType<typeof useUserPreferencesLazyQuery>;
export type UserPreferencesQueryResult = Apollo.QueryResult<UserPreferencesQuery, UserPreferencesQueryVariables>;
export const AddConnectedAccountDocument = gql`
    mutation AddConnectedAccount($uid: String!, $connectedAccount: ConnectedAccountInput!) {
  putConnectedAccount(uid: $uid, connectedAccount: $connectedAccount) {
    connectedAccountUuid
    name
    accountKey
  }
}
    `;
export type AddConnectedAccountMutationFn = Apollo.MutationFunction<AddConnectedAccountMutation, AddConnectedAccountMutationVariables>;

/**
 * __useAddConnectedAccountMutation__
 *
 * To run a mutation, you first call `useAddConnectedAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddConnectedAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addConnectedAccountMutation, { data, loading, error }] = useAddConnectedAccountMutation({
 *   variables: {
 *      uid: // value for 'uid'
 *      connectedAccount: // value for 'connectedAccount'
 *   },
 * });
 */
export function useAddConnectedAccountMutation(baseOptions?: Apollo.MutationHookOptions<AddConnectedAccountMutation, AddConnectedAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddConnectedAccountMutation, AddConnectedAccountMutationVariables>(AddConnectedAccountDocument, options);
      }
export type AddConnectedAccountMutationHookResult = ReturnType<typeof useAddConnectedAccountMutation>;
export type AddConnectedAccountMutationResult = Apollo.MutationResult<AddConnectedAccountMutation>;
export type AddConnectedAccountMutationOptions = Apollo.BaseMutationOptions<AddConnectedAccountMutation, AddConnectedAccountMutationVariables>;
export const AddConnectedBusinessAccountDocument = gql`
    mutation AddConnectedBusinessAccount($uid: String!, $connectedBusinessAccount: ConnectedBusinessAccountInput!) {
  putConnectedBusinessAccount(
    connectedBusinessAccount: $connectedBusinessAccount
    uid: $uid
  ) {
    uid
    connectedAccountUuid
    name
    licenseKey
  }
}
    `;
export type AddConnectedBusinessAccountMutationFn = Apollo.MutationFunction<AddConnectedBusinessAccountMutation, AddConnectedBusinessAccountMutationVariables>;

/**
 * __useAddConnectedBusinessAccountMutation__
 *
 * To run a mutation, you first call `useAddConnectedBusinessAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddConnectedBusinessAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addConnectedBusinessAccountMutation, { data, loading, error }] = useAddConnectedBusinessAccountMutation({
 *   variables: {
 *      uid: // value for 'uid'
 *      connectedBusinessAccount: // value for 'connectedBusinessAccount'
 *   },
 * });
 */
export function useAddConnectedBusinessAccountMutation(baseOptions?: Apollo.MutationHookOptions<AddConnectedBusinessAccountMutation, AddConnectedBusinessAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddConnectedBusinessAccountMutation, AddConnectedBusinessAccountMutationVariables>(AddConnectedBusinessAccountDocument, options);
      }
export type AddConnectedBusinessAccountMutationHookResult = ReturnType<typeof useAddConnectedBusinessAccountMutation>;
export type AddConnectedBusinessAccountMutationResult = Apollo.MutationResult<AddConnectedBusinessAccountMutation>;
export type AddConnectedBusinessAccountMutationOptions = Apollo.BaseMutationOptions<AddConnectedBusinessAccountMutation, AddConnectedBusinessAccountMutationVariables>;
export const CreateDashboardFromPresetDocument = gql`
    mutation CreateDashboardFromPreset($preset: String!, $name: String!, $uid: String!) {
  createDashboardFromPreset(preset: $preset, name: $name, uid: $uid) {
    ...DashboardWithoutComponents
  }
}
    ${DashboardWithoutComponentsFragmentDoc}`;
export type CreateDashboardFromPresetMutationFn = Apollo.MutationFunction<CreateDashboardFromPresetMutation, CreateDashboardFromPresetMutationVariables>;

/**
 * __useCreateDashboardFromPresetMutation__
 *
 * To run a mutation, you first call `useCreateDashboardFromPresetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDashboardFromPresetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDashboardFromPresetMutation, { data, loading, error }] = useCreateDashboardFromPresetMutation({
 *   variables: {
 *      preset: // value for 'preset'
 *      name: // value for 'name'
 *      uid: // value for 'uid'
 *   },
 * });
 */
export function useCreateDashboardFromPresetMutation(baseOptions?: Apollo.MutationHookOptions<CreateDashboardFromPresetMutation, CreateDashboardFromPresetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDashboardFromPresetMutation, CreateDashboardFromPresetMutationVariables>(CreateDashboardFromPresetDocument, options);
      }
export type CreateDashboardFromPresetMutationHookResult = ReturnType<typeof useCreateDashboardFromPresetMutation>;
export type CreateDashboardFromPresetMutationResult = Apollo.MutationResult<CreateDashboardFromPresetMutation>;
export type CreateDashboardFromPresetMutationOptions = Apollo.BaseMutationOptions<CreateDashboardFromPresetMutation, CreateDashboardFromPresetMutationVariables>;
export const CreateDashboardDocument = gql`
    mutation CreateDashboard($name: String!, $uid: String!) {
  createDashboard(name: $name, uid: $uid) {
    ...DashboardWithoutComponents
  }
}
    ${DashboardWithoutComponentsFragmentDoc}`;
export type CreateDashboardMutationFn = Apollo.MutationFunction<CreateDashboardMutation, CreateDashboardMutationVariables>;

/**
 * __useCreateDashboardMutation__
 *
 * To run a mutation, you first call `useCreateDashboardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDashboardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDashboardMutation, { data, loading, error }] = useCreateDashboardMutation({
 *   variables: {
 *      name: // value for 'name'
 *      uid: // value for 'uid'
 *   },
 * });
 */
export function useCreateDashboardMutation(baseOptions?: Apollo.MutationHookOptions<CreateDashboardMutation, CreateDashboardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDashboardMutation, CreateDashboardMutationVariables>(CreateDashboardDocument, options);
      }
export type CreateDashboardMutationHookResult = ReturnType<typeof useCreateDashboardMutation>;
export type CreateDashboardMutationResult = Apollo.MutationResult<CreateDashboardMutation>;
export type CreateDashboardMutationOptions = Apollo.BaseMutationOptions<CreateDashboardMutation, CreateDashboardMutationVariables>;
export const PutDashboardComponentsDocument = gql`
    mutation PutDashboardComponents($uid: String!, $dashboardUuid: String!, $components: [DashboardComponentInput!]!) {
  putDashboardComponents(
    uid: $uid
    dashboardUuid: $dashboardUuid
    components: $components
  ) {
    ...DashboardWithComponents
  }
}
    ${DashboardWithComponentsFragmentDoc}`;
export type PutDashboardComponentsMutationFn = Apollo.MutationFunction<PutDashboardComponentsMutation, PutDashboardComponentsMutationVariables>;

/**
 * __usePutDashboardComponentsMutation__
 *
 * To run a mutation, you first call `usePutDashboardComponentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePutDashboardComponentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [putDashboardComponentsMutation, { data, loading, error }] = usePutDashboardComponentsMutation({
 *   variables: {
 *      uid: // value for 'uid'
 *      dashboardUuid: // value for 'dashboardUuid'
 *      components: // value for 'components'
 *   },
 * });
 */
export function usePutDashboardComponentsMutation(baseOptions?: Apollo.MutationHookOptions<PutDashboardComponentsMutation, PutDashboardComponentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PutDashboardComponentsMutation, PutDashboardComponentsMutationVariables>(PutDashboardComponentsDocument, options);
      }
export type PutDashboardComponentsMutationHookResult = ReturnType<typeof usePutDashboardComponentsMutation>;
export type PutDashboardComponentsMutationResult = Apollo.MutationResult<PutDashboardComponentsMutation>;
export type PutDashboardComponentsMutationOptions = Apollo.BaseMutationOptions<PutDashboardComponentsMutation, PutDashboardComponentsMutationVariables>;
export const SetUserPreferencesDocument = gql`
    mutation SetUserPreferences($uid: String!, $preferences: UserPreferencesInput!) {
  setUserPreferences(uid: $uid, preferences: $preferences) {
    firstWeekDay
    timeFormat
    numberFormat
    businessAccessKey
  }
}
    `;
export type SetUserPreferencesMutationFn = Apollo.MutationFunction<SetUserPreferencesMutation, SetUserPreferencesMutationVariables>;

/**
 * __useSetUserPreferencesMutation__
 *
 * To run a mutation, you first call `useSetUserPreferencesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetUserPreferencesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setUserPreferencesMutation, { data, loading, error }] = useSetUserPreferencesMutation({
 *   variables: {
 *      uid: // value for 'uid'
 *      preferences: // value for 'preferences'
 *   },
 * });
 */
export function useSetUserPreferencesMutation(baseOptions?: Apollo.MutationHookOptions<SetUserPreferencesMutation, SetUserPreferencesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetUserPreferencesMutation, SetUserPreferencesMutationVariables>(SetUserPreferencesDocument, options);
      }
export type SetUserPreferencesMutationHookResult = ReturnType<typeof useSetUserPreferencesMutation>;
export type SetUserPreferencesMutationResult = Apollo.MutationResult<SetUserPreferencesMutation>;
export type SetUserPreferencesMutationOptions = Apollo.BaseMutationOptions<SetUserPreferencesMutation, SetUserPreferencesMutationVariables>;
export const SetUserPreferencesBusinessAccessKeyDocument = gql`
    mutation SetUserPreferencesBusinessAccessKey($uid: String!, $accessKey: String!) {
  setUserPreferencesBusinessAccessKey(uid: $uid, accessKey: $accessKey) {
    firstWeekDay
    timeFormat
    numberFormat
    businessAccessKey
  }
}
    `;
export type SetUserPreferencesBusinessAccessKeyMutationFn = Apollo.MutationFunction<SetUserPreferencesBusinessAccessKeyMutation, SetUserPreferencesBusinessAccessKeyMutationVariables>;

/**
 * __useSetUserPreferencesBusinessAccessKeyMutation__
 *
 * To run a mutation, you first call `useSetUserPreferencesBusinessAccessKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetUserPreferencesBusinessAccessKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setUserPreferencesBusinessAccessKeyMutation, { data, loading, error }] = useSetUserPreferencesBusinessAccessKeyMutation({
 *   variables: {
 *      uid: // value for 'uid'
 *      accessKey: // value for 'accessKey'
 *   },
 * });
 */
export function useSetUserPreferencesBusinessAccessKeyMutation(baseOptions?: Apollo.MutationHookOptions<SetUserPreferencesBusinessAccessKeyMutation, SetUserPreferencesBusinessAccessKeyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetUserPreferencesBusinessAccessKeyMutation, SetUserPreferencesBusinessAccessKeyMutationVariables>(SetUserPreferencesBusinessAccessKeyDocument, options);
      }
export type SetUserPreferencesBusinessAccessKeyMutationHookResult = ReturnType<typeof useSetUserPreferencesBusinessAccessKeyMutation>;
export type SetUserPreferencesBusinessAccessKeyMutationResult = Apollo.MutationResult<SetUserPreferencesBusinessAccessKeyMutation>;
export type SetUserPreferencesBusinessAccessKeyMutationOptions = Apollo.BaseMutationOptions<SetUserPreferencesBusinessAccessKeyMutation, SetUserPreferencesBusinessAccessKeyMutationVariables>;