import { useSelector } from "react-redux";
import { HealthType } from "../../../../../entities/api/HealthType";
import { useSingleChartNumberValue, useSingleChartValue } from "../../../../../hooks/UseSingleChartValue";
import { HealthDataCollection } from "../../../../../models/datasources/HERemoteApiService"
import { RootState } from "../../../../../Store";
import { DashboardComponentConfigurationValue } from "../../../../../generated/graphql";
import { useGaugeChartConfiguration } from "../../../../../hooks/charts/UseGaugeChartConfiguration";
import ReactTooltip from "react-tooltip";
import { formatNumber } from "../../../../../models/UserUtils";
import { useIsDarkMode } from "../../../../../hooks/UseIsDarkmode";
import './GaugeChart.css'

export interface GaugeChartProps {
    dataType: HealthType | null,
    data: HealthDataCollection[],
    configuration: DashboardComponentConfigurationValue[],
}

export const GaugeChart: React.FC<GaugeChartProps> = (props: GaugeChartProps) => {
    
    const isDarkMode = useIsDarkMode();
    const numberFormat = useSelector((state: RootState) => state.preferencesReducer.preferences).numberFormat;
    const stringValue = useSingleChartValue(props.data, numberFormat, props.dataType);
    const numberValue = useSingleChartNumberValue(props.data, props.dataType);
    const chartPercentage = useGaugeChartConfiguration(numberValue, props.configuration);

    const chartDegRotation = 180 + 180 * chartPercentage.percentage;
    const formattedPercentageString = `${formatNumber(chartPercentage.unboundPercentage*100, 2, numberFormat)}%`
    const backgroundColor = isDarkMode ? '#171717' : '#ffffff';

    return (
        <div className="gaugeChart">
            <ReactTooltip effect="solid" />
            <div className="gaugeAspectRatioFixer">
                <svg viewBox="0 0 2 1" />
            </div>
            <div className="gaugeWrapper">
                <div className="gaugeSvgClip" data-offset="{'top': -15}" data-tip={formattedPercentageString}>
                    <svg width="100%" viewBox="0 0 100 35">
                        <circle 
                                cx="50" cy="35" r="25" strokeWidth="10"
                                stroke="#43a04770" 
                                fill={backgroundColor}
                            />
                    </svg>
                    <svg width="100%" viewBox="0 0 100 35" style={{ transform: `translateY(50%) rotate(${chartDegRotation}deg) translateY(-50%)` }}>
                        <circle 
                                cx="50" cy="35" r="25" strokeWidth="10"
                                stroke="#43a047" 
                                fill={backgroundColor}
                            />
                    </svg>
                    <svg width="100%" viewBox="0 0 100 50" style={{ zIndex: 2 }}>
                        <rect width="100" height="15" y="35" fill={backgroundColor} />
                    </svg>
                </div>
                <div className="gauseTitleWrapper">
                    <div className="titleCenterBox">
                        <p className="gaugeValueLabel">{stringValue}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}