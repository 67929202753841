import gql from "graphql-tag";
import { dashboardWithComponents } from "../../entities/gql_fragments/DashboardWithComponents";

export const putDashboardComponents = gql`
    ${dashboardWithComponents}
    mutation PutDashboardComponents($uid: String!, $dashboardUuid: String!, $components: [DashboardComponentInput!]!) {
        putDashboardComponents(uid: $uid, dashboardUuid: $dashboardUuid, components: $components) {
            ...DashboardWithComponents
        }
    }
`;