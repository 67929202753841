import { SleepDay } from "../entities/sleep/SleepDay";
import { SleepInterval } from "../entities/sleep/SleepInterval";
import { SleepStage } from "../entities/sleep/SleepStage";

export const SleepUtils = {
    makeSleepingHours(day: SleepDay): number {
        if (day.hoursCore > 0 || day.hoursDeep > 0 || day.hoursREM > 0) {
            return day.hoursCore + day.hoursDeep + day.hoursREM;
        } else {
            return day.hoursAsleep;
        }
    },
    makeSleepingIntervals(originalIntervals: SleepInterval[]): SleepInterval[] {
        const asleepIntervals = originalIntervals.filter(x => x.stage === SleepStage.asleep);
        if (asleepIntervals.length > 0) {
            return asleepIntervals;
        }
    
        let sleepingIntervals: SleepInterval[] = [];
        originalIntervals
            .filter(x => 
                x.stage === SleepStage.core ||
                x.stage === SleepStage.deep ||
                x.stage === SleepStage.rem
            )
            .forEach(x => {
                sleepingIntervals.push({
                    from: x.from,
                    to: x.to,
                    stage: SleepStage.asleep,
                })
            })
        let index = 0;
        while(sleepingIntervals.length > index) {
            while(true) {
                // eslint-disable-next-line
                const leadingConflictIndex = sleepingIntervals.findIndex(x => x.to > sleepingIntervals[index].from && x.from < sleepingIntervals[index].from);
                if (leadingConflictIndex !== -1) {
                    sleepingIntervals[index].from = sleepingIntervals[leadingConflictIndex].from;
                    sleepingIntervals.splice(leadingConflictIndex, 1);
                    continue;
                }
                // eslint-disable-next-line
                const tailingConflictIndex = sleepingIntervals.findIndex(x => x.to > sleepingIntervals[index].to && x.from < sleepingIntervals[index].to);
                if (tailingConflictIndex !== -1) {
                    sleepingIntervals[index].to = sleepingIntervals[tailingConflictIndex].to;
                    sleepingIntervals.splice(tailingConflictIndex, 1);
                    continue;
                }
                break;
            }
            index += 1;
        }
        sleepingIntervals.sort((a,b) => (a.from > b.from) ? 1 : ((b.from > a.from) ? -1 : 0))
        return sleepingIntervals;
    },
}