import { HealthTypes } from "../entities/api/HealthTypes";
import { DashboardComponent, DashboardWithoutComponentsFragment } from "../generated/graphql";
import { ComponentType, DashboardComponentInput } from "../generated/graphql";
import { AggregationPeriod } from "./AggregationPeriod";
import { ChartType } from "./ChartType";
import { DatePreset, presetsWithAggregation } from "./DatePreset";
import { HealthDataAggregator } from "./HealthDataAggregator";

export const DashboardUtils = {
    transfromComponentsToMutationInput(components: DashboardComponent[]): DashboardComponentInput[] {
        return components.map(component => ({
            componentUuid: component.componentUuid,
            dataTypeIds: component.dataTypeIds,
            type: component.type,
            configuration: (component.configuration ?? []).map(configuration => ({
                key: configuration.key,
                value: configuration.value,
            })),
        }));
    },
    componentTypeName(type: ComponentType): string {
        switch (type) {
            case ComponentType.BarOrLineChart:
                return 'Classic chart (bar or line)';
            case ComponentType.StackedBarLineChart:
                return 'Stack bar and line chart';
            case ComponentType.TotalValue:
                return 'Total value';
            case ComponentType.Table:
                return 'Table';
            case ComponentType.Gauge:
                return 'Gauge';
        }
    },
    chartTypeForComponent(component: DashboardComponent, datatypes: HealthTypes, aggregationPeriod: AggregationPeriod | null): ChartType {
        
        switch (component.type) {

            case ComponentType.BarOrLineChart:
                // Force line chart for HR types
                const hrTypeIds = [52,53,54,55,6,7,8];
                if (component.dataTypeIds.length === 1 && hrTypeIds.includes(component.dataTypeIds[0])) {
                    return ChartType.LINE;
                }

                // Aggregated types use bar chart, record based types line chart
                if (component.dataTypeIds.length === 1 && HealthDataAggregator.isTypeIdAggregated(component.dataTypeIds[0], datatypes)) {
                    return ChartType.BAR;
                } else {
                    return ChartType.LINE;
                }

            case ComponentType.StackedBarLineChart:
                return ChartType.STACKED_BAR_LINE;

            case ComponentType.TotalValue:
                return ChartType.TOTAL_VALUE;

            case ComponentType.Table:
                return ChartType.TABLE

            case ComponentType.Gauge:
                return ChartType.GAUGE;
        }
    },
    dashboardDatePreset(dashboard: DashboardWithoutComponentsFragment): DatePreset {
        const currentPresetId = dashboard.defaultDatePreset;
        const fallbackPreset = DashboardUtils.allDashboardDefaultDatePresets().find(preset => preset.id === 3)!;
        if (currentPresetId !== undefined) {
            const datePreset = DashboardUtils.allDashboardDefaultDatePresets().find(preset => preset.id === currentPresetId);
            if (datePreset) {
                return datePreset;
            }
        }
        return fallbackPreset;
    },
    allDashboardDefaultDatePresets(): DatePreset[] {
        return presetsWithAggregation;
    }
}