import axios from "axios";
import { HealthRecord } from "../../entities/HealthRecord";
import { Account } from "../../reducers/AccountReducer";
import { AggregationPeriod } from "../AggregationPeriod";
import { HealthDataAggregator } from "../HealthDataAggregator";
import { WorkoutChartValue } from "../WorkoutChartValue";
import { healthTypeIdentifiers } from "./HealthTypeIdentifier";
import { HealthTypeRepository } from "./HealthTypeRepository";
import { HERemoteApiDecoder } from "./HERemoteApiDecoder";
import { WorkoutActivityAdapter } from "./WorkoutActivityAdapter";
import { WorkoutValueAdapter } from "./WorkoutValueAdapter";

type HealthDataResponse = {
    type: number,
    data: HealthDataWrapper[],
}

type HealthDataWrapper = {
    records: HealthDataValue[],
    units: string
}

type HealthDataValue = {
    time: string,
    cipher: string,
    nonce: string,
}

type HealthDataDecryptedResponse = {
    type: number,
    data: HealthDataDecryptedWrapper[],
}

type HealthDataDecryptedWrapper = {
    records: HealthDataDecryptedValue[],
    units: string
}

type HealthDataDecryptedValue = {
    time: string,
    value: string | number,
}

export type HealthDataCollection = {
    units: string,
    records: HealthRecord[],
}

export const HERemoteApiService = {
    async getData(account: Account, categoryId: number, from: Date, to: Date): Promise<HealthDataCollection[]> {
        if (account.directAccess) {
            const queryParams = {
                type: [categoryId],
                uid: account.directAccess.uid,
                dateFrom: from.toISOString(),
                dateTo: to.toISOString(),
            }
            const response = await axios.get<HealthDataResponse[]>(`${process.env.REACT_APP_BE_URL}healthdata/encrypted`, { params: queryParams });
            const typeResponseData = response.data.filter(x => x.type === categoryId);
            if (typeResponseData.length === 1) {
                return typeResponseData[0].data
                    .map(collection => {
                        const rawRecords = collection.records.map(record => ({
                            time: record.time,
                            value: HERemoteApiDecoder.decodeChacha(account.directAccess?.encryptionKey ?? '', record.nonce, record.cipher),
                        }))
                        return {
                            units: collection.units,
                            records: convertValuesToHumanRedableVersion(categoryId, rawRecords),
                        }
                    });
            } else {
                return [];
            }
        } else if (account.businessAccess) {
            const queryParams = {
                type: [categoryId],
                accessKey: account.businessAccess.accessKey,
                licenseKey: account.businessAccess.licenseKey,
                dateFrom: from.toISOString(),
                dateTo: to.toISOString(),
            }
            const response = await axios.get<HealthDataDecryptedResponse[]>(`${process.env.REACT_APP_BE_URL}healthdata/business-access-key`, { params: queryParams });
            
            if (response.data.length >= 1) {
                return response.data[0].data
                    .map(collection => {
                        return {
                            units: collection.units,
                            records: collection.records,
                        }
                    });
            } else {
                return [];
            }
        } else {
            console.error('Active account doesn\'t have direct either business decryption information.')
            return [];
        }
    },
    async getAggregatedData(account: Account, categoryId: number, from: Date, to: Date, aggregationPeriod: AggregationPeriod): Promise<HealthDataCollection[]> {
        const types = HealthTypeRepository.getTypes();
        const data = await HERemoteApiService.getData(account, categoryId, from, to);
        const aggregatedType = types.aggregated
            .flatMap(x => x.types)
            .find(x => x.id === categoryId);
        if (aggregatedType) {
            return data
                .map(dataCollection => HealthDataAggregator.aggregate(aggregatedType, dataCollection, from, to, aggregationPeriod));
        } else {
            return data;
        }
    },
    async getWorkoutValueData(account: Account, workoutValue: WorkoutChartValue, workoutActivity: string | null, from: Date, to: Date, aggregationPeriod: AggregationPeriod): Promise<HealthDataCollection[]> {
        const data = await HERemoteApiService.getData(account, healthTypeIdentifiers.workouts, from, to);
        const filteredWorkouts = WorkoutActivityAdapter.adaptWorkouts(workoutActivity ?? '', data);
        const adaptedData = WorkoutValueAdapter.adaptDataCollections(workoutValue, filteredWorkouts);
        if (adaptedData) {
            return adaptedData
                .map(dataCollection => HealthDataAggregator.aggregate(HealthTypeRepository.getTypes().workouts!, dataCollection, from, to, aggregationPeriod));
        } else {
            return [];
        }
    }
};

function convertValuesToHumanRedableVersion(typeId: number, records: HealthRecord[]): HealthRecord[] {
    let multiplier: number | undefined = undefined;
    if (typeId === 1) { // Body fat
        multiplier = 100
    } else if (typeId === 50) { // Oxygen saturation
        multiplier = 100
    }
    if (multiplier) {
        records.forEach(record => {
            if (typeof record.value === 'number') {
                record.value = (record.value * multiplier!).toFixed(3)
            } else {
                const parsedNumber = parseFloat(record.value);
                if (parsedNumber) {
                    record.value = (parsedNumber * multiplier!).toFixed(3)
                }
            }
        });
    }
    return records;
}