import gql from "graphql-tag";
import { dashboardWithoutComponents } from "../../entities/gql_fragments/DashboardWithoutComponents";

export const createDashboardMutation = gql`
${dashboardWithoutComponents}
mutation CreateDashboard($name: String!, $uid: String!) {
    createDashboard(name: $name, uid: $uid) {
        ...DashboardWithoutComponents
    }
}
`;