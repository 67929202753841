import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { HealthType } from "../entities/api/HealthType";
import { AggregationPeriod } from "../models/AggregationPeriod";
import { healthTypeIdentifiers } from "../models/datasources/HealthTypeIdentifier";
import { HERemoteApiService, HealthDataCollection } from "../models/datasources/HERemoteApiService";
import { WorkoutChartValue } from "../models/WorkoutChartValue";
import { RootState } from "../Store";
import { DateInterval } from "./UseDateInterval";

export enum UseHealthDataStatus {
    loading,
    error,
    data,
}

export type UseHealthDataState = {
    status: UseHealthDataStatus,
    data: HealthDataCollection[] | null,
    error: Error | null,
};

export function useHealthData(dataType: HealthType | null, aggregationPeriod: AggregationPeriod | null, workoutValue: WorkoutChartValue | null, workoutActivity: string | null, dateInterval: DateInterval): UseHealthDataState {
    const [status, setStatus] = useState(UseHealthDataStatus.loading);
    const [data, setData] = useState(null as HealthDataCollection[] | null);
    const [error, setError] = useState(null as Error | null);
    const account = useSelector((state: RootState) => state.accountReducer?.activeAccount);

    const isInvalidWorkoutConfiguration = dataType && dataType.id === healthTypeIdentifiers.workouts && !workoutValue;

    useEffect(() => {
        if (account && dataType && !isInvalidWorkoutConfiguration) {
            setStatus(UseHealthDataStatus.loading);
            let apiServicePromise: Promise<HealthDataCollection[]>;
            if (dataType && dataType.id === healthTypeIdentifiers.workouts && workoutValue) {
                apiServicePromise = HERemoteApiService.getWorkoutValueData(account, workoutValue, workoutActivity, dateInterval.from, dateInterval.to, aggregationPeriod ?? AggregationPeriod.hours);
            } else if (aggregationPeriod) {
                apiServicePromise = HERemoteApiService.getAggregatedData(account, dataType.id, dateInterval.from, dateInterval.to, aggregationPeriod);
            } else {
                apiServicePromise = HERemoteApiService.getData(account, dataType.id, dateInterval.from, dateInterval.to);
            }
            apiServicePromise
                .catch(error => {
                    setError(error);
                    setStatus(UseHealthDataStatus.error);
                })
                .then(data => {
                    setData(data as HealthDataCollection[]);
                    setStatus(UseHealthDataStatus.data);
                });
        }
    }, [account, aggregationPeriod, dataType, dataType?.id, dateInterval.from, dateInterval.to, isInvalidWorkoutConfiguration, workoutValue, workoutActivity]);

    return {
        status: status,
        data: data,
        error: error,
    };
}