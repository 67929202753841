import { DatePresetPicker } from "../../core/DatePresetPicker";
import Flatpickr from "react-flatpickr";
import { DateIntervalData } from "../../../hooks/UseDateInterval";
import { DatePreset, presetsWithoutAggregation } from "../../../models/DatePreset";
import { DateUtils } from "../../../models/DateUtils";
import { FirstWeekDay } from "../../../generated/graphql";
import { useSelector } from "react-redux";
import { RootState } from "../../../Store";

export interface NutritionHistoryHeaderProps {
    dateInterval: DateIntervalData,
}

export const NutritionHistoryHeader: React.FC<NutritionHistoryHeaderProps> = (props: NutritionHistoryHeaderProps) => {

    const weekStartPreference = useSelector((state: RootState) => state.preferencesReducer.preferences.firstWeekDay);

    function onPresetSelected(datePreset: DatePreset) {
        props.dateInterval.setInterval({
            from: datePreset.dateFrom,
            to: datePreset.dateTo,
        })
    }

    return (
        <div className="container-fluid row workoutFiltrationRow">
            <p>Dates: </p>
            <Flatpickr
                className="workoutsDatePicker"
                value={[props.dateInterval.interval.from, props.dateInterval.interval.to]}
                options={{
                    mode: 'range',
                    locale: {
                        firstDayOfWeek: weekStartPreference === FirstWeekDay.Sunday ? 0 : 1,
                    },
                }}
                onChange={dates => {
                    if (dates.length === 2 && dates[0] && dates[1]) {
                        props.dateInterval.setInterval({
                            from: dates[0],
                            to: DateUtils.setTimeToSecondBeforeMidnight(dates[1]),
                        })
                    }
                }}
            />
            <DatePresetPicker
                    presets={presetsWithoutAggregation}
                    onPresetSelected={onPresetSelected}
                />
            <div style={{ marginLeft: 'auto' }}></div>
        </div>
    )
}