import { fuzzySearch, SelectSearchOption } from "react-select-search";
import { DashboardWithComponentsFragment } from "../../../generated/graphql"
import { DashboardUtils } from "../../../models/DashboardUtils";
import { DatePreset } from "../../../models/DatePreset"
import { HESelectSearch } from "../../core/HESelectSearch/HESelectSearch"
import "./DashboardDefaultTimePeriodPicker.css"

export interface DashboardDefaultTimePeriodPickerProps {
    dashboard: DashboardWithComponentsFragment,
    setDefaultDatePreset: (datePreset: DatePreset) => void,
}

export const DashboardDefaultTimePeriodPicker: React.FC<DashboardDefaultTimePeriodPickerProps> = (props: DashboardDefaultTimePeriodPickerProps) => {

    const options = makeDefaultTimePeriodOptions();

    function datePresetChanged(option: SelectSearchOption | SelectSearchOption[]) {
        // @ts-expect-error
        const selectedPresetId = option as string;
        const datePreset = DashboardUtils.allDashboardDefaultDatePresets().find(preset => `${preset.id}` === selectedPresetId);
        if ((!props.dashboard.defaultDatePreset || selectedPresetId !== `${props.dashboard.defaultDatePreset}`) && datePreset) {
            props.setDefaultDatePreset(datePreset);
        }
    }

    return (
        <div className="defaultDashboardDatePresetWrapper">
            <p>Default date preset:</p>
            <HESelectSearch
                    options={options}
                    search
                    filterOptions={fuzzySearch}
                    emptyMessage="Not found"
                    placeholder="Select default date preset"
                    onChange={datePresetChanged}
                    value={`${DashboardUtils.dashboardDatePreset(props.dashboard).id}`}
                />
        </div>
    )
}

function makeDefaultTimePeriodOptions(): SelectSearchOption[] {
    return DashboardUtils.allDashboardDefaultDatePresets().map(preset => ({
        name: preset.title,
        value: `${preset.id}`,
    }))
}