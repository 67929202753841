import gql from "graphql-tag";

export const addConnectedAccountMutation = gql`
mutation AddConnectedAccount($uid: String!, $connectedAccount: ConnectedAccountInput!) {
    putConnectedAccount(uid: $uid, connectedAccount: $connectedAccount) {
        connectedAccountUuid
        name
        accountKey
    }
}
`;