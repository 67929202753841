import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { BusinessLoginPage, LoginPage } from './components/pages/login/LoginPage';
import { DashboardPage } from './components/pages/dashboard/DashboardPage';
import { WorkoutsPage } from './components/pages/workouts/WorkoutsPage';
import { SettingsPage } from './components/pages/settings/SettingsPage';
import { UserPreferencesProvider } from './components/core/UserPreferencesProvider';
import { CsvExportPage } from './components/pages/csv/CsvExportPage';
import { DataSharingSettingsPage } from './components/pages/datasharing/DataSharingSettingsPage';
import { ConnectedAccountsProvider } from './components/core/ConnectedAccountsProvider';
import { useSelector } from 'react-redux';
import { RootState } from './Store';
import { DashboardSettingsPage } from './components/pages/dashboard_settings/DashboardSettingsPage';
import { DashboardSettingsDetailPage } from './components/pages/dashboard_settings_detail/DashboardSettingsDetailPage';
import { NutritionPage } from './components/pages/nutrition/NutritionPage';
import { UidPage } from './components/pages/uid/UidPage';
import { ToastProvider } from 'react-toast-notifications';
import { SleepPage } from './components/pages/sleep/SleepPage';
import { SettingsBusinessPage } from './components/pages/settings_business/SettingsBusinessPage';

function App() {
  const userIsSignedIn = useSelector((state: RootState) => state.accountReducer !== null);

  return (
    <>
      <Router>
          { !userIsSignedIn
            ? <Switch>
                <Route path="/login/sub/:sub" component={LoginPage} />
                <Route path="/login" component={LoginPage} />
                <Route path="/business" component={BusinessLoginPage} />
                <Route component={LoginPage} />
              </Switch>
            : <UserPreferencesProvider>
                <ConnectedAccountsProvider>
                  <ToastProvider>
                    <Switch>
                      <Route exact path="/uid" component={UidPage} />
                      <Route exact path="/dashboard" component={DashboardPage} />
                      <Route exact path="/dashboard/settings" component={DashboardSettingsPage} />
                      <Route exact path="/dashboard/settings/:dashboardUuid" component={DashboardSettingsDetailPage} />
                      <Route exact path="/export" component={CsvExportPage} />
                      <Route exact path="/workouts" component={WorkoutsPage} />  
                      <Route exact path="/nutrition" component={NutritionPage} />
                      <Route exact path="/sleep" component={SleepPage} />
                      <Route exact path="/settings" component={SettingsPage} />
                      <Route exact path="/settings/sharing" component={DataSharingSettingsPage} />
                      <Route exact path="/settings/business" component={SettingsBusinessPage} />
                      <Route exact path="/">
                        <Redirect to="/dashboard" />
                      </Route>
                      <Redirect to='/' />
                    </Switch>
                  </ToastProvider>
                </ConnectedAccountsProvider>
              </UserPreferencesProvider>
          }
      </Router>
    </>
  );
}

export default App;
