import {
  AES,
  lib as CryptoJSLib,
  enc as CryptoJSEnc,
 } from "crypto-js";

var JsonFormatter = {
  stringify: function (cipherParams: CryptoJSLib.CipherParams) {
    var jsonObj: any = { ct: cipherParams.ciphertext.toString(CryptoJSEnc.Base64) };
    if (cipherParams.iv) {
      jsonObj.iv = cipherParams.iv.toString();
    }
    if (cipherParams.salt) {
      jsonObj.s = cipherParams.salt.toString();
    }
    return JSON.stringify(jsonObj);
  },
  parse: function (jsonStr: string) {
    var jsonObj = JSON.parse(jsonStr);
    var cipherParams = CryptoJSLib.CipherParams.create({
      ciphertext: CryptoJSEnc.Base64.parse(jsonObj.ct)
    });
    if (jsonObj.iv) {
      cipherParams.iv = CryptoJSEnc.Hex.parse(jsonObj.iv);
    }

    if (jsonObj.s) {
      cipherParams.salt = CryptoJSEnc.Hex.parse(jsonObj.s);
    }
    return cipherParams;
  }
};

export const ConnectedAccountCrypto = {
  encrypt(accountKey: string, encryptionKey: string): string {
    return AES.encrypt(accountKey, encryptionKey, { format: JsonFormatter }).toString();
  },
  dencrypt(encryptedAccountKey: string, encryptionKey: string): string {
    return AES.decrypt(encryptedAccountKey, encryptionKey, { format: JsonFormatter }).toString(CryptoJSEnc.Utf8);
  }
};