import { Chart, ChartData } from "chart.js";
import { Line } from "react-chartjs-2";
import { useSelector } from "react-redux";
import { DateIntervalData } from "../../../hooks/UseDateInterval"
import { HealthDataCollection } from "../../../models/datasources/HERemoteApiService"
import { RootState } from "../../../Store";
import { ChartUtils } from "../dashboard/content/charts/ChartUtils";
import { DateUtils } from "../../../models/DateUtils"
import { useState } from "react";
import "./NutritionHistory.css";
import { NutritionHistoryHeader } from "./NutritionHistoryHeader";

export interface NutritionHistoryProps {
    dateInterval: DateIntervalData,
    protein: HealthDataCollection | null,
    fats: HealthDataCollection | null,
    carbohydrates: HealthDataCollection | null,
}

export const NutritionHistory: React.FC<NutritionHistoryProps> = (props: NutritionHistoryProps) => {
    
    const preferences = useSelector((state: RootState) => state).preferencesReducer.preferences;
    const chartData = makeChartData(props);
    const units = props.protein?.units ?? props.fats?.units ?? props.carbohydrates?.units ?? '';
    const isEmpty = isDatasetEmpty(props);

    const [aspectRatio, setAspectRatio] = useState(3);

    return (
        <>
            <NutritionHistoryHeader
                dateInterval={props.dateInterval}
            />
            { isEmpty &&
                <div className="nutritionHistoryEmptyState">
                    <p>No data available</p>
                </div>
            }
            { !isEmpty &&
                <Line
                    data={chartData}
                    options={{
                        plugins: {
                            legend: {
                                display: false,
                            },
                            tooltip: {
                                callbacks: {
                                    label: (data: any) => ChartUtils.tooltipCallback(data, preferences.numberFormat, units),
                                }
                            }
                        },
                        aspectRatio: aspectRatio,
                        resizeDelay: 200,
                        onResize: (_: Chart, size: { width: number; height: number }) => {
                            let newAspectRatio: number;
                            if (size.width <= 400) {
                                newAspectRatio = 1;
                            } else if (size.width <= 1100) {
                                newAspectRatio = 2;
                            } else if (size.width <= 1400) {
                                newAspectRatio = 3;
                            } else {
                                newAspectRatio = 4;
                            }
                            if (aspectRatio !== newAspectRatio) {
                                setAspectRatio(newAspectRatio);
                            }
                        },
                        scales: {
                            xAxis: {
                                type: 'time',
                                time: {
                                    unit: 'day',
                                    displayFormats: {
                                        second: ChartUtils.makeDateFormatSeconds(preferences.timeFormat),
                                        minute: ChartUtils.makeDateFormatMinutesAndHours(preferences.timeFormat),
                                        hour: ChartUtils.makeDateFormatMinutesAndHours(preferences.timeFormat),
                                    }
                                }
                            },
                        },
                    }}
                />
            }
        </>
    );
}

function isDatasetEmpty(props: NutritionHistoryProps): boolean {
    return (props.protein?.records.length ?? 0) === 0
        && (props.fats?.records.length ?? 0) === 0
        && (props.carbohydrates?.records.length ?? 0) === 0;
}

function makeChartData(props: NutritionHistoryProps): ChartData<"line", (number | null)[], unknown> {

    const timelineDates = Array.from(
        new Set([
            ...props.protein?.records?.map(x => new Date(x.time).getTime()) ?? [],
            ...props.fats?.records?.map(x => new Date(x.time).getTime()) ?? [],
            ...props.carbohydrates?.records?.map(x => new Date(x.time).getTime()) ?? [],
            DateUtils.removeTimeFromDate(props.dateInterval.interval.to),
        ])
    )
    .sort()
    .map(time => new Date(time));

    return {
        labels: [
            props.dateInterval.interval.from,
            ...timelineDates,
        ],
        datasets: [
            makeDataSet(
                'Protein',
                '#35C759',
                props.protein,
            ),
            makeDataSet(
                'Fats',
                '#2893FF',
                props.fats,
            ),
            makeDataSet(
                'Carbohydrates',
                '#FFCC02',
                props.carbohydrates,
            ),
            {
                label: 'Hidden data',
                hidden: true,
                data: [
                    0,
                    ...timelineDates.map(_ => 0),
                ],
            },
        ],
    }
}

function makeDataSet(name: string, color: string, collection: HealthDataCollection | null) {
    return {
        label: name,
        backgroundColor: '#00000025',
        borderColor: color,
        fill: true,
        pointBackgroundColor: color,
        data: [
            null,
            ...dataCollectionToValues(collection),
            null
        ]
    }
}

function dataCollectionToValues(collection: HealthDataCollection | null): number[] {
    if (!collection) {
        return [];
    } else {
        return collection.records.map(record => {
            if (typeof record.value === 'number') {
                return record.value;
            } else {
                return parseFloat(record.value);
            }
        });
    }
}