import moment from "moment";
import { useSelector } from "react-redux";
import { Workout } from "../../../entities/api/Workout";
import { TimeFormat } from "../../../generated/graphql";
import { RootState } from "../../../Store";
import { WorkoutListTotalStats } from "./total_stats/WorkoutListTotalStats"
import { WorkoutListTotalStatsItemProps } from "./total_stats/WorkoutListTotalStatsItem";
import { iconForWorkoutMetadata } from "./WorkoutMetadataIcon";
import { workoutDates } from "./WorkoutUtils";
import humanizeDuration from "humanize-duration";
import { formatNumber } from "../../../models/UserUtils";
import { Doughnut } from 'react-chartjs-2';
import { ChartData } from 'chart.js'
import { useIsDarkMode } from "../../../hooks/UseIsDarkmode";
import { useChartLabelColor } from "../../../hooks/UseChartLabelColor";

export interface WorkoutsListProps {
    workouts: Workout[]
}

function shouldDisplayWorkoutMetadata(metadataName: string): boolean {
    if (metadataName.indexOf('Heart rate zone') !== -1)  {
        return false;
    } else {
        const hiddenMetadataNames = [
            'Activity',
            'Duration',
            'Workout date',
        ]
        return hiddenMetadataNames.indexOf(metadataName) === -1
    }
}

function formatWorkoutDates(workout: Workout, preferredTimeFormat: TimeFormat): string {
    const dates = workoutDates(workout);
    if (dates[0] !== null && dates[1] === null) {
        let timeFormat;
        switch (preferredTimeFormat) {
            case TimeFormat.Format12h:
                timeFormat = 'MMM D YYYY h:mm:ss a';
                break;
            case TimeFormat.Format24h:
                timeFormat = 'MMM D YYYY HH:mm:ss';
                break;
        }
        return moment(dates[0]).format(timeFormat);
    } else if (dates[0] !== null && dates[1] !== null) {
        const startMoment = moment(dates[0]);
        const endMoment = moment(dates[1]);
        let timeFormatStart;
        let timeFormatEnd;
        switch (preferredTimeFormat) {
            case TimeFormat.Format12h:
                timeFormatStart = 'MMM D YYYY h:mm a';
                break;
            case TimeFormat.Format24h:
                timeFormatStart = 'MMM D YYYY HH:mm';
                break;
        }
        if (startMoment.isSame(endMoment, 'day')) {
            switch (preferredTimeFormat) {
                case TimeFormat.Format12h:
                    timeFormatEnd = 'h:mm a';
                    break;
                case TimeFormat.Format24h:
                    timeFormatEnd = 'HH:mm';
                    break;
            }
        } else {
            timeFormatEnd = timeFormatStart
        }
        return startMoment.format(timeFormatStart) + ' - ' + endMoment.format(timeFormatEnd);
    }
    return "-";
}

function makeTotalStatistics(workouts: Workout[]): WorkoutListTotalStatsItemProps[] {
    let totalEnergyBurned = 0;
    let totalDistance = 0;
    let totalDurationSeconds = 0;
    workouts.forEach(workout => {
        const dates = workoutDates(workout);
        if (dates.indexOf(null) === -1) {
            totalDurationSeconds += moment(dates[1]).diff(moment(dates[0]), "seconds");
        }
        let burnedEnergyMetadata = workout.values.find(metadata => metadata.name === 'Active energy burned')
        if (burnedEnergyMetadata) {
            totalEnergyBurned += parseFloat(burnedEnergyMetadata.value);
        }
        let distanceMetadata = workout.values.find(metadata => metadata.name === 'Distance')
        if (distanceMetadata) {
            totalDistance += parseFloat(distanceMetadata.value);
        }
    })
    return [
        {
            dataTypeName: 'Active energy burned',
            value: totalEnergyBurned,
            ...iconForWorkoutMetadata('Active energy burned'),
        },
        {
            dataTypeName: 'Distance',
            value: totalDistance,
            ...iconForWorkoutMetadata('Distance'),
        },
        {
            dataTypeName: 'Duration',
            value: totalDurationSeconds,
            valueFormatted: humanizeDuration(totalDurationSeconds * 1000, { language: 'en', largest: 3 }),
            ...iconForWorkoutMetadata('Duration'),
        }
    ];
}

export const WorkoutList: React.FC<WorkoutsListProps> = (props: WorkoutsListProps) => {
    const { preferencesReducer } = useSelector((state: RootState) => state);
    const preferences = preferencesReducer.preferences;
    const isDarkMode = useIsDarkMode();
    const labelColor = useChartLabelColor();
    return (
        <div id="workoutsList">
            { props.workouts.length > 1
                ?   (<>
                        <WorkoutListTotalStats items={makeTotalStatistics(props.workouts)} />
                        <hr className="workoutRowSeparator" />
                    </>)
                :   (<></>)
            }
            { props.workouts.map(workout => {
                const chartData = makeChartData(workout);
                return (
                    <div className="row workoutRow" key={workout.time.toISOString()}>
                        <p className="workoutTypeLabel col-12">{workout.values.find(x => x.name === 'Activity')?.value ?? '-'}</p>
                        <p className="workoutDateLabel col-12">{formatWorkoutDates(workout, preferences.timeFormat)}</p>
                        <div className="col-xl-6 workoutMetaInfosDivs">
                            { workout
                                .values
                                .filter(metadata => shouldDisplayWorkoutMetadata(metadata.name))
                                .map( metadata => (
                                        <div key={metadata.name} className="workoutMetaInfo">
                                            <img
                                                alt={metadata.name}
                                                src={iconForWorkoutMetadata(metadata.name).iconUrl}
                                                className={iconForWorkoutMetadata(metadata.name).invertedInDarkMode ? 'invertedImageInDarkMode' : ''}
                                                />
                                            <div>
                                                <p className="workoutMetaInfoLabel">{metadata.name}</p>
                                                <p className="workoutMetaValueLabel">{
                                                    formatNumber(
                                                        parseFloat(metadata.value),
                                                        3,
                                                        preferences.numberFormat
                                                    )
                                                } {metadata.units}</p>
                                            </div>
                                        </div>
                                    ))
                            }
                        </div>
                        <div className="col-xl-6 d-none d-md-block workoutChartWrapper">
                            { chartData &&
                                <div style={{ height: '300px' }}>
                                    <Doughnut
                                        data={chartData}
                                        height={300}
                                        options={{
                                            elements: {
                                                arc: {
                                                    borderColor: isDarkMode ? '#171717' : '#ffffff',
                                                },
                                            },
                                            maintainAspectRatio: false,
                                            plugins: {
                                                legend: {
                                                    position: 'right',
                                                    labels: {
                                                        color: labelColor,
                                                    }
                                                },
                                                tooltip: {
                                                    callbacks: {
                                                        // @ts-ignore
                                                        label: (data: any) => {
                                                            const type = data.label as string
                                                            const value = data.parsed as number
                                                            const formatedValue = formatNumber(
                                                                value,
                                                                1,
                                                                preferences.numberFormat
                                                            )
                                                            return `${type}: ${formatedValue} %`;
                                                        },
                                                    }
                                                }
                                            },
                                        }}
                                    />
                                </div>
                            }
                        </div>
                    </div>
                )
            })
            }
        </div>
    )
}

function makeChartData(workout: Workout): ChartData<"doughnut", (number | null)[], unknown> | null {
    const zoneA = workout.values.find(value => value.name.indexOf('A Easy') !== -1)?.value;
    const zoneB = workout.values.find(value => value.name.indexOf('B Fat Burn') !== -1)?.value;
    const zoneC = workout.values.find(value => value.name.indexOf('C Moderate Training') !== -1)?.value;
    const zoneD = workout.values.find(value => value.name.indexOf('D Hard Training') !== -1)?.value;
    const zoneE = workout.values.find(value => value.name.indexOf('E Extreme Training') !== -1)?.value;

    if (!zoneA || !zoneB || !zoneC || !zoneD || !zoneE) {
        return null;
    } else {
        return {
            labels: [
                'Easy (<115bpm)',
                'Fat burn (115-134bpm)',
                'Moderate Training (135-155bpm)',
                'Hard Training (155-175bpm)',
                'Extreme Training (>175bpm)'
            ],
            datasets: [{
                data: [
                    parseFloat(zoneA)*100,
                    parseFloat(zoneB)*100,
                    parseFloat(zoneC)*100,
                    parseFloat(zoneD)*100,
                    parseFloat(zoneE)*100
                ],
                backgroundColor: [
                    '#2893FF',
                    '#35C759',
                    '#FFCC02',
                    '#FE9500',
                    '#D20000'
                ],
            }]
        }
    }
}