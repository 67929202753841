import { Workout } from "../../entities/api/Workout";
import { WorkoutMetadata } from "../../entities/api/WorkoutMetadata";
import { HealthRecord } from "../../entities/HealthRecord";
import { WorkoutChartValue } from "../WorkoutChartValue";
import { HealthDataCollection } from "./HERemoteApiService";

export const WorkoutValueAdapter = {
    adaptDataCollections(workoutValue: WorkoutChartValue, data: HealthDataCollection[]): HealthDataCollection[] {
        if (data.length > 0 && data[0].records.length > 0) {
            const workouts: Workout[] = data[0].records.map((dto) => ({
                    time: new Date(dto.time as string),
                    values: JSON.parse(dto.value as string) as WorkoutMetadata[],
                })
            );
            return WorkoutValueAdapter.adaptWorkouts(workoutValue, workouts);
        } else {
            return [];
        }
    },
    adaptWorkouts(workoutValue: WorkoutChartValue, workouts: Workout[]): HealthDataCollection[] {
        const metadataName = workoutValueMetadataName(workoutValue);
        let returnData: HealthDataCollection[] = [];

        workouts.forEach(workout => {
            const metadata = workout.values.find(metadata => metadata.name === metadataName);
            if (metadata) {
                const collection = returnData.find(collection => collection.units === metadata.units);
                const newRecord: HealthRecord = {
                    time: workout.time.toISOString(),
                    value: parseFloat(metadata.value),
                };
                if (collection) {
                    collection.records.push(newRecord)
                } else {
                    returnData.push({
                        units: metadata.units,
                        records: [newRecord],
                    })
                }
            }
        });
        
        return returnData;
    }
}

function workoutValueMetadataName(workoutValue: WorkoutChartValue): string {
    switch (workoutValue) {
        case WorkoutChartValue.activeEnergyBurned:
            return 'Active energy burned';
        case WorkoutChartValue.distance:
            return 'Distance';
    }
}