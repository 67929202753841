import gql from "graphql-tag";
import { useMutation, useQuery } from "@apollo/client";
import { useParams } from "react-router"
import { dashboardWithComponents } from "../../../entities/gql_fragments/DashboardWithComponents";
import { DashboardQuery, DashboardQueryVariables, DashboardWithComponentsFragment, PutDashboardComponentsMutation, PutDashboardComponentsMutationVariables, SetDashboardDefaultDatePresetMutation, SetDashboardDefaultDatePresetMutationVariables } from "../../../generated/graphql";
import { AppContainer } from "../../core/AppContainer";
import { SideBarItem } from "../../core/SideBar";
import { LoadingFailedState } from "../../core/LoadingFailedState";
import { ActivityIndicator } from "../../core/activity_indicator/ActivityIndicator";
import { DashboardComponentsEditor } from "./DashboardComponentsEditor";
import { useDataTypes } from "../../../hooks/UseDataTypes";
import { useState } from "react";
import { insertAtArrayIndex } from "../../../models/ArrayUtils";
import { v4 as uuidv4 } from 'uuid';
import { ComponentType, DashboardComponentInput } from "../../../generated/graphql";
import { HESwal } from "../../core/HESwal";
import { DataTypeSelectModal } from "./components/DataTypeSelectModal";
import { HealthType } from "../../../entities/api/HealthType";
import { putDashboardComponents } from "../../../models/shared_gql/PutDashboardComponents";
import { useUserId } from "../../../hooks/UseUserId";
import { DashboardUtils } from "../../../models/DashboardUtils";
import { DashboardDefaultTimePeriodPicker } from "./DashboardDefaultTimePeriodPicker";
import { DatePreset } from "../../../models/DatePreset";
import "./DashboardSettingsDetailPage.css";
import { useConfigurationSavedToast } from "../../../hooks/UseConfigurationSavedToast";

const dashboardQuery = gql`
    ${dashboardWithComponents}
    query Dashboard($uid: String!, $dashboardUuid: String!) {
        dashboard(uid: $uid, dashboardUuid: $dashboardUuid) {
            ...DashboardWithComponents
        }
    }
`;

const setDashboardDefaultDatePresetMutation = gql`
    ${dashboardWithComponents}
    mutation SetDashboardDefaultDatePreset($defaultDatePresetId: Float!, $dashboardUuid: String!, $uid: String!) {
        setDashboardDefaultDatePreset(defaultDatePresetId: $defaultDatePresetId, dashboardUuid: $dashboardUuid, uid: $uid) {
            ...DashboardWithComponents
        }
    }
`;

type DashboardSettingsDetailPageParams = {
    dashboardUuid: string,
}

export const DashboardSettingsDetailPage: React.FC = () => {
    const userId = useUserId();
    const params = useParams<DashboardSettingsDetailPageParams>();
    const queryVariables: DashboardQueryVariables = {
        uid: userId,
        dashboardUuid: params.dashboardUuid,
    }
    const dashboardQueryResult = useQuery<DashboardQuery>(dashboardQuery, { variables: queryVariables });
    const [setDashboardComponents] = useMutation<PutDashboardComponentsMutation>(putDashboardComponents);
    const [setDashboardDefaultDatePreset] = useMutation<SetDashboardDefaultDatePresetMutation>(setDashboardDefaultDatePresetMutation)
    const [dashboard, setDashboard] = useState(undefined as DashboardWithComponentsFragment | undefined);
    const [error, setError] = useState(undefined as Error | undefined);
    const [isTypePickerShown, setIsTypePickerShown] = useState(false);
    const [pendingAddComponentIndex, setPendingAddComponentIndex] = useState(null as number | null);
    const dataTypes = useDataTypes();
    const { showConfigurationSavedToast } = useConfigurationSavedToast();

    if (!dashboard && dashboardQueryResult.data?.dashboard) {
        setDashboard(dashboardQueryResult.data.dashboard);
    }

    if (!error && dashboardQueryResult.error) {
        setError(dashboardQueryResult.error);
    }

    function performSetDashboardComponents(variables: PutDashboardComponentsMutationVariables) {
        setDashboardComponents({ variables:  variables })
            .then(result => setDashboard(result.data!.putDashboardComponents!))
            .catch(error => setError(error));
    }

    function addComponentAction(index: number) {
        setPendingAddComponentIndex(index);
        setIsTypePickerShown(true);
    }

    function typeSelectedAction(type: HealthType) {
        setIsTypePickerShown(false);
        if (pendingAddComponentIndex !== null) {
            const components = insertAtArrayIndex(
                DashboardUtils.transfromComponentsToMutationInput(dashboard?.components ?? []),
                pendingAddComponentIndex ?? 0,
                makeNewComponent(type),
            );
            const mutationVariables: PutDashboardComponentsMutationVariables = {
                uid: userId,
                dashboardUuid: params.dashboardUuid,
                components: components,
            };
            performSetDashboardComponents(mutationVariables)
        }
    }

    function deleteComponent(componentUuid: string) {
        HESwal.fire({
            title: 'Are you sure?',
            text: `Are you sure you want to delete the component?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it',
            customClass: {
                confirmButton: 'btn btn-danger'
            }
        }).then((result) => {
            if (result.isConfirmed) {
                const components = DashboardUtils.transfromComponentsToMutationInput(dashboard?.components ?? [])
                    .filter(component => component.componentUuid !== componentUuid)
                const mutationVariables: PutDashboardComponentsMutationVariables = {
                    uid: userId,
                    dashboardUuid: params.dashboardUuid,
                    components: components,
                };
                performSetDashboardComponents(mutationVariables);
            }
        })
    }

    function setDefaultDatePreset(datePreset: DatePreset) {
        if (dashboard) {
            const variables: SetDashboardDefaultDatePresetMutationVariables = {
                uid: dashboard.uid,
                dashboardUuid: dashboard.dashboardUuid,
                defaultDatePresetId: datePreset.id,
            }
            setDashboardDefaultDatePreset({ variables: variables })
                .then(result => {
                    setDashboard(result.data!.setDashboardDefaultDatePreset!);
                    showConfigurationSavedToast();
                })
                .catch(error => setError(error));
        }
    }


    return (
        <AppContainer
            title={ dashboardQueryResult.data?.dashboard?.name ?? ' ' }
            activeSideBarItem={SideBarItem.dashboard}
        >
            { error && 
                <LoadingFailedState />
            }
            { dashboardQueryResult.loading && 
                <ActivityIndicator />
            }
            { dashboard && !error && 
                <>
                    <div className="topDashboardConfigurationWrapper">
                        <DashboardDefaultTimePeriodPicker
                            dashboard={dashboard}
                            setDefaultDatePreset={setDefaultDatePreset}
                        />
                        <hr />
                    </div>
                    <DashboardComponentsEditor 
                        dashboard={dashboard}
                        dataTypes={dataTypes}
                        setDashboard={setDashboard}
                        addComponent={addComponentAction}
                        deleteAction={deleteComponent}
                        />
                    <DataTypeSelectModal
                        isDisplayed={isTypePickerShown}
                        setIsDisplayed={setIsTypePickerShown}
                        healthTypes={dataTypes}
                        typeSelectedAction={typeSelectedAction}
                        />
                </>
            }
        </AppContainer>
    )
}

function makeNewComponent(type: HealthType): DashboardComponentInput {
    return {
        componentUuid: uuidv4(),
        dataTypeIds: [type.id],
        type: ComponentType.BarOrLineChart,
        configuration: [],
    }
}