import { useUserId } from "../../../hooks/UseUserId"

export const UidPage: React.FC = () => {

    const userId = useUserId();

    function copyUserId() {
        navigator.clipboard.writeText(userId)
            .catch(() => {
               alert('Copy to clipboard was denied by your browser. Please copy the identifier manually.') 
            })
            .then(() => {});
    }

    return (
        <div
            style={{
                margin: '25px',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <p><strong>User identifier:</strong> {userId}</p>
            <a 
                href="#"
                onClick={copyUserId}
                style={{
                    color: '#28a745',
                    fontWeight: 'bold',
                }}
            >Copy</a>
            <br />
            <a 
                href="/"
                style={{
                    color: '#28a745',
                    fontWeight: 'bold',
                }}
            >Go back</a>
        </div>
    )
}