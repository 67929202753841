import { gql, useMutation, useQuery } from "@apollo/client";
import { LoadingFailedState } from "../../core/LoadingFailedState";
import { ConnectedBusinessAccountsQuery, DeleteConnectedBusinessAccountMutation, DeleteConnectedBusinessAccountMutationVariables, useAddConnectedBusinessAccountMutation } from "../../../generated/graphql"
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../Store";
import { HESwal } from "../../core/HESwal";
import { AddConnectedBusinessAccountModal } from "./AddConnectedBusinessAccountModal";
import { v4 as uuidv4 } from 'uuid';
import { addConnectedAccount, removeConnectedAccountsWithUid, setActiveAccount } from "../../../reducers/AccountReducer";

export const connectedBusinessAccountsQuery = gql`
query ConnectedBusinessAccounts($uid: String!) {
    connectedBusinessAccounts(uid: $uid) {
        connectedAccountUuid
        name
        licenseKey
  }
}
`;

export const deleteConnectedBusinessAccountMutation = gql`
mutation DeleteConnectedBusinessAccount($uid: String!, $connectedBusinessAccountUuid: String!) {
    deleteConnectedBusinessAccount(uid: $uid, connectedBusinessAccountUuid: $connectedBusinessAccountUuid) {
        connectedAccountUuid
        name
        licenseKey
    }
}
`;

export const ConnectedBusinessAccounts: React.FC = () => {

    const dispatch = useDispatch();
    const me = useSelector((state: RootState) => state.accountReducer?.me);
    const businessAccessKey = useSelector((state: RootState) => state.preferencesReducer.preferences.businessAccessKey);
    const activeAccountBusinessUuid = useSelector((state: RootState) => state.accountReducer?.activeAccount.businessAccess?.uuid);
    const licensesQuery = useQuery<ConnectedBusinessAccountsQuery>(
        connectedBusinessAccountsQuery,
        {
            variables: {
                uid: me?.directAccess?.uid ?? ''
            }
        }
    );

    const [addConnectedBusinessAccountMutation] = useAddConnectedBusinessAccountMutation();
    const [deleteConnectedAccountMutation] = useMutation<DeleteConnectedBusinessAccountMutation>(deleteConnectedBusinessAccountMutation);

    function deleteConnectedAccountButtonClicked(connectedAccountUuid: string) {
        const accountToDelete = (licensesQuery.data?.connectedBusinessAccounts ?? []).find(x => x.connectedAccountUuid === connectedAccountUuid);
        HESwal.fire({
            title: 'Are you sure?',
            text: `Are you sure you want to delete ${accountToDelete?.name ?? ''}?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it',
            customClass: {
                confirmButton: 'btn btn-danger'
            }
        }).then((result) => {
            if (result.isConfirmed) {
                const variables: DeleteConnectedBusinessAccountMutationVariables = {
                    uid: me?.directAccess?.uid ?? '',
                    connectedBusinessAccountUuid: connectedAccountUuid,
                }
                deleteConnectedAccountMutation({ variables: variables })
                    .then(result => {
                        licensesQuery.refetch();
                        const deletedAccountKey = result.data?.deleteConnectedBusinessAccount?.connectedAccountUuid;
                        if (deletedAccountKey) {
                            dispatch(removeConnectedAccountsWithUid(deletedAccountKey));
                            if (activeAccountBusinessUuid && me && activeAccountBusinessUuid === deletedAccountKey) {
                                dispatch(setActiveAccount(me));
                            }
                        }
                    })
                    .catch(_ => alert('Something went wrong. Please try it later.'));
            }
        })
    }



    function connectNewAccount(name: string, key: string) {
        addConnectedBusinessAccountMutation({ variables: {
            uid: me?.directAccess?.uid ?? '',
            connectedBusinessAccount: {
                connectedAccountUuid: uuidv4(),
                name: name,
                licenseKey: key,
            }
        } })
            .then(result => {
                licensesQuery.refetch();

                if (result.data?.putConnectedBusinessAccount && businessAccessKey) {
                    dispatch(addConnectedAccount({
                        name: name,
                        businessAccess: {
                            uuid: result.data.putConnectedBusinessAccount.connectedAccountUuid,
                            licenseKey: result.data.putConnectedBusinessAccount.licenseKey,
                            accessKey: businessAccessKey,
                        }
                    }));
                }
            })
            .catch(_ => alert('Something went wrong. Please try it later.'));
    }

    if (licensesQuery.error) {
        return (
            <LoadingFailedState />
        )
    }

    return (
        <>
            <p>Connected users (licenses):</p>
            { licensesQuery.data?.connectedBusinessAccounts?.length === 0 &&
                <p style={{ opacity: 0.5 }}>No connected users</p>
            }
            <table className="table" style={{ borderTopWidth: "0"}}>
                <tbody>
                    {
                        (licensesQuery.data?.connectedBusinessAccounts ?? []).map(connectedAccount => (
                            <tr key={connectedAccount.connectedAccountUuid}>
                                <td>
                                    <span>{connectedAccount.name}</span>
                                </td>
                                <td className="deleteConnectedAccountTd">
                                    <button 
                                        className="btn btn-round btn-fab"
                                        onClick={() => deleteConnectedAccountButtonClicked(connectedAccount.connectedAccountUuid)}
                                    >
                                        <i className="material-icons">delete</i>
                                        <div className="ripple-container"></div>
                                    </button>
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
            <button
                type="button"
                className="btn btn-success"
                style={{ marginTop: '20px', marginBottom: '0' }}
                data-toggle="modal"
                data-target="#addBusinessAccountModal"
                >Add</button>
            <AddConnectedBusinessAccountModal
                connectAccountHandler={connectNewAccount}
            />
        </>
    )
}