import { useMemo, useState } from "react";

export function useHover(): [boolean, { onMouseEnter():void, onMouseLeave():void }] {
  const [hovered, setHovered] = useState(false);
  
  const eventHandlers = useMemo(() => ({
    onMouseEnter() { setHovered(true); },
    onMouseLeave() { setHovered(false); }
  }), []);
  
  return [hovered, eventHandlers];
}