import "./DashboardSettingsItem.css"
import { Card } from "../../../core/card/Card"
import { DashboardWithoutComponentsFragment } from "../../../../generated/graphql";

export interface DashboardManagementProps {
    dashboard: DashboardWithoutComponentsFragment,
    renameClicked: () => void,
    editClicked: () => void,
    deleteClicked: () => void,
}

export const DashboardSettingsItem: React.FC<DashboardManagementProps> = (props: DashboardManagementProps) => {
    return (
        <div className="dashboardSettingsItemWrapper">
            <Card>
                <div className="dashboardSettingsItem">
                    <h3>{ props.dashboard.name }</h3>
                    <div className="dashboardSettingsItemActionsWrapper">
                        <button
                            type="button"
                            className="btn btn-fab"
                            onClick={props.renameClicked}
                            ><i className="material-icons">edit</i></button>
                        <button
                            type="button"
                            className="btn btn-fab"
                            onClick={props.editClicked}
                            ><i className="material-icons">settings</i></button>
                        <button
                            type="button"
                            className="btn btn-fab"
                            onClick={props.deleteClicked}
                            ><i className="material-icons">delete</i></button>
                    </div>
                </div>
            </Card>
        </div>
    )
}