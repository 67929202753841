import { useState } from "react"
import { Button, Modal } from "react-bootstrap";
import { fuzzySearch, SelectedOptionValue, SelectSearchOption } from "react-select-search";
import { HealthType } from "../../../../entities/api/HealthType"
import { HealthTypeCategory } from "../../../../entities/api/HealthTypeCategory";
import { HealthTypes } from "../../../../entities/api/HealthTypes"
import { healthTypeIdentifiers } from "../../../../models/datasources/HealthTypeIdentifier";
import { HESelectSearch } from "../../../core/HESelectSearch/HESelectSearch";

export interface DataTypeSelectModalProps {
    isDisplayed: boolean,
    defaultHealthType?: HealthType,
    healthTypes: HealthTypes,
    setIsDisplayed: (isDisplayed: boolean) => void,
    typeSelectedAction: (healthType: HealthType) => void,
}

export const DataTypeSelectModal: React.FC<DataTypeSelectModalProps> = (props: DataTypeSelectModalProps) => {
    const [healthType, setHealthType] = useState(props.defaultHealthType);
    if (!props.isDisplayed && healthType !== props.defaultHealthType) {
        setHealthType(props.defaultHealthType);
    }

    function selectValueChanged(value: SelectedOptionValue | SelectedOptionValue[]) {
        // @ts-expect-error
        const singleValueRaw = value as string;
        const singleValue = parseInt(singleValueRaw);
        const selectedHealthType = 
            [
                ...[
                    ...props.healthTypes.aggregated,
                    ...props.healthTypes.recordBased,
                ]
                .flatMap(category => category.types)
                .flatMap(dataTypes => dataTypes),
                props.healthTypes.workouts
            ]
            .find(type => (type?.id ?? -1) === singleValue);
        if (selectedHealthType) {
            setHealthType(selectedHealthType);
        }
    }

    function submitButtonClicked() {
        if (healthType) {
            props.typeSelectedAction(healthType);
        }
    }

    return (
        <Modal show={props.isDisplayed} onHide={() => props.setIsDisplayed(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Data type</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <HESelectSearch
                        options={makeSelectOptions(props.healthTypes)}
                        search
                        filterOptions={fuzzySearch}
                        emptyMessage="Not found"
                        placeholder="Select data type"
                        onChange={selectValueChanged}
                        value={healthType ? `${healthType.id}` : undefined}
                    />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => props.setIsDisplayed(false)}>
                    Close
                </Button>
                <Button
                    variant="success"
                    disabled={healthType === undefined}
                    onClick={submitButtonClicked}
                >
                    Select
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

function makeSelectOptions(healthTypes: HealthTypes): SelectSearchOption[] {
    function mapRootCategory(categories: HealthTypeCategory[], rootCategoryName: string): SelectSearchOption[] {
        return categories
            .flatMap(category => 
                category.types
                    .filter(type => isDataTypeSupportedByDashboard(type))
                    .map(type => makeSelectOption(type, rootCategoryName, category.name))
            )
    }

    let options = [
        ...mapRootCategory(healthTypes.aggregated, 'Aggregated'),
        ...mapRootCategory(healthTypes.recordBased, 'Record based'),
    ]

    if (healthTypes.workouts) {
        options.push({
            name: 'Workouts',
            value: healthTypeIdentifiers.workouts.toString(),
        });
    }

    return options;
}

function makeSelectOption(healthType: HealthType, masterCategoryName: string, category: string): SelectSearchOption {
    return {
        name: `${masterCategoryName} > ${category}: ${healthType.name}`,
        value: healthType.id.toString(),
    }
}

function isDataTypeSupportedByDashboard(healthType: HealthType): boolean {
    const unsupportedTypeIds = [
        // Record based HR
        52, 53, 54,
        // Cycle tracking
        27, 28, 29, 30, 31, 17
    ]
    return !unsupportedTypeIds.includes(healthType.id)
}