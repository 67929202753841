import { DateIntervalData } from "../../../hooks/UseDateInterval";
import { UseNutritionData } from "../../../hooks/UseNutritionData"
import { NutritionHistory } from "./NutritionHistory";
import { NutritionToday } from "./NutritionToday";
import "./NutritionContent.css"
import { safeAt } from "../../../models/ArrayUtils";
import { NutritionCurrentWeek } from "./NutritionCurrentWeek";

export interface NutritionContentProps {
    dateInterval: DateIntervalData,
    data: UseNutritionData,
}

export const NutritionContent: React.FC<NutritionContentProps> = (props: NutritionContentProps) => {
    return (
        <div className="container-fluid row workoutFiltrationRow">
            <div className="nutritionFullWidthDiv">
                <div className="nutritionFullWidthDiv row">
                    <div className="col-xl-6">
                        <h3 className="nutritionSectionHeader">Today</h3>
                        <NutritionToday
                                protein={safeAt(props.data.proteinThisWeek, 0)}
                                fats={safeAt(props.data.fatThisWeek, 0)}
                                carbohydrates={safeAt(props.data.carbohydratesThisWeek, 0)}
                            />
                    </div>
                    <div className="col-xl-6">
                        <h3 className="nutritionSectionHeader">This week</h3>
                        <NutritionCurrentWeek
                                protein={safeAt(props.data.proteinThisWeek, 0)}
                                fats={safeAt(props.data.fatThisWeek, 0)}
                                carbohydrates={safeAt(props.data.carbohydratesThisWeek, 0)}
                            />
                    </div>
                </div>
                <br />
                <div className="nutritionFullWidthDiv">
                    <h3 className="nutritionSectionHeader">History</h3>
                    <NutritionHistory
                            dateInterval={props.dateInterval}
                            protein={safeAt(props.data.proteinHistory, 0)}
                            fats={safeAt(props.data.fatHistory, 0)}
                            carbohydrates={safeAt(props.data.carbohydratesHistory, 0)}
                        />
                </div>
            </div>
        </div>
    );
}