import { useState } from "react"
import { DashboardComponent, DashboardComponentInputConfigValue } from "../../../../generated/graphql";
import { GaugeDefaultInterval } from "../../../../hooks/charts/UseGaugeChartConfiguration";
import { useDebouncedEffect } from "../../../../hooks/UseDebouncedEffect"
import { GaugeChartConfigurationKey } from "../../../../models/chart_configuration_keys/GaugeChartConfigurationKey";

export interface EditGaugeChartConfigurationRowsProps {
    component: DashboardComponent,
    patchConfiguration: (configurations: DashboardComponentInputConfigValue[]) => void,
}

export const EditGaugeChartConfigurationRows: React.FC<EditGaugeChartConfigurationRowsProps> = (props: EditGaugeChartConfigurationRowsProps) => {
    const defaultMin = props.component.configuration.find(x => x.key === GaugeChartConfigurationKey.GAUGE_MIN)?.value ?? null;
    const defaultMax = props.component.configuration.find(x => x.key === GaugeChartConfigurationKey.GAUGE_MAX)?.value ?? null;
    const [min, setMin] = useState(defaultMin);
    const [max, setMax] = useState(defaultMax);

    useDebouncedEffect(() => {
        const hasChange = defaultMin !== min || defaultMax !== max;
        if (hasChange) {
            var configurationValues: DashboardComponentInputConfigValue[] = [];
            configurationValues.push({
                key: GaugeChartConfigurationKey.GAUGE_MIN,
                value: min ?? '',
            });
            configurationValues.push({
                key: GaugeChartConfigurationKey.GAUGE_MAX,
                value: max ?? '',
            });
            props.patchConfiguration(configurationValues);
        }
    }, [min, max, props], 500);

    return (
        <form className="row">
            <div className="form-group col-lg-4">
                <label className="col-form-label">Gauge minimum:</label>
                <input type="number" className="form-control" value={min ?? ''} placeholder={`${GaugeDefaultInterval.min}`} onChange={(event) => setMin(event.target.value)} />
            </div>
            <div className="form-group col-lg-4">
                <label className="col-form-label">Gauge maximum:</label>
                <input type="number" className="form-control" value={max ?? ''} placeholder={`${GaugeDefaultInterval.max}`} onChange={(event) => setMax(event.target.value)} />
            </div>
        </form>
    )
}