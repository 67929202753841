import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ConnectedAccountsWithKeysQuery, ConnectedAccountsWithKeysQueryVariables } from "../../generated/graphql";
import { ConnectedAccountCrypto } from "../../models/ConnectedAccountCrypto";
import { makeUserInfo } from "../../models/UserUtils";
import { setConnectedAccounts } from "../../reducers/AccountReducer";
import { RootState } from "../../Store";

const connectedAccountsWithKeyQuery = gql`
query ConnectedAccountsWithKeys($uid: String!) {
    connectedAccounts(uid: $uid) {
        name
        accountKey
    }

    connectedBusinessAccounts(uid: $uid) {
        uid
        connectedAccountUuid
        name
        licenseKey
    }

    userPreferences(uid: $uid) {
        businessAccessKey
    }
}
`;

export interface ConnectedAccountsProviderProps {
    children: JSX.Element,
}

export const ConnectedAccountsProvider: React.FC<ConnectedAccountsProviderProps> = (props: ConnectedAccountsProviderProps) => {
    const me = useSelector((state: RootState) => state.accountReducer?.me);
    const queryVariables: ConnectedAccountsWithKeysQueryVariables = {
        uid: me?.directAccess?.uid ?? '',
    }
    const { data } = useQuery<ConnectedAccountsWithKeysQuery>(connectedAccountsWithKeyQuery, { variables: queryVariables });
    const dispatch = useDispatch();
    useEffect(() => {
        if (data) {
            dispatch(setConnectedAccounts(
                [
                    ...data.connectedAccounts.map(dto => {
                        const decryptedAccountKey = ConnectedAccountCrypto.dencrypt(dto.accountKey, me?.directAccess?.encryptionKey ?? '');
                        const userInfo = makeUserInfo(decryptedAccountKey);
                        return {
                            name: dto.name,
                            directAccess: {
                                uid: userInfo.accountKeySha512,
                                encryptionKey: userInfo.encryptionKey,
                            }
                        }
                    }),
                    ...data.connectedBusinessAccounts.map(dto => ({
                        name: dto.name,
                        businessAccess: {
                            uuid: dto.connectedAccountUuid,
                            licenseKey: dto.licenseKey,
                            accessKey: data.userPreferences?.businessAccessKey ?? '',
                        }
                    }))
                ]
            ))
        }
    });
    return props.children;
}