import { AppContainer } from "../../core/AppContainer";
import { SideBarItem } from "../../core/SideBar";
import { useDateInterval } from "../../../hooks/UseDateInterval";
import { CsvExportHeader } from "./CsvExportHeader";
import { useState } from "react";
import { CsvExportTypeSelect } from "./CsvExportTypeSelect";
import { useCsvExportPageDataManager } from "./CsvExportPageDataManager";
import { AggregationPeriod } from "../../../models/AggregationPeriod";
import { ActivityIndicator } from "../../core/activity_indicator/ActivityIndicator";
import { LoadingFailedState } from "../../core/LoadingFailedState";
import { useDataTypes } from "../../../hooks/UseDataTypes";
import { DataTypesFilter } from "../../../models/DataTypesFilter";

export enum CsvExportPageState {
    loading,
    selectTypes,
    loadingFailed,
}

export const CsvExportPage: React.FC = () => {
    const dateInterval = useDateInterval();
    const dataTypes = useDataTypes();
    const [enabledTypeIds, setEnabledTypeIds] = useState<Set<number>>(new Set());
    const [aggregationPeriod, setAggregationPeriod] = useState(AggregationPeriod.days);
    const [state, setState] = useState(CsvExportPageState.selectTypes);
    const [searchQuery, setSearchQuery] = useState('');

    const filteredTypes = DataTypesFilter.filter(dataTypes, searchQuery);

    const dataManager = useCsvExportPageDataManager(
        dataTypes,
        filteredTypes,
        enabledTypeIds,
        aggregationPeriod,
        dateInterval,
        setEnabledTypeIds,
        setAggregationPeriod,
        setState,
    );

    return (
        <AppContainer
            title="CSV Export"
            activeSideBarItem={SideBarItem.csv}
        >
            {(() => {
                switch(state) {
                    case CsvExportPageState.loading:
                        return (<ActivityIndicator />);
                    case CsvExportPageState.selectTypes:
                        return (
                            <>
                                <CsvExportHeader
                                    delegate={dataManager}
                                    dateInterval={dateInterval}
                                    aggregationPeriod={aggregationPeriod}
                                    isExportAvailable={enabledTypeIds.size > 0}
                                    searchQuery={searchQuery}
                                    setSearchQuery={setSearchQuery}
                                />
                                { filteredTypes &&
                                    <CsvExportTypeSelect 
                                        delegate={dataManager}
                                        types={filteredTypes}
                                        enabledTypeIds={enabledTypeIds}
                                    />
                                }
                            </>
                        );
                    case CsvExportPageState.loadingFailed:
                        return (<LoadingFailedState />);
                }
            })()}
        </AppContainer>
    );
}