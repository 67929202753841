import { useEffect, useState } from "react";
import { AppContainer } from "../../core/AppContainer";
import { SideBarItem } from "../../core/SideBar";
import Flatpickr from "react-flatpickr";
import './WorkoutsPage.css';
import { useDateInterval } from "../../../hooks/UseDateInterval";
import { WorkoutsPageContent } from "./WorkoutsPageContent";
import { HERemoteApiService } from "../../../models/datasources/HERemoteApiService";
import { healthTypeIdentifiers } from "../../../models/datasources/HealthTypeIdentifier";
import { Workout } from "../../../entities/api/Workout";
import { WorkoutMetadata } from "../../../entities/api/WorkoutMetadata";
import { LoadingFailedState } from "../../core/LoadingFailedState";
import { WorkoutsPageExport } from "./WorkoutsPageExport";
import { useSelector } from "react-redux";
import { RootState } from "../../../Store";
import { DatePresetPicker } from "../../core/DatePresetPicker";
import { DatePreset, presetsWithoutAggregation } from "../../../models/DatePreset";
import { DateUtils } from "../../../models/DateUtils";
import { FirstWeekDay } from "../../../generated/graphql";

type ActivityTypeStatistics = {
    activityName: string,
    numberOfWorkouts: number,
}

function workoutActivityTypes(workouts: Workout[]): ActivityTypeStatistics[] {
    const activityTypes: ActivityTypeStatistics[] = [];
    workouts.map((workout) => workout.values.find((metadata) => (metadata.name === 'Activity'))?.value).forEach(activityName => {
        if (activityName) {
            const typeStatistics = activityTypes.find(x => x.activityName === activityName);
            if (typeStatistics) {
                typeStatistics.numberOfWorkouts += 1;
            } else {
                activityTypes.push({
                    activityName: activityName,
                    numberOfWorkouts: 1,
                });
            }
        }
    })
    return activityTypes.sort((a, b) => (a.activityName > b.activityName) ? 1 : -1);
}

export const WorkoutsPage: React.FC = () => {
    const dateInterval = useDateInterval();
    const [isLoading, setIsLoading] = useState(true);
    const [workouts, setWorkouts] = useState([] as Workout[]);
    const [filteredWorkouts, setFilteredWorkouts] = useState([] as Workout[]);
    const [activityTypeFilter, setActivityTypeFilter] = useState(null as (ActivityTypeStatistics | null));
    const [loadingFailed, setLoadingFailed] = useState(false);
    const activeAccount = useSelector((state: RootState) => state.accountReducer?.activeAccount);
    const weekStartPreference = useSelector((state: RootState) => state.preferencesReducer.preferences.firstWeekDay);

    useEffect(() => {
        if (activeAccount) {
            setIsLoading(true);
            HERemoteApiService.getData(activeAccount, healthTypeIdentifiers.workouts, dateInterval.interval.from, dateInterval.interval.to)
                .then(data => {
                    if (data && (data as any[]).length > 0 && data[0].records.length > 0) {
                        const workouts: Workout[] = data[0].records.map((dto) => ({
                                time: new Date(dto.time as string),
                                values: JSON.parse(dto.value as string) as WorkoutMetadata[],
                            })
                        );
                        workouts.reverse();
                        setWorkouts(workouts);
                        setActivityTypeFilter(null);
                    } else {
                        setWorkouts([]);
                    }
                    setIsLoading(false);
                })
                .catch(_ => {
                    setLoadingFailed(true);
                });
        }
    }, [activeAccount, dateInterval.interval]);

    useEffect(() => {
        if (activityTypeFilter) {
            const filteredWorkouts = workouts.filter(workout => {
                const activityType = workout.values.find(metadata => metadata.name === 'Activity')?.value;
                return activityType === activityTypeFilter.activityName;
            })
            setFilteredWorkouts(filteredWorkouts);
        } else {
            setFilteredWorkouts(workouts);
        }
    }, [workouts, activityTypeFilter]);

    function onPresetSelected(datePreset: DatePreset) {
        dateInterval.setInterval({
            from: datePreset.dateFrom,
            to: datePreset.dateTo,
        })
    }

    return (
        <AppContainer
            title="Workouts"
            activeSideBarItem={SideBarItem.workouts}
        >
            <div className="container-fluid row workoutFiltrationRow">
                <p>Dates: </p>
                <Flatpickr
                    className="workoutsDatePicker"
                    value={[dateInterval.interval.from, dateInterval.interval.to]}
                    options={{
                        mode: 'range',
                        locale: {
                            firstDayOfWeek: weekStartPreference === FirstWeekDay.Sunday ? 0 : 1,
                        },
                    }}
                    onChange={dates => {
                        if (dates.length === 2 && dates[0] && dates[1]) {
                            dateInterval.setInterval({
                                from: dates[0],
                                to: DateUtils.setTimeToSecondBeforeMidnight(dates[1]),
                            })
                        }
                    }}
                />
                <DatePresetPicker
                        presets={presetsWithoutAggregation}
                        onPresetSelected={onPresetSelected}
                    />
                <div style={{ marginLeft: 'auto' }}></div>
                { workoutActivityTypes(workouts).length > 1 
                    ? (
                        <div className="dropdown workoutTypeDropdown">
                            <button 
                                className="btn dropdown-toggle btn-outline-secondary"
                                type="button" id="workoutTypePeriodDropdown"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false">{activityTypeFilter ? `${activityTypeFilter.activityName} (${activityTypeFilter.numberOfWorkouts})` : `All activities (${workouts.length})`}</button>
                            <div className="dropdown-menu" aria-labelledby="workoutTypePeriodDropdown">
                                <a className="dropdown-item workoutActivityTypeDropdownItem" href="#" onClick={() => setActivityTypeFilter(null)}>All activities ({workouts.length})</a>
                                { workoutActivityTypes(workouts).map(activityType => (
                                        <a 
                                            key={activityType.activityName}
                                            className="dropdown-item workoutActivityTypeDropdownItem"
                                            href="#"
                                            onClick={() => setActivityTypeFilter(activityType)}
                                        >{activityType.activityName} ({activityType.numberOfWorkouts})</a>
                                    )
                                )
                                }
                            </div>
                        </div>
                    )
                    : (<></>)
                }
                <button 
                    type="button"
                    className="btn btn-success workoutsExportButton"
                    disabled={filteredWorkouts.length === 0}
                    onClick={() => WorkoutsPageExport.exportWorkoutsToCsv(filteredWorkouts)}
                    >Export</button>
            </div>
            
            { !loadingFailed
                ? (<WorkoutsPageContent isLoading={isLoading} workouts={filteredWorkouts} />)
                : (<LoadingFailedState />)
            }
            
        </AppContainer>
    );
}