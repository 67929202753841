import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LocalStorageManager } from '../models/LocalStorageManager';

export type Account = {
    name: string,
    directAccess?: DirectAccountAccess,
    businessAccess?: BusinessAccountAccess,
}

export type DirectAccountAccess = {
    uid: string,
    encryptionKey: string,
}

export type BusinessAccountAccess = {
    uuid: string,
    licenseKey: string,
    accessKey: string,
}

interface AccountSliceData {
    me: Account,
    connectedAccounts: Account[],
    activeAccount: Account,
}

function makeDefaultAccountSlice(): AccountSliceData | null {
    const me = LocalStorageManager.getUser()
    if (!me) {
        return null;
    }
    const activeAccount = LocalStorageManager.getActiveAccount()
    const meAccount: Account = {
        name: 'Me',
        directAccess: {
            uid: me.accountKeySha512,
            encryptionKey: me.encryptionKey,
        }
    }
    return {
        me: meAccount,
        connectedAccounts: LocalStorageManager.getConnectedAccountCache() ?? [],
        activeAccount: activeAccount ?? meAccount,
    }
}

export const accountSlice = createSlice({
    name: 'account',
    initialState: makeDefaultAccountSlice() as AccountSliceData | null,
    reducers: {
        setConnectedAccounts: (state, action: PayloadAction<Account[]>) => {
            if (state) {
                LocalStorageManager.setConnectedAccountCache(action.payload);
                state.connectedAccounts = action.payload;
            }
        },
        setActiveAccount: (state, action: PayloadAction<Account>) => {
            if (state) {
                LocalStorageManager.setActiveAccount(action.payload);
                state.activeAccount = action.payload;
            }
        },
        setAccountToNull: (state) => {
            state = null
        },
        addConnectedAccount: (state, action: PayloadAction<Account>) => {
            if (state) {
                const newConnectedAccounts = [
                    ...state.connectedAccounts,
                    action.payload,
                ];
                LocalStorageManager.setConnectedAccountCache(newConnectedAccounts);
                state.connectedAccounts = newConnectedAccounts;
            }
        },
        removeConnectedAccountsWithUid: (state, action: PayloadAction<string>) => {
            if (state) {
                const newConnectedAccounts = state.connectedAccounts
                    .filter(acc => 
                        (acc.directAccess !== undefined && acc.directAccess.uid !== action.payload) ||
                        (acc.businessAccess !== undefined && acc.businessAccess.uuid !== action.payload)
                    );
                LocalStorageManager.setConnectedAccountCache(newConnectedAccounts);
                state.connectedAccounts = newConnectedAccounts;
            }
        },
        updateBusinessAccessKey: (state, action: PayloadAction<string>) => {
            if (state) {
                let newConnectedAccounts = state.connectedAccounts;
                newConnectedAccounts.forEach(acc => {
                    if (acc.businessAccess) {
                        acc.businessAccess.accessKey = action.payload;
                    }
                })
                LocalStorageManager.setConnectedAccountCache(newConnectedAccounts);
                state.connectedAccounts = newConnectedAccounts;
            }
        },
    }
});

export const { setConnectedAccounts, setActiveAccount, setAccountToNull, addConnectedAccount, removeConnectedAccountsWithUid, updateBusinessAccessKey } = accountSlice.actions;

export default accountSlice.reducer;