import Flatpickr from "react-flatpickr";
import { useSelector } from "react-redux";
import { FirstWeekDay } from "../../../generated/graphql";
import { DateIntervalData } from "../../../hooks/UseDateInterval";
import { AggregationPeriod, aggregationPeriodName } from "../../../models/AggregationPeriod";
import { DatePreset, presetsWithAggregation } from "../../../models/DatePreset";
import { DateUtils } from "../../../models/DateUtils";
import { RootState } from "../../../Store";
import { DatePresetPicker } from "../../core/DatePresetPicker";
import "./CsvExportHeader.css";

export interface CsvExportHeaderDelegate {
    enableAllTypesAction: () => void,
    disableAllTypesAction: () => void,
    setAggregationPeriod: (aggregationPeriod: AggregationPeriod) => void,
    exportAction: () => void
}

export interface CsvExportHeaderProps {
    dateInterval: DateIntervalData,
    aggregationPeriod: AggregationPeriod,
    isExportAvailable: boolean,
    delegate: CsvExportHeaderDelegate,
    searchQuery: string,
    setSearchQuery: (searchQuery: string) => void,
}

export const CsvExportHeader: React.FC<CsvExportHeaderProps> = (props: CsvExportHeaderProps) => {

    const weekStartPreference = useSelector((state: RootState) => state.preferencesReducer.preferences.firstWeekDay);

    function onPresetSelected(datePreset: DatePreset) {
        props.dateInterval.setInterval({
            from: datePreset.dateFrom,
            to: datePreset.dateTo,
        });
        props.delegate.setAggregationPeriod(datePreset.aggregationPeriod!);
    }

    return (
        <>
            <div className="container-fluid row workoutFiltrationRow">
                <p>Dates: </p>
                <Flatpickr
                    className="workoutsDatePicker"
                    value={[props.dateInterval.interval.from, props.dateInterval.interval.to]}
                    options={{
                        mode: 'range',
                        locale: {
                            firstDayOfWeek: weekStartPreference === FirstWeekDay.Sunday ? 0 : 1,
                        },
                    }}
                    onChange={dates => {
                        if (dates.length === 2 && dates[0] && dates[1]) {
                            props.dateInterval.setInterval({
                                from: dates[0],
                                to: DateUtils.setTimeToSecondBeforeMidnight(dates[1]),
                            });
                        }
                    }}
                />
                <DatePresetPicker
                        presets={presetsWithAggregation}
                        onPresetSelected={onPresetSelected}
                    />
                <button
                    type="button"
                    className="btn btn-outline-secondary exportEnableAllButton"
                    onClick={props.delegate.enableAllTypesAction}
                    >Enable all</button>
                <button
                    type="button"
                    className="btn btn-outline-secondary exportDisableAllButton"
                    onClick={props.delegate.disableAllTypesAction.bind(props.delegate)}
                    >Disable all</button>
                <div className="dropdown">
                    <button className="btn dropdown-toggle btn-outline-secondary" type="button" id="exportAgregationPeriodDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{aggregationPeriodName(props.aggregationPeriod)}</button>
                    <div className="dropdown-menu" aria-labelledby="exportAgregationPeriodDropdown">
                        <a className="dropdown-item" href="#" onClick={() => props.delegate.setAggregationPeriod(AggregationPeriod.hours)}>Hours</a>
                        <a className="dropdown-item" href="#" onClick={() => props.delegate.setAggregationPeriod(AggregationPeriod.days)}>Days</a>
                        <a className="dropdown-item" href="#" onClick={() => props.delegate.setAggregationPeriod(AggregationPeriod.months)}>Months</a>
                        <a className="dropdown-item" href="#" onClick={() => props.delegate.setAggregationPeriod(AggregationPeriod.years)}>Years</a>
                    </div>
                </div>
                <div style={{ marginLeft: 'auto' }}></div>
                <button
                    type="button"
                    className="btn btn-success workoutsExportButton"
                    disabled={!props.isExportAvailable}
                    onClick={props.delegate.exportAction}
                    >Export</button>
            </div>
            <div className="container-fluid row workoutFiltrationRow">
                <form className="csvSearchForm">
                    <div className="form-group csvSearchWrapper">
                        <label className="col-form-label">Search:</label>
                        <input type="text" className="form-control" placeholder="Type name" value={props.searchQuery} onChange={(event) => props.setSearchQuery(event.target.value)} />
                    </div>
                </form>
            </div>
        </>
    )
}