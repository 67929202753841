import moment from "moment";
import { useSelector } from "react-redux";
import { NumberFormat, TimeFormat } from "../../../../../generated/graphql";
import { HealthRecord } from "../../../../../entities/HealthRecord";
import { AggregationPeriod } from "../../../../../models/AggregationPeriod";
import { HealthDataCollection } from "../../../../../models/datasources/HERemoteApiService"
import { formatNumber } from "../../../../../models/UserUtils";
import { RootState } from "../../../../../Store";
import "./TableChart.css"

export interface TableChartProps {
    data: HealthDataCollection[],
    aggregationPeriod: AggregationPeriod | null,
}

function formatDate(dateString: string, aggregationPeriod: AggregationPeriod | null, preferredTimeFormat: TimeFormat): string {
    let timeFormat;

    switch (aggregationPeriod) {
        case null:
            switch (preferredTimeFormat) {
                case TimeFormat.Format12h:
                    timeFormat = 'MMM D YYYY h:mm:ss a';
                    break;
                case TimeFormat.Format24h:
                    timeFormat = 'MMM D YYYY HH:mm:ss';
                    break;
            }
            break;

        case AggregationPeriod.hours:
            switch (preferredTimeFormat) {
                case TimeFormat.Format12h:
                    timeFormat = 'MMM D YYYY h:mm:ss a';
                    break;
                case TimeFormat.Format24h:
                    timeFormat = 'MMM D YYYY HH:mm:ss';
                    break;
            }
            break;

        case AggregationPeriod.days:
            timeFormat = 'MMM D YYYY';
            break;

        case AggregationPeriod.months:
            timeFormat = 'MMM YYYY';
            break;

        case AggregationPeriod.years:
            timeFormat = 'YYYY';
            break;
    }

    return moment(dateString).format(timeFormat);
}

function rowValue(record: HealthRecord, numberFormat: NumberFormat): string {
    const value = formatNumber(
        parseFloat(record.value.toString()),
        3,
        numberFormat
    );
    if (value === 'NaN') {
        return ' ';
    } else {
        return value;
    }
}

export const TableChart: React.FC<TableChartProps> = (props: TableChartProps) => {
    const { preferencesReducer } = useSelector((state: RootState) => state);
    const preferences = preferencesReducer.preferences;

    return (
        <table className="table">
            <tbody>
                {
                    props.data[0].records.map(record => (
                        <tr className="chartTableRow" key={record.time}>
                            <td>
                                <div>
                                    <p>{formatDate(record.time, props.aggregationPeriod, preferences.timeFormat)}</p>
                                </div>
                            </td>
                            <td>
                                <div>
                                    <p>{ rowValue(record, preferences.numberFormat) }</p>
                                </div>
                            </td>
                        </tr>
                    ))
                }
            </tbody>
        </table>
    )
}