import "./AddComponentRow.css"
import { AddComponentButton } from "../components/AddComponentButton"

export interface AddComponentRowProps {
    onClickAction: () => void,
}

export const AddComponentRow: React.FC<AddComponentRowProps> = (props: AddComponentRowProps) => {
    return (
        <div className="row addComponentButtonWrapper">
            <AddComponentButton onClickAction={props.onClickAction} />
        </div>
    )
}