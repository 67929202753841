export enum AggregationPeriod {
    hours,
    days,
    months,
    years,
}

export function aggregationPeriodName(aggregationPeriod: AggregationPeriod): string {
    switch(aggregationPeriod) {
        case AggregationPeriod.hours:
            return "Hours";
        case AggregationPeriod.days:
            return "Days";
        case AggregationPeriod.months:
            return "Months";
        case AggregationPeriod.years:
            return "Years";
    }
}