import { AppContainer } from "../../core/AppContainer";
import { SideBarItem } from "../../core/SideBar";
import { DateIntervalData, useDateInterval } from "../../../hooks/UseDateInterval";
import { LoadingFailedState } from "../../core/LoadingFailedState";
import { UseNutritionData, useNutritionData, UseNutritionDataState } from "../../../hooks/UseNutritionData";
import { ActivityIndicator } from "../../core/activity_indicator/ActivityIndicator";
import { NutritionContent } from "./NutritionContent";

export const NutritionPage: React.FC = () => {
    const dateInterval = useDateInterval();
    const nutritionData = useNutritionData(dateInterval.interval);

    return (
        <AppContainer
            title="Nutrition"
            activeSideBarItem={SideBarItem.nutrition}
        >
            { makeContent(nutritionData, dateInterval) }

        </AppContainer>
    );
}

function makeContent(data: UseNutritionData, dateInterval: DateIntervalData) {
    switch (data.state) {
        case UseNutritionDataState.loading:
            return (<ActivityIndicator />);
        case UseNutritionDataState.error:
            return (<LoadingFailedState />);
        case UseNutritionDataState.data:
            return (
                <NutritionContent
                        dateInterval={dateInterval}
                        data={data}
                    />
            );
    }
}