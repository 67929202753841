import { WorkoutListTotalStatsItem, WorkoutListTotalStatsItemProps } from "./WorkoutListTotalStatsItem"

export interface WorkoutListTotalStatsProps {
    items: WorkoutListTotalStatsItemProps[],
}

export const WorkoutListTotalStats: React.FC<WorkoutListTotalStatsProps> = (props: WorkoutListTotalStatsProps) => {
    return (
        <div className="row workoutRow">
            <p className="workoutTypeLabel col-12">Total statistics</p>
            <div className="col-12 workoutTotalMetaInfosDivs">
                { props.items.map(item => (<WorkoutListTotalStatsItem key={item.dataTypeName} {...item} />)) }
            </div>
        </div>
    )
}