import { HealthType } from "../../../entities/api/HealthType";
import { HealthTypes } from "../../../entities/api/HealthTypes";
import { DateIntervalData } from "../../../hooks/UseDateInterval";
import { CsvExportService } from "../../../models/datasources/CsvExportService";
import { LocalStorageManager } from "../../../models/LocalStorageManager";
import { AggregationPeriod } from "../../../models/AggregationPeriod";
import { CsvExportHeaderDelegate } from "./CsvExportHeader";
import { CsvExportPageState } from "./CsvExportPage";
import { CsvExportTypeSelectDelegate } from "./CsvExportTypeSelect";
import { useSelector } from "react-redux";
import { RootState } from "../../../Store";

export type CsvExportPageDataManager = CsvExportHeaderDelegate & CsvExportTypeSelectDelegate;

export function useCsvExportPageDataManager(
    allTypes: HealthTypes,
    filteredTypes: HealthTypes,
    enabledTypeIds: Set<number>,
    aggregationPeriod: AggregationPeriod,
    dateInterval: DateIntervalData,
    setEnabledTypeIds: (enabledTypeIds: Set<number>) => void,
    setAggregationPeriod: (aggregationPeriod: AggregationPeriod) => void,
    setState: (state: CsvExportPageState) => void,
): CsvExportPageDataManager {
    const activeAccount = useSelector((state: RootState) => state.accountReducer?.activeAccount)
    return {
        exportAction: () => {
            if (enabledTypeIds.size > 0 && activeAccount) {
                setState(CsvExportPageState.loading);
                CsvExportService.fetchAndGenerateCsv(activeAccount, enabledTypeIds, dateInterval, aggregationPeriod)
                    .then(csv => {
                        setState(CsvExportPageState.selectTypes);
                        CsvExportService.downloadCsv('export', csv);
                        LocalStorageManager.setCsvExportTypeIds(Array.from(enabledTypeIds));
                    })
                    .catch(_ => {
                        setState(CsvExportPageState.loadingFailed);
                    });
            }
        },
        setAggregationPeriod: setAggregationPeriod,
        enableAllTypesAction: () => {
            const flattenTypesIds = flattenHealthTypes(allTypes).map(x => x.id);
            setEnabledTypeIds(new Set(flattenTypesIds));
        },
        disableAllTypesAction: () => {
            setEnabledTypeIds(new Set());
        },
        enableType: (type: HealthType) => {
            if (!enabledTypeIds.has(type.id)) {
                let newTypes = new Set(enabledTypeIds);
                newTypes.add(type.id);
                setEnabledTypeIds(newTypes);
            }
        },
        disableType: (type: HealthType) => {
            if (enabledTypeIds.has(type.id)) {
                let newTypes = new Set(enabledTypeIds);
                newTypes.delete(type.id);
                setEnabledTypeIds(newTypes);
            }
        }
    }
}

function flattenHealthTypes(healthTypes: HealthTypes): HealthType[] {
    return healthTypes.aggregated
        .concat(healthTypes.recordBased)
        .flatMap(x => x.types);
}