import { DatePreset } from "../../models/DatePreset"

export interface DatePresetPickerProps {
    presets: DatePreset[],
    onPresetSelected: (datePreset: DatePreset) => void,
}

export const DatePresetPicker: React.FC<DatePresetPickerProps> = (props: DatePresetPickerProps) => {
    return (
        <div className="dropdown datePresetPickerDropdown">
            <button className="btn dropdown-toggle btn-outline-secondary" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Presets</button>
            <div className="dropdown-menu">
                { props.presets.map((present, index) => (
                    <a  key={`presetItem${index}`} 
                        className="dropdown-item"
                        href="#"
                        onClick={() => props.onPresetSelected(present)}
                    >{ present.title }</a>
                ))   
                }
            </div>
        </div>
    )
}