import { Bar } from "react-chartjs-2"
import { SleepDay } from "../../../entities/sleep/SleepDay"
import { DateInterval } from "../../../hooks/UseDateInterval"
import { ChartData } from "chart.js";
import { useSelector } from "react-redux";
import { RootState } from "../../../Store";
import { ChartUtils } from "../dashboard/content/charts/ChartUtils";
import { formatNumber } from "../../../models/UserUtils";
import { NumberFormat } from "../../../generated/graphql";
import { useMediaQuery } from "react-responsive";
import { SleepStage, sleepStageColorHex } from "../../../entities/sleep/SleepStage";
import { useChartLabelColor } from "../../../hooks/UseChartLabelColor";
import { SleepUtils } from "../../../models/SleepUtils";

export interface SleepDaysChartProps {
    sleepDays: SleepDay[],
    dateInterval: DateInterval,
}

export const SleepDaysChart: React.FC<SleepDaysChartProps> = (props: SleepDaysChartProps) => {
    const chartData = makeChartData(props.sleepDays);
    const preferences = useSelector((state: RootState) => state.preferencesReducer.preferences);

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
    const labelColor = useChartLabelColor();
    
    return (
        <Bar
            data={chartData}
            height={150}
            options={{
                plugins: {
                    legend: {
                        display: true,
                        onClick: () => {},
                        labels: {
                            color: labelColor,
                        }
                    },
                    tooltip: {
                        callbacks: {
                            label: (data: any) => tooltipCallback(data, preferences.numberFormat, props.sleepDays),
                        }
                    }
                },
                aspectRatio: isTabletOrMobile ? 1.5 : 4,
                scales: {
                    xAxis: {
                        stacked: true,
                        type: 'time',
                        time: {
                            unit: 'day',
                            displayFormats: {
                                second: ChartUtils.makeDateFormatSeconds(preferences.timeFormat),
                                minute: ChartUtils.makeDateFormatMinutesAndHours(preferences.timeFormat),
                                hour: ChartUtils.makeDateFormatMinutesAndHours(preferences.timeFormat),
                            }
                        },
                        ticks: {
                            color: labelColor,
                        },
                    },
                    yAxis: {
                        stacked: true,
                        min: 0,
                        ticks: {
                            color: labelColor,
                        },
                    },
                },
            }}
        />
    )
}

function tooltipCallback(data: any, numberFormat: NumberFormat, sleepDays: SleepDay[]): string {
    const datasetIndex = data.datasetIndex as number;
    const dataIndex = data.dataIndex as number;
    let value: number;
    if (datasetIndex === 0) {
        value = SleepUtils.makeSleepingHours(sleepDays[dataIndex]);
    } else {
        value = sleepDays[dataIndex].hoursInBed;
    }
    const formatedValue = formatNumber(
        value,
        1,
        numberFormat
    )
    return `${formatedValue} hours`;
}

function makeChartData(sleepDays: SleepDay[]): ChartData<"bar", (number | null)[], unknown> {
    return {
        labels: sleepDays.map(day => day.date),
        datasets: [
            {
                label: 'Sleeping',
                backgroundColor: sleepStageColorHex(SleepStage.asleep),
                borderColor: sleepStageColorHex(SleepStage.asleep),
                data: sleepDays.map(day => {
                    const sleepingHours = SleepUtils.makeSleepingHours(day)
                    if (sleepingHours < day.hoursInBed) {
                        return sleepingHours;
                    } else {
                        return sleepingHours - day.hoursInBed;
                    }
                }),
            },
            {
                label: 'In bed',
                backgroundColor: sleepStageColorHex(SleepStage.inBed),
                borderColor: sleepStageColorHex(SleepStage.inBed),
                data: sleepDays.map(day => {
                    const sleepingHours = SleepUtils.makeSleepingHours(day)
                    if (day.hoursInBed < sleepingHours) {
                        return day.hoursInBed;
                    } else {
                        return day.hoursInBed - sleepingHours;
                    }
                }),
            },
        ]
    }
}