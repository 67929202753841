import { ChartData } from "chart.js";
import moment from "moment";
import { Line } from "react-chartjs-2";
import { useSelector } from "react-redux";
import { DashboardComponentConfigurationValue } from "../../../../../generated/graphql";
import { HealthRecord } from "../../../../../entities/HealthRecord";
import { useStackedBarLineChartConfiguration } from "../../../../../hooks/charts/UseStackedBarLineChartConfiguration";
import { DateIntervalData } from "../../../../../hooks/UseDateInterval"
import { useIsDarkMode } from "../../../../../hooks/UseIsDarkmode";
import { AggregationPeriod } from "../../../../../models/AggregationPeriod";
import { HealthDataCollection } from "../../../../../models/datasources/HERemoteApiService"
import { RootState } from "../../../../../Store";
import { makeXAxisTimeUnit } from "./BarChart";
import { ChartUtils } from "./ChartUtils";

export interface StackedBarLineChartProps {
    data: HealthDataCollection[],
    dateInterval: DateIntervalData,
    aggregationPeriod: AggregationPeriod,
    configuration: DashboardComponentConfigurationValue[],
}

export const StackedBarLineChart: React.FC<StackedBarLineChartProps> = (props: StackedBarLineChartProps) => {
    const isDarkMode = useIsDarkMode();
    const chartData = makeChartData(props.data, props.dateInterval, isDarkMode);
    const preferences = useSelector((state: RootState) => state.preferencesReducer.preferences);
    const configuration = useStackedBarLineChartConfiguration(props.configuration);
    return (
        <Line
                    data={chartData}
                    height={ChartUtils.makeChartHeight()}
                    options={{
                        plugins: {
                            legend: {
                                display: false,
                            },
                            tooltip: {
                                callbacks: {
                                    label: (data: any) => ChartUtils.tooltipCallback(data, preferences.numberFormat, props.data[Math.abs(data.datasetIndex - 1)].units),
                                }
                            }
                        },
                        aspectRatio: 2,
                        scales: {
                            xAxis: {
                                type: 'time',
                                time: {
                                    unit: makeXAxisTimeUnit(props.aggregationPeriod),
                                    displayFormats: {
                                        second: ChartUtils.makeDateFormatSeconds(preferences.timeFormat),
                                        minute: ChartUtils.makeDateFormatMinutesAndHours(preferences.timeFormat),
                                        hour: ChartUtils.makeDateFormatMinutesAndHours(preferences.timeFormat),
                                    }
                                }
                            },
                            yAxisA: {
                                type: 'linear',
                                display: true,
                                position: 'right',
                                min: configuration.ySecondaryMin,
                                max: configuration.ySecondaryMax,
                            },
                            yAxisB: {
                                type: 'linear',
                                display: true,
                                position: 'left',
                                min: configuration.yMainMin,
                                max: configuration.yMainMax,
                                grid: {
                                    display:false
                                }
                            },  
                        },
                    }}
                />
    )
}

function makeChartData(data: HealthDataCollection[], dateInterval: DateIntervalData, isDarkMode: boolean): ChartData<"line", (number | null)[], unknown> {

    function parseRecord(record: HealthRecord): number {
        if (typeof record.value === 'string') {
            return parseFloat(record.value);
        } else {
            return record.value as number;
        }
    }

    const dateStringPoints = Array.from(
        new Set(
            data.flatMap(x => x.records).map(dataPoint => dataPoint.time)
        )
    ).sort();
    const datePoints = dateStringPoints.map(date => new Date(date));

    let numberdDataPoints: (number | null)[][] = [[],[]];
    let dataA: HealthRecord[] = data.length >= 1 
        ? [...data[0].records]
        : [];
    let dataB: HealthRecord[] = data.length >= 2 
        ? [...data[1].records]
        : [];
    dateStringPoints.forEach(date => {
        if (dataA.length > 0 && dataA[0].time === date) {
            numberdDataPoints[0].push(parseRecord(dataA.shift() as HealthRecord));
        } else {
            numberdDataPoints[0].push(null);
        }
        if (dataB.length > 0 && dataB[0].time === date) {
            numberdDataPoints[1].push(parseRecord(dataB.shift() as HealthRecord));
        } else {
            numberdDataPoints[1].push(null);
        }
    });

    let labelDates = [
        dateInterval.interval.from,
        ...datePoints,
    ];
    if (datePoints.length > 0 && !moment(datePoints[datePoints.length - 1]).isSame(moment(dateInterval.interval.to), 'day')) {
        labelDates.push(dateInterval.interval.to);
    }

    return {
        labels: labelDates,
        datasets: [
            {
                label: 'Chart',
                yAxisID: 'yAxisA',
                borderColor: isDarkMode ? '#35C75975' : '#288C41',
                pointBackgroundColor: '#35C759',
                spanGaps: true,
                borderWidth: 4,
                pointBorderWidth: 4,
                data: numberdDataPoints.length < 2
                    ? [null]
                    : [
                        null,
                        ...numberdDataPoints[1],
                        null,
                    ],
            },
            {
                label: 'Chart',
                backgroundColor: isDarkMode ? '#10461E' : '#335A3D',
                yAxisID: 'yAxisB',
                // @ts-ignore
                type: 'bar',
                data: numberdDataPoints.length < 1
                    ? [null]
                    : [
                        null,
                        ...numberdDataPoints[0],
                        null,
                    ],
            },
        ],
    }
}