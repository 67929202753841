import { useState } from "react"
import { fuzzySearch, SelectSearchOption } from "react-select-search"
import { HealthType } from "../../../../entities/api/HealthType"
import { HealthTypes } from "../../../../entities/api/HealthTypes"
import { DashboardComponent } from "../../../../generated/graphql";
import { ComponentType, DashboardComponentInputConfigValue } from "../../../../generated/graphql";
import { makeAllComponentTimePeriods } from "../../../../models/ComponentTimePeriod"
import { DashboardUtils } from "../../../../models/DashboardUtils"
import { healthTypeIdentifiers } from "../../../../models/datasources/HealthTypeIdentifier"
import { Card } from "../../../core/card/Card"
import { HESelectSearch } from "../../../core/HESelectSearch/HESelectSearch"
import { DataTypeSelectModal } from "../components/DataTypeSelectModal"
import { EditBarOrLineChartConfigurationRows } from "./EditBarOrLineChartConfigurationRows"
import { EditStackedBarLineChartConfigurationRow } from "./EditStackedBarLineChartConfigurationRow"
import { WorkoutConfiguration } from "./WorkoutConfiguration"
import "./EditComponentRow.css"
import { EditGaugeChartConfigurationRows } from "./EditGaugeChartConfigurationRows";

export const COMPONENT_TIME_PERIOD_CONFIGURATION_KEY = 'TIME_PERIOD';
export interface EditComponentRowProps {
    component: DashboardComponent,
    dataTypes: HealthTypes,
    changeComponentHealthTypeAction: (componentUuid: string, newType: HealthType, index: number) => void,
    changeComponentTypeAction: (componentUuid: string, newType: ComponentType) => void,
    changeComponentConfigurationAction: (componentUuid: string, configurations: DashboardComponentInputConfigValue[]) => void,
    deleteAction: (dashboardUuid: string) => void,
}

export const EditComponentRow: React.FC<EditComponentRowProps> = (props: EditComponentRowProps) => {
    const dataType = makeHealthType(props.component, props.dataTypes);
    const [isTypePickerShown, setIsTypePickerShown] = useState(false);
    const componentTypeSelectOptions = makeComponentTypeSelectOptions();
    const componentTimePeriodSelectOptions = makeComponentTimePeriodOptions();
    
    function changeDataTypeAction() {
        setIsTypePickerShown(true);
    }

    function typeSelectedAction(newType: HealthType) {
        setIsTypePickerShown(false);
        props.changeComponentHealthTypeAction(props.component.componentUuid, newType, 0);
    }

    function componentTypeChangedAction(option: SelectSearchOption | SelectSearchOption[]) {
        // @ts-expect-error
        const selectedType = option as ComponentType;
        if (selectedType !== props.component.type) {
            const componentUuid = props.component.componentUuid;
            props.changeComponentTypeAction(componentUuid, selectedType);
        }
    }

    function componentTimePeriodChangedAction(option: SelectSearchOption | SelectSearchOption[]) {
        // @ts-expect-error
        const selectedTimePeriodId = option as string;
        if (selectedTimePeriodId !== makeCurrentComponentTimePeriodId(props.component) && !Number.isNaN(selectedTimePeriodId)) {
            patchComponentConfiguration([
                {
                    key: COMPONENT_TIME_PERIOD_CONFIGURATION_KEY,
                    value: selectedTimePeriodId,
                }
            ])
        }
    }

    function patchComponentConfiguration(configurations: DashboardComponentInputConfigValue[]) {
        const oldConfiguration = props.component.configuration;
        let newConfiguration: DashboardComponentInputConfigValue[] = [];
        let addedKeys = new Set<string>();

        oldConfiguration.forEach(confValue => {
            const newVal = configurations.find(x => x.key === confValue.key);
            if (!newVal || newVal.value !== '') {
                newConfiguration.push({
                    key: confValue.key,
                    value: newVal?.value ?? confValue.value,
                });
                addedKeys.add(confValue.key);
            }
        })

        configurations
            .filter(x => !addedKeys.has(x.key))
            .filter(x => x.value.length !== 0)
            .forEach(confValue => newConfiguration.push(confValue));

        props.changeComponentConfigurationAction(props.component.componentUuid, newConfiguration)
    }

    return (
        <>
            <div className="row addComponentButtonWrapper">
                <Card>
                    <div className="componentEditTopRow">
                        <div className="componentTypeWrap">
                            <p>{ dataType?.name ?? '-' }</p>
                            <a href="#" onClick={changeDataTypeAction}><i className="material-icons">edit</i></a>
                        </div>
                        <button
                            type="button"
                            className="btn btn-fab"
                            onClick={() => props.deleteAction(props.component.componentUuid)}
                            ><i className="material-icons">delete</i></button>
                    </div>
                    <div className="topPickersWrapper">
                        <div className="typeSelectWrapper">
                            <p>Chart type:</p>
                            <HESelectSearch
                                    options={componentTypeSelectOptions}
                                    search
                                    filterOptions={fuzzySearch}
                                    emptyMessage="Not found"
                                    placeholder="Select type"
                                    onChange={componentTypeChangedAction}
                                    value={props.component.type}
                                />
                        </div>
                        <div className="timePeriodWrapperSelectWrapper">
                            <p>Time period:</p>
                            <HESelectSearch
                                    options={componentTimePeriodSelectOptions}
                                    search
                                    filterOptions={fuzzySearch}
                                    emptyMessage="Not found"
                                    placeholder="Select time period"
                                    onChange={componentTimePeriodChangedAction}
                                    value={makeCurrentComponentTimePeriodId(props.component)}
                                />
                        </div>
                        { dataType?.id === healthTypeIdentifiers.workouts &&
                            <WorkoutConfiguration
                                component={props.component}
                                patchConfiguration={patchComponentConfiguration}
                            />
                        }
                    </div>
                    { props.component.type === ComponentType.BarOrLineChart &&
                        <EditBarOrLineChartConfigurationRows
                                component={props.component}
                                patchConfiguration={patchComponentConfiguration}
                            />
                    }
                    { props.component.type === ComponentType.StackedBarLineChart &&
                        <EditStackedBarLineChartConfigurationRow
                                component={props.component}
                                patchConfiguration={patchComponentConfiguration}
                                changeComponentHealthTypeAction={props.changeComponentHealthTypeAction}
                            />
                    }
                    { props.component.type === ComponentType.Gauge &&
                        <EditGaugeChartConfigurationRows
                                component={props.component}
                                patchConfiguration={patchComponentConfiguration}
                            />
                    }
                </Card>
            </div>
            <DataTypeSelectModal 
                    defaultHealthType={dataType ?? undefined}
                    healthTypes={props.dataTypes}
                    typeSelectedAction={typeSelectedAction}
                    isDisplayed={isTypePickerShown}
                    setIsDisplayed={setIsTypePickerShown}
                />
        </>
    )
}

function makeHealthType(component: DashboardComponent, dataTypes: HealthTypes): HealthType | null {
    if (component.dataTypeIds.length >= 1) {
        let types = dataTypes.aggregated
            .concat(dataTypes.recordBased)
            .flatMap(category => category.types)
        
        if (dataTypes.workouts) {
            types.push(dataTypes.workouts);
        }

        return types.find(type => type.id === component.dataTypeIds[0]) ?? null;
    } else {
        return null;
    }
}

function makeComponentTypeSelectOptions(): SelectSearchOption[] {
    return [
            ComponentType.BarOrLineChart,
            ComponentType.Gauge,
            ComponentType.StackedBarLineChart,
            ComponentType.TotalValue,
            ComponentType.Table,
        ]
        .map(type => ({
            name: DashboardUtils.componentTypeName(type),
            value: type,
        }))
}

function makeComponentTimePeriodOptions(): SelectSearchOption[] {
    return makeAllComponentTimePeriods()
        .map(timePeriod => ({
            name: timePeriod.title,
            value: `${timePeriod.id}`,
        }))
}

function makeCurrentComponentTimePeriodId(component: DashboardComponent): string {
    return component.configuration.find(x => x.key === COMPONENT_TIME_PERIOD_CONFIGURATION_KEY)?.value ?? '0';
}