import { useMutation } from "@apollo/client"
import { useState } from "react"
import { HealthType } from "../../../entities/api/HealthType"
import { HealthTypes } from "../../../entities/api/HealthTypes"
import { ComponentType, DashboardComponentInputConfigValue, DashboardWithComponentsFragment, PutDashboardComponentsMutation, PutDashboardComponentsMutationVariables } from "../../../generated/graphql";
import { useConfigurationSavedToast } from "../../../hooks/UseConfigurationSavedToast";
import { useUserId } from "../../../hooks/UseUserId"
import { DashboardUtils } from "../../../models/DashboardUtils"
import { putDashboardComponents } from "../../../models/shared_gql/PutDashboardComponents"
import { LoadingFailedState } from "../../core/LoadingFailedState"
import { AddComponentRow } from "./rows/AddComponentRow"
import { EditComponentRow } from "./rows/EditComponentRow"
export interface DashboardComponentsEditorProps {
    dashboard: DashboardWithComponentsFragment,
    dataTypes: HealthTypes,
    setDashboard: (dashboard: DashboardWithComponentsFragment) => void,
    addComponent: (index: number) => void,
    deleteAction: (dashboardUuid: string) => void,
}

export const DashboardComponentsEditor: React.FC<DashboardComponentsEditorProps> = (props: DashboardComponentsEditorProps) => {

    const userId = useUserId();
    const [error, setError] = useState(null as Error | null);
    const [setDashboardComponents] = useMutation<PutDashboardComponentsMutation>(putDashboardComponents);
    const { showConfigurationSavedToast } = useConfigurationSavedToast();

    function performSetDashboardComponents(variables: PutDashboardComponentsMutationVariables) {
        setDashboardComponents({ variables:  variables })
            .then(result => {
                props.setDashboard(result.data!.putDashboardComponents!)
                showConfigurationSavedToast();
            })
            .catch(error => setError(error));
    }

    function changeComponentTypeAction(componentUuid: string, newType: ComponentType) {
        const components = DashboardUtils.transfromComponentsToMutationInput(props.dashboard.components);
        const componentToEdit = components.find(component => component.componentUuid === componentUuid);
        if (componentToEdit) {
            componentToEdit.type = newType;
            componentToEdit.dataTypeIds = componentToEdit.dataTypeIds.filter((_, index) => index < maximumChartTypeDataTypes(newType));
        }
        const mutationVariables: PutDashboardComponentsMutationVariables = {
            uid: userId,
            dashboardUuid: props.dashboard.dashboardUuid,
            components: components,
        };
        performSetDashboardComponents(mutationVariables);
    }

    function changeComponentHealthTypeAction(componentUuid: string, newType: HealthType, index: number) {
        const components = DashboardUtils.transfromComponentsToMutationInput(props.dashboard.components);
        const componentToEdit = components.find(component => component.componentUuid === componentUuid);
        if (componentToEdit) {
            const numberOfTypes = componentToEdit.dataTypeIds.length;
            if (numberOfTypes === 0 && index === 0) {
                componentToEdit.dataTypeIds = [newType.id];
            } else if (numberOfTypes === index) {
                componentToEdit.dataTypeIds.push(newType.id);
            } else if (numberOfTypes > index) {
                componentToEdit.dataTypeIds[index] = newType.id;
            }
        }
        const mutationVariables: PutDashboardComponentsMutationVariables = {
            uid: userId,
            dashboardUuid: props.dashboard.dashboardUuid,
            components: components,
        };
        performSetDashboardComponents(mutationVariables);
    }

    function changeComponentConfigurationAction(componentUuid: string, configurations: DashboardComponentInputConfigValue[]) {
        const components = DashboardUtils.transfromComponentsToMutationInput(props.dashboard.components);
        const componentToEdit = components.find(component => component.componentUuid === componentUuid);
        if (componentToEdit) {
            componentToEdit.configuration = configurations
        }
        const mutationVariables: PutDashboardComponentsMutationVariables = {
            uid: userId,
            dashboardUuid: props.dashboard.dashboardUuid,
            components: components,
        };
        performSetDashboardComponents(mutationVariables);
    }

    return (
        <>
            { error &&
                <LoadingFailedState />
            }
            { !error &&
                <div className="container-fluid">
                    <AddComponentRow onClickAction={() => props.addComponent(0)} />
                    { props.dashboard.components && props.dashboard.components.map((component, index) => (
                        <div key={component.componentUuid}>
                            <EditComponentRow 
                                    component={component}
                                    dataTypes={props.dataTypes}
                                    changeComponentHealthTypeAction={changeComponentHealthTypeAction}
                                    changeComponentTypeAction={changeComponentTypeAction}
                                    changeComponentConfigurationAction={changeComponentConfigurationAction}
                                    deleteAction={props.deleteAction}
                                />
                            <AddComponentRow
                                onClickAction={() => props.addComponent(index + 1)}
                                />
                        </div>
                    )) }
                </div>
            }
        </>
    )
}

function maximumChartTypeDataTypes(type: ComponentType): number {
    switch (type) {
        case ComponentType.BarOrLineChart:
            return 1;
        case ComponentType.StackedBarLineChart:
            return 2;
        case ComponentType.TotalValue:
            return 1;
        case ComponentType.Table:
            return 1;
        case ComponentType.Gauge:
            return 1;
    }
}