import { HealthType } from "../../../entities/api/HealthType";
import { HealthTypeCategory } from "../../../entities/api/HealthTypeCategory";
import { HealthTypes } from "../../../entities/api/HealthTypes"

export interface CsvExportTypeSelectDelegate {
    enableType(type: HealthType): void,
    disableType(type: HealthType): void,
}

export interface CsvExportTypeSelectProps {
    types: HealthTypes,
    enabledTypeIds: Set<number>,
    delegate: CsvExportTypeSelectDelegate,
}

type ExportFormSection = {
    name: string,
    categories: HealthTypeCategory[],
};

export const CsvExportTypeSelect: React.FC<CsvExportTypeSelectProps> = (props: CsvExportTypeSelectProps) => {
    const sections: ExportFormSection[] = [
        {
            name: 'Aggregated types',
            categories: props.types.aggregated,
        },
        {
            name: 'Record types',
            categories: props.types.recordBased,
        },
    ]
    return (
        <div id="exportTypeForm">
            {
                sections.map(section => (
                    <div key={section.name}>
                        <h4 className="topCategoryExportTypesForm"><b>{section.name}</b></h4>
                        {
                            section.categories.map(category => (
                                    <div key={section.name + category.name}>
                                        <h4>{category.name}</h4>
                                        {
                                            category.types.map(type => (
                                                <div className="form-check" key={`type${type.id}`}>
                                                    <label className="form-check-label">
                                                        <input 
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={props.enabledTypeIds.has(type.id)}
                                                            onChange={event => {
                                                                if (event.target.checked) {
                                                                    props.delegate.enableType(type);
                                                                } else {
                                                                    props.delegate.disableType(type);
                                                                }
                                                            }}
                                                        ></input>
                                                        {type.name}
                                                        <span className="form-check-sign">
                                                            <span className="check"></span>
                                                        </span>
                                                    </label>
                                                </div>
                                            ))
                                        }
                                    </div>
                            ))
                        }
                    </div>
                ))
            }
        </div>
    )
}