import { useState } from "react";

export interface AddConnectedBusinessAccountModalProps {
    connectAccountHandler: (name: string, key: string) => void,
}

export const AddConnectedBusinessAccountModal: React.FC<AddConnectedBusinessAccountModalProps> = (props: AddConnectedBusinessAccountModalProps) => {
    const [name, setName] = useState("");
    const [key, setKey] = useState("");
    return (
        <div className="modal fade" id="addBusinessAccountModal" role="dialog" aria-labelledby="addAccountModalLabel" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="addAccountModalLabel">Add user (license)</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <form>
                        <div className="form-group">
                            <label className="col-form-label">Name:</label>
                            <input type="text" className="form-control" value={name} onChange={(event) => setName(event.target.value)} />
                        </div>
                        <div className="form-group">
                            <label className="col-form-label">License key:</label>
                            <input type="text" className="form-control" value={key} onChange={(event) => setKey(event.target.value)} />
                        </div>
                    </form>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                    <button 
                        type="button"
                        className="btn btn-success addConnectedAccountButton"
                        data-dismiss="modal"
                        disabled={!(name !== '' && key !== '')}
                        onClick={() => {
                            props.connectAccountHandler(name, key)
                            setName('');
                            setKey('');
                        }}
                        >Save</button>
                </div>
                </div>
            </div>
        </div>
    )
}