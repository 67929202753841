import { ChartData } from "chart.js";
import { Bar } from "react-chartjs-2"
import { useSelector } from "react-redux";
import { DashboardComponentConfigurationValue } from "../../../../../generated/graphql";
import { AggregationPeriod } from "../../../../../models/AggregationPeriod";
import { BarOrLineChartConfigurationKey } from "../../../../../models/chart_configuration_keys/BarOrLineChartConfigurationKey";
import { HealthDataCollection } from "../../../../../models/datasources/HERemoteApiService";
import { RootState } from "../../../../../Store";
import { ChartUtils } from "./ChartUtils";

export interface BarChartProps {
    data: HealthDataCollection[],
    aggregationPeriod: AggregationPeriod,
    configuration: DashboardComponentConfigurationValue[],
}

export const BarChart: React.FC<BarChartProps> = (props: BarChartProps) => {
    const chartData = makeChartData(props.data[0]);
    const preferences = useSelector((state: RootState) => state.preferencesReducer.preferences);

    return (
        <Bar
                data={chartData}
                height={ChartUtils.makeChartHeight()}
                options={{
                    plugins: {
                        legend: {
                            display: false,
                        },
                        tooltip: {
                            callbacks: {
                                label: (data: any) => ChartUtils.tooltipCallback(data, preferences.numberFormat, props.data[0].units),
                            }
                        }
                    },
                    aspectRatio: 2,
                    scales: {
                        xAxis: {
                            type: 'time',
                            time: {
                                unit: makeXAxisTimeUnit(props.aggregationPeriod),
                                displayFormats: {
                                    second: ChartUtils.makeDateFormatSeconds(preferences.timeFormat),
                                    minute: ChartUtils.makeDateFormatMinutesAndHours(preferences.timeFormat),
                                    hour: ChartUtils.makeDateFormatMinutesAndHours(preferences.timeFormat),
                                }
                            }
                        },
                        yAxis: {
                            min: makeYMin(props.configuration),
                            max: makeYMax(props.configuration),
                        },
                    },
                }}
            />
    )
}

function makeChartData(data: HealthDataCollection): ChartData<"bar", (number | null)[], unknown> {
    return {
        labels: data.records.map(dataPoint => new Date(dataPoint.time)),
        datasets: [{
            label: 'Chart',
            backgroundColor: '#66bb6a',
            borderColor: '#43a047',
            data: data.records.map(dataPoint => {
                if (typeof dataPoint.value === 'string') {
                    return parseFloat(dataPoint.value);
                } else {
                    return dataPoint.value as number;
                }
            })
        }],
    }
}

type XAxisTimeUnit = false | "millisecond" | "second" | "minute" | "hour" | "day" | "week" | "month" | "quarter" | "year" | undefined;

export function makeXAxisTimeUnit(aggregationPeriod: AggregationPeriod): XAxisTimeUnit {
    switch (aggregationPeriod) {
        case AggregationPeriod.hours:
            return 'hour';
        case AggregationPeriod.days:
            return 'day';
        case AggregationPeriod.months:
            return 'month';
        case AggregationPeriod.years:
            return 'year';
    }
}

function makeYMin(configuration: DashboardComponentConfigurationValue[]): number | undefined {
    const rawValue = configuration.find(x => x.key === BarOrLineChartConfigurationKey.Y_MIN)?.value;
    if (rawValue) {
        return parseFloat(rawValue);
    } else {
        return undefined;
    }
}

function makeYMax(configuration: DashboardComponentConfigurationValue[]): number | undefined {
    const rawValue = configuration.find(x => x.key === BarOrLineChartConfigurationKey.Y_MAX)?.value;
    if (rawValue) {
        return parseFloat(rawValue);
    } else {
        return undefined;
    }
}