import "./StateMessage.css";

export interface StateMessageProps {
    title: string,
    messages: string[],
}

export const StateMessage: React.FC<StateMessageProps> = (props: StateMessageProps) => {
    return (
        <>
            <p className="stateMessageTitle">{props.title}</p>
            { 
                props.messages.map(message => (
                    <p key={message} className="stateMessageSubtitle" dangerouslySetInnerHTML={{ __html: message }}></p>
                ))
            }
        </>
    )
}