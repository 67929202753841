import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useScript, UseScriptStatus } from '../../../hooks/UseScript';
import { parseJwt } from '../../../models/JwtParser';
import { loginUsecase } from '../../../models/use_cases/LoginUsecase';
import './he_original_login.css';

function initializeSignInWithApple(isBusinessLogin: boolean) {
    document.addEventListener('AppleIDSignInOnSuccess', (event: Event) => handleAppleSignInResponse(event));
    AppleID.auth.init({
        clientId : isBusinessLogin ? 'app.healthexport.business' : 'cz.tomasparizek.HealthExport.Remote',
        // eslint-disable-next-line no-restricted-globals
        redirectURI : `https://${location.hostname}/dashboard`,
        usePopup: true,
    });
}

function handleAppleSignInResponse(event: Event) {
    // @ts-ignore
    const jwtString = event.detail.authorization.id_token as string;
    const jwt = parseJwt(jwtString);
    finishLogin(jwt.sub);
}

function finishLogin(sub: string) {
    loginUsecase(sub);
    window.location.href = '/';
}

type LoginPageParams = {
    sub: string | undefined,
}

export const BusinessLoginPage: React.FC = () => {
    return (
        <LoginPage isBusinessLogin={true} />
    )
}

export interface LoginPageProps {
    isBusinessLogin?: boolean,
}

export const LoginPage: React.FC<LoginPageProps> = (props: LoginPageProps) => {
    const appleSignInScriptStatus = useScript("https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js");
    useEffect(() => {
        if (appleSignInScriptStatus === UseScriptStatus.ready) {
            initializeSignInWithApple(props.isBusinessLogin ?? false);
        }
    }, [appleSignInScriptStatus, props.isBusinessLogin]);
    let { sub } = useParams<LoginPageParams>();
    useEffect(() => {
        if (sub && sub.length > 0) {
            finishLogin(sub);
        }
    });
    return (
        <div className="loginBody">
            <div className="loginWrapper">
                <div className="loginWrapDiv">
                    <h1 style={{ fontSize: '2rem', fontWeight: 'bold', marginBottom: '21px' }}>HealthExport Remote</h1>
                    <p style={{ color: '#000000', marginTop: '16px', marginBottom: '8px', fontWeight: 'normal' }}>Get remote access to health data on your iPhone.</p>
                    <p style={{ color: '#000000', marginTop: '8px', marginBottom: '0px', fontWeight: 'normal' }}>For more information visit <a href="https://healthexport.app/">HealthExport</a>.</p>
                    <div id="appleid-signin" data-mode="center-align" data-type="sign-in" data-color="black" data-border="false" data-border-radius="15" data-width="200" data-height="50"></div>
                    <p className="loginLegalInfo">By click on the Sign in button you agree to the <a href="https://remote.healthexport.app/privacypolicy">Terms of Service and Privacy Policy</a>.</p>
                    <img className="loginSitePreview" src="/images/remote_preview.png" alt="HealthExport Remote site preview" />
                </div>
            </div>
        </div>
    );
}