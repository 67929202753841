import moment from "moment"
import { AggregationPeriod } from "./AggregationPeriod"
import { DateUtils } from "./DateUtils"

export type DatePreset = {
    id: number,
    title: string,
    dateFrom: Date,
    dateTo: Date,
    aggregationPeriod?: AggregationPeriod,
}

export const presetsWithAggregation: DatePreset[] = [
    {
        id: 0,
        title: 'Today by hours',
        dateFrom: DateUtils.removeTimeFromDate(new Date()),
        dateTo: DateUtils.setTimeToSecondBeforeMidnight(new Date()),
        aggregationPeriod: AggregationPeriod.hours,
    },
    {
        id: 1,
        title: 'Last 2 days by hours',
        dateFrom: DateUtils.removeTimeFromDate(DateUtils.yesterday(new Date())),
        dateTo: DateUtils.setTimeToSecondBeforeMidnight(new Date()),
        aggregationPeriod: AggregationPeriod.hours,
    },
    {
        id: 2,
        title: 'Last 7 days by days',
        dateFrom: DateUtils.removeTimeFromDate(DateUtils.removeDays(7, new Date())),
        dateTo: DateUtils.setTimeToSecondBeforeMidnight(new Date()),
        aggregationPeriod: AggregationPeriod.days,
    },
    {
        id: 3,
        title: 'Last 30 days by days',
        dateFrom: DateUtils.removeTimeFromDate(DateUtils.removeDays(30, new Date())),
        dateTo: DateUtils.setTimeToSecondBeforeMidnight(new Date()),
        aggregationPeriod: AggregationPeriod.days,
    },
    {
        id: 4,
        title: 'Last 180 days by days',
        dateFrom: DateUtils.removeTimeFromDate(DateUtils.removeDays(180, new Date())),
        dateTo: DateUtils.setTimeToSecondBeforeMidnight(new Date()),
        aggregationPeriod: AggregationPeriod.days,
    },
    {
        id: 5,
        title: 'Last 180 days by months',
        dateFrom: DateUtils.removeTimeFromDate(DateUtils.removeDays(180, new Date())),
        dateTo: DateUtils.setTimeToSecondBeforeMidnight(new Date()),
        aggregationPeriod: AggregationPeriod.months,
    },
    {
        id: 6,
        title: 'Last 365 days by days',
        dateFrom: DateUtils.removeTimeFromDate(DateUtils.removeDays(365, new Date())),
        dateTo: DateUtils.setTimeToSecondBeforeMidnight(new Date()),
        aggregationPeriod: AggregationPeriod.days,
    },
    {
        id: 7,
        title: 'Last 365 days by months',
        dateFrom: DateUtils.removeTimeFromDate(DateUtils.removeDays(365, new Date())),
        dateTo: DateUtils.setTimeToSecondBeforeMidnight(new Date()),
        aggregationPeriod: AggregationPeriod.months,
    },
]

export const presetsWithoutAggregation: DatePreset[] = [
    {
        id: 100,
        title: 'Today',
        dateFrom: DateUtils.removeTimeFromDate(new Date()),
        dateTo: DateUtils.setTimeToSecondBeforeMidnight(new Date()),
    },
    {
        id: 101,
        title: 'Last 7 days',
        dateFrom: DateUtils.removeTimeFromDate(DateUtils.removeDays(7, new Date())),
        dateTo: DateUtils.setTimeToSecondBeforeMidnight(new Date()),
    },
    {
        id: 102,
        title: 'Last 14 days',
        dateFrom: DateUtils.removeTimeFromDate(DateUtils.removeDays(14, new Date())),
        dateTo: DateUtils.setTimeToSecondBeforeMidnight(new Date()),
    },
    {
        id: 103,
        title: 'Last 30 days',
        dateFrom: DateUtils.removeTimeFromDate(DateUtils.removeDays(30, new Date())),
        dateTo: DateUtils.setTimeToSecondBeforeMidnight(new Date()),
    },
    {
        id: 104,
        title: 'Last 180 days',
        dateFrom: DateUtils.removeTimeFromDate(DateUtils.removeDays(180, new Date())),
        dateTo: DateUtils.setTimeToSecondBeforeMidnight(new Date()),
    },
    {
        id: 105,
        title: 'Last 365 days',
        dateFrom: DateUtils.removeTimeFromDate(DateUtils.removeDays(365, new Date())),
        dateTo: DateUtils.setTimeToSecondBeforeMidnight(new Date()),
    },
    {
        id: 106,
        title: 'This month',
        dateFrom: moment().startOf('month').toDate(),
        dateTo: moment().endOf('month').toDate(),
    },
    {
        id: 107,
        title: 'Last month',
        dateFrom: moment().add(-1, 'month').startOf('month').toDate(),
        dateTo: moment().add(-1, 'month').endOf('month').toDate(),
    },
    {
        id: 108,
        title: 'This year',
        dateFrom: moment().startOf('year').toDate(),
        dateTo: moment().endOf('year').toDate(),
    },
    {
        id: 109,
        title: 'Last year',
        dateFrom: moment().add(-1,'year').startOf('year').toDate(),
        dateTo: moment().add(-1,'year').endOf('year').toDate(),
    },
]