import { useSelector } from "react-redux";
import { formatNumber } from "../../../../models/UserUtils";
import { RootState } from "../../../../Store";

export interface WorkoutListTotalStatsItemProps {
    iconUrl: string,
    invertedInDarkMode: boolean,
    dataTypeName: string,
    value: number,
    valueFormatted?: string,
}

export const WorkoutListTotalStatsItem: React.FC<WorkoutListTotalStatsItemProps> = (props: WorkoutListTotalStatsItemProps) => {
    const preferencesReducer = useSelector((state: RootState) => state.preferencesReducer);
    return (
        <div className="workoutMetaInfo">
            <img src={props.iconUrl} className={ props.invertedInDarkMode ? 'invertedImageInDarkMode' : '' } alt='' />
            <div>
                <p className="workoutMetaInfoLabel">{props.dataTypeName}</p>
                <p className="workoutMetaValueLabel">{
                    props.valueFormatted ?? formatNumber(
                        props.value,
                        2,
                        preferencesReducer.preferences.numberFormat,
                    )
                }</p>
            </div>
        </div>
    )
}