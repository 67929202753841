import { NumberFormat, TimeFormat } from "../../../../../generated/graphql";
import { formatNumber } from "../../../../../models/UserUtils";

export const ChartUtils = {
    makeChartHeight(): number {
        return 100;
    },
    tooltipCallback(data: any, numberFormat: NumberFormat, units: string): string {
        const value = data.parsed.y as number
        const formatedValue = formatNumber(
            value,
            1,
            numberFormat
        )
        return `${formatedValue} ${units}`;
    },
    makeDateFormatSeconds(timeFormat: TimeFormat): string {
        if (timeFormat === TimeFormat.Format12h) {
            return 'MMM D h:mm:ss a';
        } else {
            return 'MMM D HH:mm:ss';
        }
    },
    makeDateFormatMinutesAndHours(timeFormat: TimeFormat): string {
        if (timeFormat === TimeFormat.Format12h) {
            return 'MMM D h:mm a';
        } else {
            return 'MMM D HH:mm';
        }
    }
}