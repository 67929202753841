import { safeAt } from "../../../models/ArrayUtils";
import { HealthDataCollection } from "../../../models/datasources/HERemoteApiService"
import { DateUtils } from "../../../models/DateUtils";
import { NutritionChart } from "./NutritionChart";

export interface NutritionTodayProps {
    protein: HealthDataCollection | null,
    fats: HealthDataCollection | null,
    carbohydrates: HealthDataCollection | null,
}

export const NutritionToday: React.FC<NutritionTodayProps> = (props: NutritionTodayProps) => {

    const proteinValue = dataCollectionToValue(props.protein);
    const fatsValue = dataCollectionToValue(props.fats);
    const carbohydratesValue = dataCollectionToValue(props.carbohydrates);
    const units = makeUnits(props);

    return (
        <div className="row nutritionTodayWrap">
            <NutritionChart
                protein={proteinValue}
                fats={fatsValue}
                carbohydrates={carbohydratesValue}
                units={units}
            />
        </div>
    )
}

function dataCollectionToValue(collection: HealthDataCollection | null): number {
    if (collection === null) {
        return 0;
    } else {
        let todayDateWithoutTime = DateUtils.removeTimeFromDate(new Date());
        let todayRecords = collection
            .records
            .filter(record => new Date(record.time).getTime() === todayDateWithoutTime.getTime());
        const value = safeAt(todayRecords, 0)?.value;
        if (!value ) {
            return 0;
        } else {
            if (typeof value === 'number') {
                return value;
            } else {
                return parseFloat(value);
            }
        }
    }
}

function makeUnits(props: NutritionTodayProps): string {
    return props.protein?.units ?? props.fats?.units ?? props.carbohydrates?.units ?? '';
}