import { useState } from "react";
import { DashboardComponent } from "../../generated/graphql";
import { DashboardComponentInputConfigValue } from "../../generated/graphql";
import { useDebouncedEffect } from "../UseDebouncedEffect";
import { StackedBarLineChartConfigurationKey } from "../../models/chart_configuration_keys/StackedBarLineChartConfigurationKey";

export type UseEditStackedBarLineChartConfigurationRowData = {
    yMainMin: string | null,
    yMainMax: string | null,
    ySecondaryMin: string | null,
    ySecondaryMax: string | null,
    setYMainMin:(value: string | null) => void,
    setYMainMax: (value: string | null) => void,
    setYSecondaryMin: (value: string | null) => void,
    setYSecondaryMax: (value: string | null) => void,
}

export function useEditStackedBarLineChartConfigurationRowData(
    component: DashboardComponent,
    patchConfiguration: (configurations: DashboardComponentInputConfigValue[],
) => void): UseEditStackedBarLineChartConfigurationRowData {

    const defaultYMainMin = component.configuration.find(x => x.key === StackedBarLineChartConfigurationKey.Y_MAIN_MIN)?.value ?? null;
    const defaultYMainMax = component.configuration.find(x => x.key === StackedBarLineChartConfigurationKey.Y_MAIN_MAX)?.value ?? null;
    const defaultYSecondaryMin = component.configuration.find(x => x.key === StackedBarLineChartConfigurationKey.Y_SECONDARY_MIN)?.value ?? null;
    const defaultYSecondaryMax = component.configuration.find(x => x.key === StackedBarLineChartConfigurationKey.Y_SECONDARY_MAX)?.value ?? null;
    
    const [yMainMin, setYMainMin] = useState(defaultYMainMin);
    const [yMainMax, setYMainMax] = useState(defaultYMainMax);
    const [ySecondaryMin, setYSecondaryMin] = useState(defaultYSecondaryMin);
    const [ySecondaryMax, setYSecondaryMax] = useState(defaultYSecondaryMax);

    useDebouncedEffect(() => {
        const hasChange = 
            defaultYMainMin !== yMainMin ||
            defaultYMainMax !== yMainMax ||
            defaultYSecondaryMin !== ySecondaryMin ||
            defaultYSecondaryMax !== ySecondaryMax;
            
        if (hasChange) {
            var configurationValues: DashboardComponentInputConfigValue[] = [];
            const values: [StackedBarLineChartConfigurationKey, string | null][] = [
                [StackedBarLineChartConfigurationKey.Y_MAIN_MIN, yMainMin],
                [StackedBarLineChartConfigurationKey.Y_MAIN_MAX, yMainMax],
                [StackedBarLineChartConfigurationKey.Y_SECONDARY_MIN, ySecondaryMin],
                [StackedBarLineChartConfigurationKey.Y_SECONDARY_MAX, ySecondaryMax],
            ]
            values.forEach(value => {
                configurationValues.push({
                    key: value[0],
                    value: value[1] ?? '',
                });
            });
            patchConfiguration(configurationValues);
        }
    }, [yMainMin, yMainMax, ySecondaryMin, ySecondaryMax], 500);

    return {
        yMainMin: yMainMin,
        yMainMax: yMainMax,
        ySecondaryMin: ySecondaryMin,
        ySecondaryMax: ySecondaryMax,
        setYMainMin: setYMainMin,
        setYMainMax: setYMainMax,
        setYSecondaryMin: setYSecondaryMin,
        setYSecondaryMax: setYSecondaryMax,
    }
}