import { useState } from "react";
import { useSelector } from "react-redux";
import { HealthType } from "../entities/api/HealthType";
import { AggregationPeriod } from "../models/AggregationPeriod";
import { HealthTypeRepository } from "../models/datasources/HealthTypeRepository";
import { HealthDataCollection } from "../models/datasources/HERemoteApiService";
import { RootState } from "../Store";
import { DateInterval, makeThisWeekDateInterval } from "./UseDateInterval";
import { useHealthData, UseHealthDataStatus } from "./UseHealthData";

export enum UseNutritionDataState {
    loading,
    error,
    data,
}

export type UseNutritionData = {
    state: UseNutritionDataState,
    proteinThisWeek: HealthDataCollection[] | null,
    fatThisWeek: HealthDataCollection[] | null,
    carbohydratesThisWeek: HealthDataCollection[] | null,
    proteinHistory: HealthDataCollection[] | null,
    fatHistory: HealthDataCollection[] | null,
    carbohydratesHistory: HealthDataCollection[] | null,
}

// Warning: Bellow declared consts need to be outside the `useNutritionData` function!
// If consts are in the function, then they are regenerated on each render, which causes an infinite loop in the `useHealthData`.
const proteinType = makeProteinType();
const fatType = makeFatType();
const carbohydratesType = makeCarbohydratesType();

export function useNutritionData(dateInterval: DateInterval): UseNutritionData {
    const firstWeekDay = useSelector((state: RootState) => state).preferencesReducer.preferences.firstWeekDay;
    const [thisWeekDayInterval] = useState(makeThisWeekDateInterval(firstWeekDay));

    // Todat data
    const proteinThisWeek = useHealthData(proteinType, AggregationPeriod.days, null, null, thisWeekDayInterval);
    const fatThisWeek = useHealthData(fatType, AggregationPeriod.days, null, null, thisWeekDayInterval);
    const carbohydratesThisWeek = useHealthData(carbohydratesType, AggregationPeriod.days, null, null, thisWeekDayInterval);

    // // Selected date interval data
    const proteinHistory = useHealthData(proteinType, AggregationPeriod.days, null, null, dateInterval);
    const fatHistory = useHealthData(fatType, AggregationPeriod.days, null, null, dateInterval);
    const carbohydratesHistory = useHealthData(carbohydratesType, AggregationPeriod.days, null, null, dateInterval);

    // Error / loading state check
    const states = new Set([
        proteinThisWeek.status,
        fatThisWeek.status,
        carbohydratesThisWeek.status,
        proteinHistory.status,
        fatHistory.status,
        carbohydratesHistory.status,
    ]);
    if (states.has(UseHealthDataStatus.error)) {
        return makeEmptyDataWithState(UseNutritionDataState.error);
    }
    if (states.has(UseHealthDataStatus.loading)) {
        return makeEmptyDataWithState(UseNutritionDataState.loading);
    }

    // Data is loaded
    return {
        state: UseNutritionDataState.data,
        proteinThisWeek: proteinThisWeek.data,
        fatThisWeek: fatThisWeek.data,
        carbohydratesThisWeek: carbohydratesThisWeek.data,
        proteinHistory: proteinHistory.data,
        fatHistory: fatHistory.data,
        carbohydratesHistory: carbohydratesHistory.data,
    }
}

function makeEmptyDataWithState(state: UseNutritionDataState): UseNutritionData {
    return {
        state: state,
        proteinThisWeek: null,
        fatThisWeek: null,
        carbohydratesThisWeek: null,
        proteinHistory: null,
        fatHistory: null,
        carbohydratesHistory: null,
    }
}

// Type factory
function makeProteinType(): HealthType {
    return HealthTypeRepository.getTypes()
        .aggregated
        .flatMap(category => category.types)
        .find(type => type.id === 39)!;
}

function makeFatType(): HealthType {
    return HealthTypeRepository.getTypes()
        .aggregated
        .flatMap(category => category.types)
        .find(type => type.id === 33)!;
}

function makeCarbohydratesType(): HealthType {
    return HealthTypeRepository.getTypes()
        .aggregated
        .flatMap(category => category.types)
        .find(type => type.id === 36)!;
}