import { healthTypeIdentifiers } from "../../models/datasources/HealthTypeIdentifier";

export enum SleepStage {
    inBed,
    asleep,
    awake,
    core,
    deep,
    rem,
}

export function sleepStageDataTypeId(stage: SleepStage): number {
    switch (stage) {
        case SleepStage.inBed:
            return healthTypeIdentifiers.timeSleepInBed;
        case SleepStage.asleep:
            return healthTypeIdentifiers.timeSleepAsleep;
        case SleepStage.awake:
            return healthTypeIdentifiers.timeSleepAwake;
        case SleepStage.core:
            return healthTypeIdentifiers.timeSleepCore;
        case SleepStage.deep:
            return healthTypeIdentifiers.timeSleepDeep;
        case SleepStage.rem:
            return healthTypeIdentifiers.timeSleepREM;
    }
}

export function sleepStageColorHex(stage: SleepStage): string {
    switch (stage) {
        case SleepStage.inBed:
            return '#7b809a';
        case SleepStage.asleep:
            return '#03a9f4';
        case SleepStage.awake:
            return '#F47D03';
        case SleepStage.core:
            return '#0079B0';
        case SleepStage.deep:
            return '#004D70';
        case SleepStage.rem:
            return '#1C99D1';
    }
}