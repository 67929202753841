import { useMutation } from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";
import { FirstWeekDay, NumberFormat, SetUserPreferencesMutation, SetUserPreferencesMutationVariables, TimeFormat, UserPreferencesInput } from "../../../generated/graphql";
import { RootState } from "../../../Store";
import { AppContainer } from "../../core/AppContainer";
import { SideBarItem } from "../../core/SideBar";
import { RadioSelectionList } from "./RadioSelectionList";
import { setPreferences as setReduxPreferences } from "../../../reducers/UserPreferencesReducer";
import { useHistory } from "react-router";
import { setUserPreferencesMutation } from "../../../models/shared_gql/SetUserPreferences";

export const SettingsPage: React.FC = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { preferencesReducer, accountReducer } = useSelector((state: RootState) => state);
    const preferences = preferencesReducer.preferences;

    const [setPreferences, { data }] = useMutation<SetUserPreferencesMutation>(setUserPreferencesMutation);
    if (data && (
            data.setUserPreferences.firstWeekDay !== preferences.firstWeekDay || 
            data.setUserPreferences.numberFormat !== preferences.numberFormat ||
            data.setUserPreferences.timeFormat !== preferences.timeFormat)) {
        dispatch(setReduxPreferences({
            __typename: "UserPreferences",
            timeFormat: data.setUserPreferences.timeFormat,
            numberFormat: data.setUserPreferences.numberFormat,
            firstWeekDay: data.setUserPreferences.firstWeekDay,
            businessAccessKey: data.setUserPreferences.businessAccessKey,
        }))
    }
    
    function handleFirstDayChange(selectedItem: FirstWeekDay) {
        setNewPreferences({
            firstWeekDay: selectedItem,
            timeFormat: preferences.timeFormat,
            numberFormat: preferences.numberFormat,
        });
    }

    function handleNumberFormatChange(selectedItem: NumberFormat) {
        setNewPreferences({
            firstWeekDay: preferences.firstWeekDay,
            timeFormat: preferences.timeFormat,
            numberFormat: selectedItem,
        });
    }

    function handleTimeFormatChange(selectedItem: TimeFormat) {
        setNewPreferences({
            firstWeekDay: preferences.firstWeekDay,
            timeFormat: selectedItem,
            numberFormat: preferences.numberFormat,
        });
    }

    function setNewPreferences(preferences: UserPreferencesInput) {
        const variables: SetUserPreferencesMutationVariables = {
            uid: accountReducer?.me.directAccess?.uid ?? '',
            preferences: preferences,
        };
        setPreferences({ variables: variables });
    }

    return (
        <AppContainer
            title="Settings"
            activeSideBarItem={SideBarItem.settings}
        >
            <div className="content">
                <div className="settingsContent">
                    <button
                        type="button"
                        className="btn"
                        style={{ marginTop: '30px', marginBottom: '10px' }}
                        onClick={() => history.push('/settings/sharing')}
                        >Data sharing</button>
                    <button
                        type="button"
                        className="btn"
                        style={{ marginTop: '30px', marginBottom: '10px', marginLeft: '20px' }}
                        onClick={() => history.push('/settings/business')}
                        >Business account</button>
                    <br />
                    <hr style={{ opacity: 0.5 }} />
                    <RadioSelectionList
                        selectionId="firstWeekDay"
                        title="First day of the week"
                        setSelectedItem={handleFirstDayChange}
                        items={[
                            {
                                id: FirstWeekDay.Sunday,
                                title: 'Sunday',
                                isSelected: preferences.firstWeekDay === FirstWeekDay.Sunday,
                            },
                            {
                                id: FirstWeekDay.Monday,
                                title: 'Monday',
                                isSelected: preferences.firstWeekDay === FirstWeekDay.Monday,
                            },
                        ]}
                    />
                    <RadioSelectionList
                        selectionId="numberFormat"
                        title="Number format"
                        setSelectedItem={handleNumberFormatChange}
                        items={[
                            {
                                id: NumberFormat.Us,
                                title: '68,356.9 kcal',
                                isSelected: preferences.numberFormat === NumberFormat.Us,
                            },
                            {
                                id: NumberFormat.Classic,
                                title: '68 356,9 kcal',
                                isSelected: preferences.numberFormat === NumberFormat.Classic,
                            },
                        ]}
                    />
                    <RadioSelectionList
                        selectionId="timeFormat"
                        title="Time format"
                        setSelectedItem={handleTimeFormatChange}
                        items={[
                            {
                                id: TimeFormat.Format12h,
                                title: '12h (AM/PM)',
                                isSelected: preferences.timeFormat === TimeFormat.Format12h,
                            },
                            {
                                id: TimeFormat.Format24h,
                                title: '24h',
                                isSelected: preferences.timeFormat === TimeFormat.Format24h,
                            },
                        ]}
                    />
                </div>
            </div>

        </AppContainer>
    );
}