import { useEffect, useState } from "react";

export function useIsDarkMode(): boolean {
    const [isDarkMode, setIsDarkMode] = useState(isDarkmodeActive())
    const [isListenerSetuped, setIsListenerSetuped] = useState(false)
    useEffect(() => {
        if (!isListenerSetuped) {
            setIsListenerSetuped(true)
            window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', _ => {
                setIsDarkMode(isDarkmodeActive())
            })
        }
    }, [isListenerSetuped])
    return isDarkMode;
}

function isDarkmodeActive(): boolean {
    return window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
}