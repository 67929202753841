import { DateIntervalData } from "../../../hooks/UseDateInterval"
import { DatePreset, presetsWithoutAggregation } from "../../../models/DatePreset"
import { DatePresetPicker } from "../../core/DatePresetPicker"
import { DashboardDatePicker } from "../dashboard/header/DashboardDatePicker"

export interface SleepHeaderProps {
    dateInterval: DateIntervalData,
}

export const SleepHeader: React.FC<SleepHeaderProps> = (props: SleepHeaderProps) => {

    function onPresetSelected(datePreset: DatePreset) {
        props.dateInterval.setInterval({
            from: datePreset.dateFrom,
            to: datePreset.dateTo,
        })
    }

    return (
        <div className="container-fluid row dashboardHeader">
            <p>Dates: </p>
            <DashboardDatePicker 
                    dateInterval={props.dateInterval}
                />
            <DatePresetPicker
                    presets={presetsWithoutAggregation}
                    onPresetSelected={onPresetSelected}
                />
        </div>
    )
}