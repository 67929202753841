import { UserPreferences } from "../generated/graphql";
import { UserInfo } from "../entities/UserInfo";
import { Account } from "../reducers/AccountReducer";

const localStoragePrefix = 'HE_REMOTE_';

export enum LocalStorageDataType {
    user = 'user',
    userPreferencesCache = 'userPreferencesCache',
    csvExportTypeIds = 'csvExportTypeIds',
    activeAccount = 'activeAccount',
    connectedAccountCache = 'connectedAccountCache',
    activeDashboardUUID = 'activeDashboardUUID',
    localStorageMigrationDone = 'localStorageMigrationDone',
}

export type LocalStorageData = 
    {
        type: LocalStorageDataType.user,
        value?: UserInfo,
    } | 
    {
        type: LocalStorageDataType.userPreferencesCache,
        value?: UserPreferences,
    } | 
    {
        type: LocalStorageDataType.csvExportTypeIds,
        value?: number[],
    }  | 
    {
        type: LocalStorageDataType.activeAccount,
        value?: Account,
    } | {
        type: LocalStorageDataType.connectedAccountCache,
        value?: Account[],
    } | {
        type: LocalStorageDataType.activeDashboardUUID,
        value?: string,
    } | {
        type: LocalStorageDataType.localStorageMigrationDone,
        value?: boolean,
    }

function set(value: LocalStorageData) {
    const key = localStoragePrefix + value.type;
    if (value.value) {
        localStorage.setItem(key, JSON.stringify(value.value));
    } else {
        localStorage.removeItem(key);
    }
}

function get<T>(key: LocalStorageDataType): T | null {
    const encodedValue = localStorage.getItem(localStoragePrefix + key);
    if (encodedValue && encodedValue !== null && encodedValue.length > 0) {
        return JSON.parse(encodedValue) as T;
    } else {
        return null;
    }
}

export const LocalStorageManager = {
    clear() {
        localStorage.clear()
    },

    setUser(user: UserInfo | null) {
        set({
            type: LocalStorageDataType.user,
            value: user ?? undefined,
        })
    },
    getUser(): UserInfo | null { return get<UserInfo>(LocalStorageDataType.user) },
    
    setUserPreferences(user: UserPreferences | null) {
        set({
            type: LocalStorageDataType.userPreferencesCache,
            value: user ?? undefined,
        })
    },
    getUserPreferences(): UserPreferences | null { return get<UserPreferences>(LocalStorageDataType.userPreferencesCache) },

    setCsvExportTypeIds(typeIds: number[] | null) {
        set({
            type: LocalStorageDataType.csvExportTypeIds,
            value: typeIds ?? undefined,
        })
    },
    getCsvExportTypeIds(): number[] | null { return get<number[]>(LocalStorageDataType.csvExportTypeIds) },

    setActiveAccount(activeAccount: Account | null) {
        set({
            type: LocalStorageDataType.activeAccount,
            value: activeAccount ?? undefined,
        })
    },
    getActiveAccount(): Account | null { return get<Account>(LocalStorageDataType.activeAccount) },

    setConnectedAccountCache(connectedAccounts: Account[] | null) {
        set({
            type: LocalStorageDataType.connectedAccountCache,
            value: connectedAccounts ?? undefined,
        })
    },
    getConnectedAccountCache(): Account[] | null { return get<Account[]>(LocalStorageDataType.connectedAccountCache) },

    setActiveDashboardUUID(dashboardUUID: string | null) {
        set({
            type: LocalStorageDataType.activeDashboardUUID,
            value: dashboardUUID ?? undefined,
        })
    },
    getActiveDashboardUUID(): string | null { return get<string>(LocalStorageDataType.activeDashboardUUID) },

    setLocalStorageMigrationDone(localStorageMigrationDone: boolean | null) {
        set({
            type: LocalStorageDataType.localStorageMigrationDone,
            value: localStorageMigrationDone ?? undefined,
        })
    },
    getLocalStorageMigrationDone(): boolean | null { return get<boolean>(LocalStorageDataType.localStorageMigrationDone) },
};