import moment from "moment";
import { HealthType } from "../entities/api/HealthType";
import { HealthTypes } from "../entities/api/HealthTypes";
import { HealthRecord } from "../entities/HealthRecord";
import { AggregationPeriod } from "./AggregationPeriod";
import { healthTypeIdentifiers } from "./datasources/HealthTypeIdentifier";
import { HealthDataCollection } from "./datasources/HERemoteApiService";
import { DateUtils } from "./DateUtils";

export enum AggregationMode {
    sum,
    average,
}

export const HealthDataAggregator = {
    isTypeAggregated(type: HealthType, dataTypes: HealthTypes): boolean {
        return HealthDataAggregator.isTypeIdAggregated(type.id, dataTypes);
    },
    isTypeIdAggregated(typeId: number, dataTypes: HealthTypes): boolean {
        if (typeId === healthTypeIdentifiers.workouts) {
            return true;
        }
        return dataTypes.aggregated
            .flatMap(category => category.types)
            .find(type => type.id === typeId)
            !== undefined;
    },
    aggregationModeForType(type: HealthType): AggregationMode {
        // Warning: when returning average check DashboardUtils -> chartTypeForComponent
        if (type.name.toLowerCase().includes('heart rate') || type.name.toLowerCase().includes('vo2 max')) {
            return AggregationMode.average;
        } else {
            return AggregationMode.sum;
        }
    },
    aggregate(type: HealthType, dataCollection: HealthDataCollection, fromDate: Date, toDate: Date, aggregationPeriod: AggregationPeriod): HealthDataCollection {
        const aggregationMode = HealthDataAggregator.aggregationModeForType(type);
        const hourhlyRecords = removeMinutesAndSecondsFromHealthData(dataCollection.records);
        const recordsWithZerosForNull = fillMissingValuesInHealthData(
            hourhlyRecords,
            fromDate,
            toDate
        );
        const aggregatedRecords = applyAggregationPeriodToHealthData(
            recordsWithZerosForNull,
            aggregationPeriod,
            aggregationMode,
        );
        return {
            records: aggregatedRecords,
            units: dataCollection.units,
        };
    }
};

function removeMinutesAndSecondsFromHealthData(originalRecords: HealthRecord[]): HealthRecord[] {
    originalRecords.forEach(record => {
        if (record.time.indexOf(':00:00.000') === -1) { // Check if minutes or seconds exists (performance optimization)
            record.time = moment(record.time).startOf('hour').toISOString();
        }
    });
    return originalRecords;
}

function fillMissingValuesInHealthData(originalRecords: HealthRecord[], fromDate: Date, toDate: Date): HealthRecord[] {
    let i = 0;
    let data: HealthRecord[] = [];
    let time = fromDate.getTime();
    const dateToTime = toDate.getTime();
    while(time < dateToTime) {
        let date = new Date(time);
        let key = date.toISOString();
        if (originalRecords.length > i && originalRecords[i].time === key) {
            data.push({ time: key, value: originalRecords[i].value });
            i++;
        }  else {
            data.push({ time: key, value: 0 });
        }
        if (originalRecords.length <= i || originalRecords[i].time !== key) {
            time += 60*60*1000
        }
    }
    return data;
}

function applyAggregationPeriodToHealthData(records: HealthRecord[], agregationPeriod: AggregationPeriod, aggregationMode: AggregationMode): HealthRecord[] {
    if (records.length === 0) {
        return records;
    }
    let originalTimestamps = records.map(x => new Date(x.time));
    let originalValues: number[] = records
        .map(x => x.value)
        .map(value => {
            if (typeof value === 'number') {
                return value
            } else {
                const parsedNumber = parseFloat(value);
                if (!Number.isNaN(parsedNumber)) {
                    return parsedNumber;
                } else {
                    return undefined;
                }
            }
        })
        .filter(value => value !== undefined) as number[];
    
    let newTimestamps = [originalTimestamps[0]];
    let newValues = [[originalValues[0]]];
    for (let i = 1; i < originalTimestamps.length; i++) {
        let samePeriodAsLastRecord = false;
        if (agregationPeriod === AggregationPeriod.hours && DateUtils.datesAreSameHour(newTimestamps[newTimestamps.length-1], originalTimestamps[i])) {
            samePeriodAsLastRecord = true;
        } else if (agregationPeriod === AggregationPeriod.days && DateUtils.datesAreSameDay(newTimestamps[newTimestamps.length-1], originalTimestamps[i])) {
            samePeriodAsLastRecord = true;
        } else if (agregationPeriod === AggregationPeriod.months && DateUtils.datesAreSameMonth(newTimestamps[newTimestamps.length-1], originalTimestamps[i])) {
            samePeriodAsLastRecord = true;
        } else if (agregationPeriod === AggregationPeriod.years && DateUtils.datesAreSameYear(newTimestamps[newTimestamps.length-1], originalTimestamps[i])) {
            samePeriodAsLastRecord = true;
        }
        if (!samePeriodAsLastRecord) {
            newTimestamps.push(originalTimestamps[i]);
            newValues.push([originalValues[i]]);
        } else {
            newValues[newValues.length - 1].push(originalValues[i]);
        }
    }
    let flatNewValues: number[];
    switch (aggregationMode) {
        case AggregationMode.sum:
            flatNewValues = newValues
                .map(x => x.reduce((accumulator, currentValue) => accumulator + currentValue));
            break;
        case AggregationMode.average:
            flatNewValues = newValues
                .map(values => {
                    const length = values
                        .filter(value => value !== 0)
                        .length;
                    const sum = values
                        .reduce((accumulator, currentValue) => accumulator + currentValue);
                    return sum / length;
                });
            break;
    }
    const formatedNewValues = flatNewValues.map(value => { return Number.isFinite(value) ? value.toFixed(3) : value })
    newTimestamps = newTimestamps.map(date => {
        switch (agregationPeriod) {
            case AggregationPeriod.hours:
                return moment(date).startOf('hour').toDate();
            case AggregationPeriod.days:
                return moment(date).startOf('days').toDate();
            case AggregationPeriod.months:
                return moment(date).startOf('month').toDate();
            case AggregationPeriod.years:
                return moment(date).startOf('year').toDate();
            default:
                return new Date();
        }
    })
    let resultData: HealthRecord[] = [];
    for (let i = 0; i < newTimestamps.length; i++) {
        resultData.push({
            time: newTimestamps[i].toISOString(),
            value: formatedNewValues[i]
        });
    }
    return resultData;
}